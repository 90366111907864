import React from 'react';
import { Link } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

export default function EmailAttachment ({ messageAttachment }) {
	return (
		<Link
			variant={'caption'}
			href={`${process.env.REACT_APP_BACKEND_URL}/public/${messageAttachment}`}
			download={messageAttachment}
			target="_blank"
			rel="noopener noreferrer"
			underline="always"
		>
			<span style={{ display: 'flex' }}>
				<AttachmentIcon fontSize="small" />
				{messageAttachment}
			</span>
		</Link>
	);
}
