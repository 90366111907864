import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    width: "200px",
  },
  userChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#00fa9a",
  },
  queueChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#ff00ff",
  },
  statusChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#00bfff",
  },
  tagChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#ff4500",
  },
  connectionChip: {
    margin: theme.spacing(0.5),
    // backgroundColor: "#ff0000",
  },
}));

export const ActionModal = ({
  open,
  handleClose,
  selectedChatbot,
  setSelectedChatbot,
  data: { queues, users, connections, tags },
  nodeId,
  optionId,
}) => {
  const classes = useStyles();
  const [actionType, setActionType] = React.useState("Setor");
  const [actionShould, setActionShould] = React.useState(null);

  const status = ["Aberto", "Fechado", "Aguardando"];

  const handleSave = async () => {
    const { data: newAction } = await api.post("/chatbot/action", {
      name: actionType,
      should: actionShould,
      optionId,
    });

    setSelectedChatbot({
      ...selectedChatbot,
      nodes: selectedChatbot.nodes.map((node) => {
        if (Number(node.id) === Number(nodeId)) {
          return {
            ...node,
            options: node.options.map((option) => {
              if (Number(option.id) === Number(optionId)) {
                if (!option.action) {
                  return {
                    ...option,
                    action: [newAction],
                  };
                }
                return {
                  ...option,
                  action: [...option.action, newAction],
                };
              }
              return option;
            }),
          };
        }
        return node;
      }),
    });
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        style={{ width: "90vw" }}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>Adicionar ação</DialogTitle>
        <DialogContent dividers style={{ width: "100%" }}>
          <TextField
            select
            label="Tipo da ação"
            // value={selectedChatbot?.name}
            // onBlur={(e) => {
            //   setSelectedChatbot({
            //     ...selectedChatbot,
            //     name: e.target.value,
            //   });
            // }}
            value={actionType}
            onChange={(e) => {
              setActionType(e.target.value);
            }}
            name="name"
            variant="outlined"
            margin="dense"
            className={classes.textField}
            style={{ width: "250px" }}
          >
            <MenuItem value="Setor">Setor</MenuItem>
            <MenuItem value="Atendente">Atendente</MenuItem>
            <MenuItem value="Conexão">Conexão</MenuItem>
            <MenuItem value="Status">Status</MenuItem>
            <MenuItem value="+Etiqueta">Adicionar Tag</MenuItem>
            <MenuItem value="-Etiqueta">Remover Tag</MenuItem>
          </TextField>
          <Divider />
          <TextField
            select
            label={actionType}
            // value={selectedChatbot?.name}
            // onBlur={(e) => {
            //   setSelectedChatbot({
            //     ...selectedChatbot,
            //     name: e.target.value,
            //   });
            // }}
            name="name"
            variant="outlined"
            margin="dense"
            className={classes.textField}
            style={{ width: "250px" }}
            onChange={(e) => {
              setActionShould(e.target.value);
            }}
          >
            {actionType === "Setor"
              ? queues?.map((queue) => (
                  <MenuItem key={queue.id} value={queue.id}>
                    {queue.name}
                  </MenuItem>
                ))
              : actionType === "Atendente"
              ? users?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))
              : actionType === "Status"
              ? status?.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))
              : actionType === "+Etiqueta" || actionType === "-Etiqueta"
              ? tags?.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                ))
              : connections?.map((connection) => (
                  <MenuItem key={connection.id} value={connection.id}>
                    {connection.name}
                  </MenuItem>
                ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {i18n.t("queueModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            // disabled={isSubmitting}
            variant="contained"
            className={classes.btnWrapper}
            onClick={handleSave}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
