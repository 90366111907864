import React, { useEffect, useState, useContext } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ThemeContext } from '../../context/Theme/ThemeContext';
import * as sanitizeHtml from 'sanitize-html';
import ModalTitle from '../ModalTitle';
import {
	Avatar,
	Backdrop,
	Box,
	Fade,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import EmailReplyText from './EmailReplyText';
import api from '../../services/api';
import ModalActionBtns from '../ModalActionBtns';

export default function EmailMessageModal({
	modalOpened,
	handleModalOpened,
	ticketId,
	message,
}) {
	const [selectedUser, setSelectedUser] = useState({ email: '' });
	const [selectedTarget, setSelectedTarget] = useState({ email: '' });
	const [currentTicket, setCurrentTicket] = useState({});
	const [subjectMessage, setSubjectMessage] = useState('');
	const [inputMessage, setInputMessage] = useState('');
	const [requestFailure, setRequestFailure] = useState(true);
	const { darkMode } = useContext(ThemeContext);

	const [ticketIdState] = useState(
		ticketId ? ticketId : message ? message.ticketId : null
	);
	const [messageBody, setMessageBody] = useState({});

	useEffect(() => {
		if (message && message.body) {
			setMessageBody(JSON.parse(message.body));
			setSelectedTarget({ email: message.contact.email });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//every time the modal loads or unloads, the requestfailure flag is set to false and the loading indicator vanishes.
	useEffect(() => {
		setRequestFailure(false);
	}, [modalOpened]);

	useEffect(() => {
		const fetchTicketInformation = async () => {
			const ticketInformation = await api.get(`/tickets/${ticketIdState}`);
			const { contact, connectionId, whatsappId } = ticketInformation.data;
			//the following check is made since connections are currently being called whatsapp inside the database and is meant so that once the name of the column is changed to connection, the code will still work
			const validConnectionId = connectionId ? connectionId : whatsappId;
			const connectionInformation = await api.get(
				`/${connectionId ? 'connection' : 'whatsapp'}/${validConnectionId}`
			);
			const user = connectionInformation.data;
			setCurrentTicket(ticketInformation.data);
			setSelectedUser(user);
			setSelectedTarget(contact);
		};
		if(ticketIdState) fetchTicketInformation();
	}, [ticketIdState]);

	const handleModalBehaviour = () => {
		setSubjectMessage('');
		setInputMessage('');
		handleModalOpened(false);
	};

	const handleSendButton = async () => {
		const request = await api.post('/email', {
			sender: selectedUser,
			target: selectedTarget.email,
			subject: message ? `RE: ${messageBody.subject}` : subjectMessage,
			message: message
				? `${inputMessage} <hr><br>${sanitizeHtml(messageBody.body)}`
				: inputMessage,
			currentTicket,
		});

		if (request.status === 201 || request.status === 200) {
			setSubjectMessage('');
			setInputMessage('');
			setRequestFailure(false);

			handleModalOpened(false);
		} else {
			setRequestFailure(true);
		}
	};

	const boxStyle = {
		boxSizing: 'border-box',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: '50%',
		minHeight: '300px',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		maxWidth: '80%',
		padding: '2% 2% 0.5% 2%',
		borderRadius: '10px',
		overflow: 'hidden',
	};

	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={modalOpened}
				onClose={handleModalBehaviour}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalOpened}>
					<Box sx={boxStyle}>
						<ModalTitle
							title="Enviar email"
							closeModalFn={handleModalBehaviour}
						/>
						<Box
							align="center"
							flexDirection={'column'}
							alignItems={'center'}
							sx={{ margin: '10px', padding: '10px', height: '100%' }}
							display="flex"
							justifyContent={'space-evenly'}
						>
							<Box
								sx={{
									width: '100%',
									mb: 2,
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Box width={'45%'}>
									<FormControl fullWidth variant="outlined">
										<InputLabel id="demo-simple-select-label">
											Enviando como:
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="user-as-sender"
											value={selectedUser}
											label={'Enviando como:'}
											required
											displayEmpty
											onChange={(e) => setSelectedUser(e.target.value)}
										>
											<MenuItem value={selectedUser}>
												<span style={{ width: '80%', fontWeight: 'bold' }}>
													{selectedUser.email}
												</span>
											</MenuItem>
											{/* next line implementation is a placeholder for when this modal is utilized in a "send email to anyone on contact list" context */}
											{!ticketIdState && (
												<>
													<MenuItem value={'usuario2'}>usuario2</MenuItem>
													<MenuItem value={'usuario3'}>usuario3</MenuItem>
												</>
											)}
										</Select>
									</FormControl>
								</Box>
								<Box width={'45%'}>
									<FormControl fullWidth variant="outlined">
										<InputLabel id="recipient-label">Enviando para:</InputLabel>
										<Select
											labelId="recipient-label"
											id="target-as-receiver"
											value={selectedTarget}
											label="Enviando para:"
											required
											displayEmpty
											onChange={(e) => setSelectedTarget(e.target.value)}
										>
											<MenuItem
												value={selectedTarget}
												sx={{ fontSize: '1.5em' }}
											>
												<span style={{ width: '80%', fontWeight: 'bold' }}>
													{selectedTarget.email}
												</span>
											</MenuItem>

											{/* next line implementation is a placeholder for when this modal is utilized in a "send email to anyone on contact list" context */}
											{!ticketIdState && (
												<MenuItem
													value={'destinatário1'}
													sx={{ fontSize: '1.5em' }}
												>
													<Avatar>P </Avatar>Pablo@yahoo.com
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</Box>
							</Box>
							<TextField
								id="email-subject"
								label="Assunto"
								inputProps={{ minLength: 3 }}
								helperText="Assunto do e-mail"
								placeholder={message ? `RE: ${messageBody.subject}` : 'Assunto'}
								variant="outlined"
								value={message ? `RE: ${messageBody.subject}` : subjectMessage}
								disabled={message ? true : false}
								fullWidth
								onChange={(e) => setSubjectMessage(e.target.value)}
							/>
							<ReactQuill
								theme="snow"
								value={inputMessage}
								onChange={setInputMessage}
								style={{
									width: '100%',
									alignSelf: 'center',
									borderRadius: '4px',
									height: '300px',
									// border: '1px solid #333',
									backgroundColor: darkMode ? '#303030' : '#fff',
									paddingBottom: '7%',
									marginBottom: '30px',
								}}
							/>
							{message && <EmailReplyText messageBody={messageBody.body} />}
							<Box textAlign="center" sx={{ width: '100%', mt: 1 }}>
								{requestFailure && (
									<Typography color="error">
										Falha no envio do e-mail. Verifique a sua conexão e tente
										novamente.
									</Typography>
								)}
								<ModalActionBtns
									cancelBtnText={'Cancelar'}
									cancelBtnFn={handleModalBehaviour}
									confirmBtnText={'Enviar'}
									confirmBtnFn={handleSendButton}
								/>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</>
	);
}
