import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import ReactHtmlParser from "react-html-parser";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import {
  Avatar,
  Box,
  Container,
  Typography,
  Button,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
} from "@material-ui/icons";

import Lock from "@material-ui/icons/Lock";
import openSocket from "../../services/socket-io";

import MarkdownWrapper from "../../components/MarkdownWrapper";
import VcardPreview from "../../components/VcardPreview";
import LocationPreview from "../../components/LocationPreview";
import ModalImageCors from "../../components/ModalImageCors";
import EmailMessageCard from "../../components/EmailMessageCard";
import { ThemeContext } from "../../context/Theme/ThemeContext";

import Audio from "../../components/Audio";
import { AuthContext } from "../../context/Auth/AuthContext";
// import Audio from "../Audio";

const useStyles = makeStyles((theme) => ({
  messagesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    // position: "fixed",
    // top: 0,
    gap: "40vh",
    overflowY: "scroll",
    padding: "43vh 0 45vh 0",
  },

  messageCard: {
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "20px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
  },

  messageCard2: {
    position: "absolute",
    bottom: "-60px",
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "20px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
    transform: "scale(0.7)",
  },

  messageCard0: {
    position: "absolute",
    top: "-60px",
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "20px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
    transform: "scale(0.7)",
  },

  avatar: {
    marginLeft: "-30px",
    boxShadow: "0 0px 5px 5px rgba(31, 38, 135, 0.37)",
  },

  cardText: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  name: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },

  message: {},
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const messagesListExample = [
  {
    mediaUrl: null,
    id: "C3CB3507AF50D858F683D92150D79296",
    ack: 0,
    read: true,
    fromMe: false,
    body: "Minhas provas começam na semana que vem",
    mediaType: "chat",
    metadata: null,
    isDeleted: false,
    createdAt: "2023-01-17T14:33:56.744Z",
    updatedAt: "2023-01-20T13:57:11.201Z",
    quotedMsgId: null,
    ticketId: 8,
    contactId: 9,
    contact: {
      id: 9,
      name: "Bruno Riwerson Silva",
      number: "5519991550438",
      email: "",
      profilePicUrl:
        "https://pps.whatsapp.net/v/t61.24694-24/310855267_197700302625613_889480052487281243_n.jpg?ccb=11-4&oh=01_AdRp-qul2zhlv2mboltTbP8YjcCc2AtyjBAsM4-KI3vo9w&oe=63DBB2CD",
      channel: "whatsapp",
      isGroup: false,
      createdAt: "2023-01-16T18:52:27.000Z",
      updatedAt: "2023-01-23T14:44:46.000Z",
    },
    ticket: {
      id: 8,
      status: "closed",
      unreadMessages: 0,
      lastMessage: "Se as coisas não mudarem, eu também.",
      isGroup: false,
      channel: "whatsapp",
      rating: 0,
      outChatbot: false,
      userId: 3,
      chatbotNode: null,
      lastChatbotOption: null,
      contactId: 9,
      whatsappId: 16,
      queueId: 2,
      createdAt: "2023-01-16T18:52:27.000Z",
      updatedAt: "2023-04-20T19:57:35.000Z",
      contact: {
        id: 9,
        name: "Bruno Riwerson Silva",
        number: "5519991550438",
        email: "",
        profilePicUrl:
          "https://pps.whatsapp.net/v/t61.24694-24/310855267_197700302625613_889480052487281243_n.jpg?ccb=11-4&oh=01_AdRp-qul2zhlv2mboltTbP8YjcCc2AtyjBAsM4-KI3vo9w&oe=63DBB2CD",
        channel: "whatsapp",
        isGroup: false,
        createdAt: "2023-01-16T18:52:27.000Z",
        updatedAt: "2023-01-23T14:44:46.000Z",
      },
      queue: {
        id: 2,
        name: "setor 254564",
        color: "#ff00ff",
        greetingMessage: "oi",
        startWork: "12:00",
        endWork: "12:15",
        absenceMessage: "não to",
        chatbotId: null,
        createdAt: "2023-01-19T18:17:03.000Z",
        updatedAt: "2023-03-31T18:30:56.000Z",
      },
      whatsapp: {
        name: "business2",
      },
    },
    quotedMsg: null,
  },
  {
    mediaUrl: null,
    id: "90D96649A8936DA5DCCBFBD7B0AA3C26",
    ack: 0,
    read: true,
    fromMe: false,
    body: "É tão adiantando horário",
    mediaType: "chat",
    metadata: null,
    isDeleted: false,
    createdAt: "2023-04-20T19:48:03.067Z",
    updatedAt: "2023-04-20T19:54:20.304Z",
    quotedMsgId: null,
    ticketId: 429,
    contactId: 1,
    contact: {
      id: 1,
      name: "Amor (Oi) ❤️",
      number: "5527988882406",
      email: "",
      profilePicUrl:
        "https://pps.whatsapp.net/v/t61.24694-24/222409742_4652639911437840_6828267770150554748_n.jpg?ccb=11-4&oh=01_AdSQkRvgKhBMibztLicquAZ3-ZaN7t5CssWHnDl3op1M9w&oe=644E93D2",
      channel: "whatsapp",
      isGroup: false,
      createdAt: "2023-01-15T18:28:23.000Z",
      updatedAt: "2023-04-20T18:51:52.000Z",
    },
    ticket: {
      id: 429,
      status: "open",
      unreadMessages: 0,
      lastMessage: "Vai querer também?",
      isGroup: false,
      channel: "whatsapp",
      rating: 0,
      outChatbot: true,
      userId: 1,
      chatbotNode: 29,
      lastChatbotOption: null,
      contactId: 1,
      whatsappId: 24,
      queueId: 3,
      createdAt: "2023-04-07T16:08:16.000Z",
      updatedAt: "2023-04-20T19:57:03.000Z",
      contact: {
        id: 1,
        name: "Amor (Oi) ❤️",
        number: "5527988882406",
        email: "",
        profilePicUrl:
          "https://pps.whatsapp.net/v/t61.24694-24/222409742_4652639911437840_6828267770150554748_n.jpg?ccb=11-4&oh=01_AdSQkRvgKhBMibztLicquAZ3-ZaN7t5CssWHnDl3op1M9w&oe=644E93D2",
        channel: "whatsapp",
        isGroup: false,
        createdAt: "2023-01-15T18:28:23.000Z",
        updatedAt: "2023-04-20T18:51:52.000Z",
      },
      queue: {
        id: 3,
        name: "Atendimento inicial",
        color: "#0000ff",
        greetingMessage: "",
        startWork: "",
        endWork: "",
        absenceMessage: "",
        chatbotId: 17,
        createdAt: "2023-02-16T21:22:16.000Z",
        updatedAt: "2023-03-30T18:33:15.000Z",
      },
      whatsapp: {
        name: "unoficial",
      },
    },
    quotedMsg: null,
  },
  {
    mediaUrl: null,
    id: "8C5C24AA30E96933B8F6C6CE248B13FA",
    ack: 0,
    read: true,
    fromMe: false,
    body: "Testando testando",
    mediaType: "chat",
    metadata: null,
    isDeleted: false,
    createdAt: "2023-04-20T19:05:14.452Z",
    updatedAt: "2023-04-20T19:19:56.293Z",
    quotedMsgId: null,
    ticketId: 429,
    contactId: 1,
    contact: {
      id: 1,
      name: "Amor (Oi) ❤️",
      number: "5527988882406",
      email: "",
      profilePicUrl:
        "https://pps.whatsapp.net/v/t61.24694-24/222409742_4652639911437840_6828267770150554748_n.jpg?ccb=11-4&oh=01_AdSQkRvgKhBMibztLicquAZ3-ZaN7t5CssWHnDl3op1M9w&oe=644E93D2",
      channel: "whatsapp",
      isGroup: false,
      createdAt: "2023-01-15T18:28:23.000Z",
      updatedAt: "2023-04-20T18:51:52.000Z",
    },
    ticket: {
      id: 429,
      status: "open",
      unreadMessages: 0,
      lastMessage: "Vai querer também?",
      isGroup: false,
      channel: "whatsapp",
      rating: 0,
      outChatbot: true,
      userId: 1,
      chatbotNode: 29,
      lastChatbotOption: null,
      contactId: 1,
      whatsappId: 24,
      queueId: 3,
      createdAt: "2023-04-07T16:08:16.000Z",
      updatedAt: "2023-04-20T19:57:03.000Z",
      contact: {
        id: 1,
        name: "Amor (Oi) ❤️",
        number: "5527988882406",
        email: "",
        profilePicUrl:
          "https://pps.whatsapp.net/v/t61.24694-24/222409742_4652639911437840_6828267770150554748_n.jpg?ccb=11-4&oh=01_AdSQkRvgKhBMibztLicquAZ3-ZaN7t5CssWHnDl3op1M9w&oe=644E93D2",
        channel: "whatsapp",
        isGroup: false,
        createdAt: "2023-01-15T18:28:23.000Z",
        updatedAt: "2023-04-20T18:51:52.000Z",
      },
      queue: {
        id: 3,
        name: "Atendimento inicial",
        color: "#0000ff",
        greetingMessage: "",
        startWork: "",
        endWork: "",
        absenceMessage: "",
        chatbotId: 17,
        createdAt: "2023-02-16T21:22:16.000Z",
        updatedAt: "2023-03-30T18:33:15.000Z",
      },
      whatsapp: {
        name: "unoficial",
      },
    },
    quotedMsg: null,
  },
  {
    mediaUrl: null,
    id: "3EB01C0A1B2652A6428952",
    ack: 0,
    read: true,
    fromMe: false,
    body: "não busca contato?",
    mediaType: "chat",
    metadata: null,
    isDeleted: false,
    createdAt: "2023-03-30T17:58:32.787Z",
    updatedAt: "2023-03-30T17:58:59.327Z",
    quotedMsgId: null,
    ticketId: 349,
    contactId: 4,
    contact: {
      id: 4,
      name: "Stéfano Dias",
      number: "554488125172",
      email: "",
      profilePicUrl:
        "https://pps.whatsapp.net/v/t61.24694-24/316445262_199431765941922_318965161923134728_n.jpg?ccb=11-4&oh=01_AdQ7znrYE18XZSgImbSbC6IjswH0qNjJ75j7JluPGI2CtA&oe=6439711C",
      channel: "whatsapp",
      isGroup: false,
      createdAt: "2023-01-15T21:52:19.000Z",
      updatedAt: "2023-04-04T18:21:36.000Z",
    },
    ticket: {
      id: 349,
      status: "open",
      unreadMessages: 0,
      lastMessage:
        "Selecione uma das opções: \n\n1 - Sou cliente\n2 - Não sou cliente\n3 - Opção 3\n4 - Opção 4\n5 - Opção 5\nCaso queira encerrar o atendimento, escreve *#sair* ou digite *0*",
      isGroup: false,
      channel: "whatsapp",
      rating: 0,
      outChatbot: true,
      userId: 1,
      chatbotNode: 29,
      lastChatbotOption: null,
      contactId: 4,
      whatsappId: 24,
      queueId: 3,
      createdAt: "2023-03-30T14:51:14.000Z",
      updatedAt: "2023-04-10T17:20:38.000Z",
      contact: {
        id: 4,
        name: "Stéfano Dias",
        number: "554488125172",
        email: "",
        profilePicUrl:
          "https://pps.whatsapp.net/v/t61.24694-24/316445262_199431765941922_318965161923134728_n.jpg?ccb=11-4&oh=01_AdQ7znrYE18XZSgImbSbC6IjswH0qNjJ75j7JluPGI2CtA&oe=6439711C",
        channel: "whatsapp",
        isGroup: false,
        createdAt: "2023-01-15T21:52:19.000Z",
        updatedAt: "2023-04-04T18:21:36.000Z",
      },
      queue: {
        id: 3,
        name: "Atendimento inicial",
        color: "#0000ff",
        greetingMessage: "",
        startWork: "",
        endWork: "",
        absenceMessage: "",
        chatbotId: 17,
        createdAt: "2023-02-16T21:22:16.000Z",
        updatedAt: "2023-03-30T18:33:15.000Z",
      },
      whatsapp: {
        name: "unoficial",
      },
    },
    quotedMsg: null,
  },
];

function PresentationMessagesList({
  contact,
  ticketId,
  isGroup,
  ticketChannel,
  templateBarStatus,
  activeIndex,
  setMessagesListLength,
  // ref,
}) {
  const classes = useStyles();

  const { darkMode } = useContext(ThemeContext);

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setMessagesListLength(messagesList.length);
  }, [messagesList]);

  // useEffect(() => {
  //   setLoading(true);
  //   const delayDebounceFn = setTimeout(() => {
  //     const fetchMessages = async () => {
  //       try {
  //         const { data } = await api.get("/messages/" + ticketId, {
  //           params: { pageNumber },
  //         });

  //         if (currentTicketId.current === ticketId) {
  //           dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
  //           setHasMore(data.hasMore);
  //           setLoading(false);
  //         }

  //         if (pageNumber === 1 && data.messages.length > 1) {
  //           scrollToBottom();
  //         }
  //       } catch (err) {
  //         setLoading(false);
  //         toastError(err);
  //       }
  //     };
  //     fetchMessages();
  //   }, 500);
  //   return () => {
  //     clearTimeout(delayDebounceFn);
  //   };
  // }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket({
      scope: "presentationmessages",
      userId: user.id,
      component: "PresentationMessagesList",
    });

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }
      // WORKING HERE

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (
      message.mediaType === "location" &&
      message.metadata
      // && message.body.split("|").length >= 2
    ) {
      const metadata = JSON.parse(JSON.parse(message.metadata));

      if (!metadata.location) return null;

      const {
        location: { latitude, longitude, description },
        body: imageLocation,
      } = metadata;

      const { clientUrl } = metadata._data;

      const linkLocation = `https://www.google.com/maps?q=${latitude},${longitude}&z=17&hl=pt-BR`;

      return (
        <LocationPreview
          image={`data:image/jpeg;base64,${imageLocation}`}
          link={linkLocation}
          description={description}
          clientUrl={clientUrl}
        />
      );
    }
    if (message.mediaType === "vcard") {
      const array = message.body.split("\n");
      const obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        const values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0].number} />;
    }
    if (message.mediaType === "image") {
      return (
        <>
          <ModalImageCors imageUrl={message.mediaUrl} />
          {/* <MarkdownWrapper>{message.body}</MarkdownWrapper> */}
        </>
      );
    }
    if (message.mediaType === "audio") {
      return <Audio url={message.mediaUrl} />;
    }
    if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    }
    if (message.mediaType !== "email") {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      const messageDay = parseISO(messagesList[index].createdAt);
      const previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      const messageTicket = message.ticketId;
      const previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} className={classes.ticketNumber}>
            Ticket #{messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      const messageUser = messagesList[index].fromMe;
      const previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return <span style={{ marginTop: 16 }} key={`divider-${message.id}`} />;
      }
    }
  };

  const renderQuotedMessage = (message) => (
    <div
      style={{
        cursor: "pointer",
      }}
      className={
        darkMode
          ? clsx(classes.quotedContainerLeftDarkMode, {
              [classes.quotedContainerRightDarkMode]: message.fromMe,
            })
          : clsx(classes.quotedContainerLeft, {
              [classes.quotedContainerRight]: message.fromMe,
            })
      }
    >
      <span
        className={clsx(classes.quotedSideColorLeft, {
          [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
        })}
      />
      <div className={classes.quotedMsg}>
        {!message.quotedMsg?.fromMe && (
          <span className={classes.messageContactName}>
            {message.quotedMsg?.contact?.name}
          </span>
        )}
        {message.quotedMsg?.body}
      </div>
    </div>
  );

  function sortByKey(array, key) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = sortByKey(messagesList, "createdAt").map(
        (message, index) => {
          if (message.mediaType === "annotation") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <div className={classes.annotation}>
                  {ReactHtmlParser(message.body)}
                  <span
                    outLined
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3em",
                    }}
                  >
                    <Lock fontSize="inherit" />
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </React.Fragment>
            );
          }
          if (message.mediaType === "call_log") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <div className={classes.messageCenter}>
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                      width="20"
                      height="17"
                    >
                      <path
                        fill="#df3333"
                        d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                      />
                    </svg>{" "}
                    <span>
                      Chamada de voz/vídeo perdida às{" "}
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            );
          }
          if (!message.fromMe) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <div
                  className={[
                    darkMode ? classes.messageLeftDark : classes.messageLeft,
                  ]}
                  style={
                    message.mediaType === "email"
                      ? {
                          padding: "0.5em 0 0 ",
                          margin: "0.5em 0 0",
                        }
                      : null
                  }
                >
                  {message.mediaType !== "email" && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  {(message.mediaUrl ||
                    message.mediaType === "location" ||
                    message.mediaType === "vcard") &&
                    // || message.mediaType === "multi_vcard"
                    checkMessageMedia(message)}
                  <Box
                    className={
                      message.mediaType !== "email"
                        ? classes.textContentItem
                        : undefined
                    }
                  >
                    {message.quotedMsg && renderQuotedMessage(message)}

                    {message && message.mediaType === "email" ? (
                      <EmailMessageCard message={message} />
                    ) : (
                      <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    )}
                    <span className={classes.timestamp}>
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </Box>
                </div>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              <div
                className={[
                  darkMode ? classes.messageRightDark : classes.messageRight,
                ]}
                style={
                  message.mediaType === "email"
                    ? {
                        padding: 0,
                        margin: "0.5em 0 0",
                      }
                    : null
                }
              >
                {message.mediaType !== "email" && (
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
                {(message.mediaUrl ||
                  message.mediaType === "location" ||
                  message.mediaType === "vcard") &&
                  // || message.mediaType === "multi_vcard"
                  checkMessageMedia(message)}
                {message.mediaType !== "email" ? (
                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                  >
                    {message.isDeleted && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}
                    {message.quotedMsg && renderQuotedMessage(message)}

                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                      {format(parseISO(message.createdAt), "HH:mm")}
                      {renderMessageAck(message)}
                    </span>
                  </div>
                ) : (
                  <Box
                    className={
                      message.mediaType !== "email" && classes.textContentItem
                    }
                  >
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {message && message.mediaType === "email" ? (
                      <EmailMessageCard message={message} />
                    ) : (
                      <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    )}
                    <span className={classes.timestamp}>
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </Box>
                )}
              </div>
            </React.Fragment>
          );
        }
      );
      return viewMessagesList;
    }
    return <div>Say hello to your new contact!</div>;
  };

  const scroll = () => {
    setTimeout(() => {
      const section = document.querySelector("#searchedClicked");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 300);
  };

  useEffect(() => {
    scroll();
  }, [activeIndex]);

  return (
    <Container className={classes.messagesContainer}>
      {messagesList.length > 0 &&
        messagesList.map((message, index) => (
          <Box
            className={classes.messageCard}
            key={index}
            id={index === activeIndex ? "searchedClicked" : ""}
          >
            <Avatar
              className={classes.avatar}
              src={message.ticket.contact.profilePicUrl}
            />
            <Box className={classes.cardText}>
              <Typography className={classes.name}>
                {message.ticket.contact.name}
              </Typography>
              <Typography className={classes.message}>
                {message.body}
              </Typography>
            </Box>
          </Box>
        ))}
    </Container>
  );

  {
    /* {messagesList.length > 0
          ? messagesList.map((message, index) => {
              return (
                <Box className={classes.messageCard} key={index}>
                  <Avatar
                    className={classes.avatar}
                    src={message.ticket.contact.profilePicUrl}
                  />
                  <Box className={classes.cardText}>
                    <Typography className={classes.name}>
                      {message.ticket.contact.name}
                    </Typography>
                    <Typography className={classes.message}>
                      {message.body}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          : messagesListExample.map((message, index) => {
              return (
                <Box className={classes.messageCard} key={index}>
                  <Avatar
                    className={classes.avatar}
                    src={message.ticket.contact.profilePicUrl}
                  />
                  <Box className={classes.cardText}>
                    <Typography className={classes.name}>
                      {message.ticket.contact.name}
                    </Typography>
                    <Typography className={classes.message}>
                      {message.body}
                    </Typography>
                  </Box>
                </Box>
              );
            })} */
  }

  // (
  //   <div className={classes.messagesListWrapper}>
  //     {ticketChannel === "whatsappbusiness" && templateBarStatus && (
  //       <TemplateOnlyBar />
  //     )}
  //     {/* <MessageOptionsMenu
  //       message={selectedMessage}
  //       anchorEl={anchorEl}
  //       menuOpen={messageOptionsMenuOpen}
  //       handleClose={handleCloseMessageOptionsMenu}
  //     /> */}
  //     <div
  //       id="messagesList"
  //       className={classes.messagesList}
  //       onScroll={handleScroll}
  //     >
  //       {messagesList.length > 0 ? renderMessages() : []}
  //     </div>

  //     {loading && (
  //       <div>
  //         <CircularProgress className={classes.circleLoading} />
  //       </div>
  //     )}
  //   </div>
  // );
}

export default PresentationMessagesList;
