import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff, HourglassEmpty } from "@material-ui/icons";

import LinkIcon from "@material-ui/icons/Link";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { getBackendUrl } from "../../config";
import AutoQueueSelect from "../AutoQueueSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

function WhatsAppModal({ open, onClose, whatsAppId }) {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isDisplay: false,
    email: "",
    emailPassword: "",
    maxAttempts: 3,
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [passwordType, setPasswordType] = useState("password");
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedAutoQueueId, setSelectedAutoQueueId] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);

        setSelectedAutoQueueId(data.autoQueue);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleClickShowPassword = () => {
    setPasswordType(passwordType === "text" ? "password" : "text");
  };

  const handleSaveWhatsApp = async (values) => {
    if (!values.email) values.emailPassword = "";
    const whatsappData = {
      ...values,
      queueIds: selectedQueueIds,
      autoQueueId: selectedAutoQueueId,
    };

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  const handleCopy = (event, text) => {
    event.persist();
    event.target.textContent = "Copied!";
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      event.target.textContent = text;
    }, 2000);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={{ ...whatsApp }}
          enableReinitialize
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
            setWhatsApp(initialState);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />
                </div>
                <div>
                  <Field
                    as={Select}
                    label={i18n.t("whatsappModal.form.channel")}
                    name="channel"
                    labelId="channel-selection-label"
                    id="channel-selection"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    // make it so that the channel is acquired from the requested connection, else it will be the default channel
                    value={values.channel || "whatsapp"}
                  >
                    <MenuItem value="whatsapp">
                      {i18n.t("whatsappModal.form.whatsapp")}
                    </MenuItem>
                    <MenuItem value="email">E-mail</MenuItem>
                    <MenuItem value="facebook">
                      {i18n.t("whatsappModal.form.facebook")}
                    </MenuItem>
                    <MenuItem value="instagram">
                      {i18n.t("whatsappModal.form.instagram")}
                    </MenuItem>
                    <MenuItem value="telegram">
                      {i18n.t("whatsappModal.form.telegram")}
                    </MenuItem>
                    <MenuItem value="whatsappcloud">
                      {i18n.t("whatsappModal.form.whatsappcloud")}
                    </MenuItem>
                  </Field>
                </div>
                {values.channel === "whatsappcloud" && (
                  <div>
                    <Field
                      as={TextField}
                      label="WABA ID do número de telefone"
                      type="greetingMessage"
                      multiline
                      rows={1}
                      fullWidth
                      name="wabaFoneNumberId"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                    <Field
                      as={TextField}
                      label="WABA ID da Conta de Negócios"
                      type="greetingMessage"
                      multiline
                      rows={1}
                      fullWidth
                      name="wabaAccountId"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                    <Field
                      as={TextField}
                      label="WABA Chave da API"
                      type="greetingMessage"
                      multiline
                      rows={1}
                      fullWidth
                      name="wabaAccessToken"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                )}
                {values.channel === "facebook" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Page ID"
                      multiline
                      rows={1}
                      fullWidth
                      name="pageId"
                      variant="outlined"
                      margin="dense"
                    />
                    <Field
                      as={TextField}
                      label="Page Access Token"
                      multiline
                      rows={1}
                      fullWidth
                      name="pageToken"
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                )}
                {values.channel === "telegram" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Telegram Bot Token"
                      type="greetingMessage"
                      multiline
                      rows={1}
                      fullWidth
                      name="telegramBotToken"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                )}

                {values.channel === "email" && (
                  <div>
                    <Field
                      sx={{ m: 1 }}
                      as={TextField}
                      label="Endereço de Email"
                      fullWidth
                      // type="email"
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={
                        touched.email && errors.email ? "E-mail inválido" : ""
                      }
                      variant="outlined"
                      margin="dense"
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <Field
                        sx={{ m: 1 }}
                        as={TextField}
                        label="Senha"
                        fullWidth
                        name="emailPassword"
                        variant="outlined"
                        margin="dense"
                        // type={passwordType}
                        inputProps={
                          {
                            //type: passwordType,
                          }
                        }
                      />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {passwordType === "password" ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </Box>
                    <Divider fullWidth style={{ margin: "8px 0" }} />
                    <Field
                      as={TextField}
                      sx={{ m: 1 }}
                      label="Host de recebimento de Email"
                      type="emailServer"
                      fullWidth
                      name="emailServer"
                      variant="outlined"
                      margin="dense"
                      helperText="Ex: smtp.gmail.com . Dúvida? Consulte a documentação do seu provedor de email."
                    />
                    <Field
                      as={TextField}
                      label="Porta de recebimento de Email"
                      sx={{ m: 2 }}
                      inputProps={{ type: "number" }}
                      type="emailPort"
                      fullWidth
                      name="emailPort"
                      variant="outlined"
                      margin="dense"
                      helperText="Costuma ser 993. Porta do servidor de email. Dúvida? Consulte a documentação do seu provedor de email."
                    />
                    <Divider fullWidth style={{ margin: "8px 0" }} />
                    <Field
                      as={TextField}
                      sx={{ m: 1, mt: 2 }}
                      label="Host de envio de email"
                      type="emailServerSend"
                      fullWidth
                      name="emailServerSend"
                      variant="outlined"
                      margin="dense"
                      helperText="Ex: smtp.gmail.com - Atenção: não necessariamente é o mesmo servidor de recebimento."
                    />
                    <Field
                      as={TextField}
                      label="Porta de envio de email"
                      sx={{ m: 2 }}
                      inputProps={{ type: "number" }}
                      type="emailPortSend"
                      fullWidth
                      name="emailPortSend"
                      variant="outlined"
                      margin="dense"
                      helperText="Costuma ser 465. Dúvida? Consulte a documentação do seu provedor de email."
                    />
                    <Divider fullWidth style={{ margin: "8px 0" }} />
                  </div>
                )}
                {values.channel !== "email" && (
                  <>
                    <div>
                      <Field
                        as={TextField}
                        label={i18n.t("queueModal.form.greetingMessage")}
                        type="greetingMessage"
                        multiline
                        rows={5}
                        fullWidth
                        name="greetingMessage"
                        error={
                          touched.greetingMessage &&
                          Boolean(errors.greetingMessage)
                        }
                        helperText={
                          touched.greetingMessage && errors.greetingMessage
                        }
                        variant="outlined"
                        margin="dense"
                      />
                    </div>
                    <div>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.farewellMessage")}
                        type="farewellMessage"
                        multiline
                        rows={5}
                        fullWidth
                        name="farewellMessage"
                        error={
                          touched.farewellMessage &&
                          Boolean(errors.farewellMessage)
                        }
                        helperText={
                          touched.farewellMessage && errors.farewellMessage
                        }
                        variant="outlined"
                        margin="dense"
                      />
                    </div>
                  </>
                )}

                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />

                <AutoQueueSelect
                  selectedAutoQueueId={selectedAutoQueueId}
                  onChange={(selectedAutoQueueId) =>
                    setSelectedAutoQueueId(selectedAutoQueueId)
                  }
                />
                <TextField
                  value={whatsApp?.maxAttempts}
                  onChange={(e) => {
                    setWhatsApp({
                      ...whatsApp,
                      maxAttempts: e.target.value,
                    });
                  }}
                  label={i18n.t("chatbotMaxAttempts")}
                  type="maxAttempts"
                  fullWidth
                  name="maxAttempts"
                  variant="outlined"
                  margin="dense"
                  inputProps={{ type: "number" }}
                />
              </DialogContent>

              {values.createdAt && (
                <DialogContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignContent="center"
                    m={2}
                  >
                    <Typography variant="h6" align="center">
                      {i18n.t("whatsappModal.form.connectionInfo")}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignContent="center"
                      my={1}
                      alignItems="center"
                    >
                      <HourglassEmpty
                        fontSize="medium"
                        style={{ "margin-right": "0.5em" }}
                      />
                      <Typography
                        variant="body2"
                        onClick={(event) =>
                          handleCopy(
                            event,
                            new Date(values.createdAt).toLocaleString("pt-br")
                          )
                        }
                      >
                        Created at:{" "}
                        {new Date(values.createdAt).toLocaleString("pt-br")}
                      </Typography>
                    </Box>
                    {values.channel === "whatsappcloud" ||
                      (values.channel === "facebook" && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignContent="center"
                          mt={1}
                        >
                          <LinkIcon
                            fontSize="medium"
                            style={{ "margin-right": "0.5em" }}
                          />
                          <Typography
                            variant="body2"
                            display="inline"
                            onClick={(event) =>
                              handleCopy(
                                event,
                                `${getBackendUrl()}/webhook/?connectionId=${
                                  values.apikey
                                }`
                              )
                            }
                          >
                            Link:{" "}
                            {`${getBackendUrl()}/webhook/?connectionId=${
                              values.apikey
                            }`}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </DialogContent>
              )}
              <Divider />
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default React.memo(WhatsAppModal);
