import React, { useState, useEffect, useContext } from "react";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Chip,
  withStyles,
  Avatar,
  DialogActions,
  Button,
} from "@material-ui/core";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DoneIcon from "@material-ui/icons/Done";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    margin: "auto",
    marginTop: "8vh",
    display: "flex",
    flexDirection: "column",

    "& .MuiDialog-container": {
      margin: "auto",
      width: "1000px",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  chipError: {
    color: "#fff",
    backgroundColor: "#ff0063",

    // change Avatar background color
    "& .MuiAvatar-root": {
      color: "#fff",
      backgroundColor: "#dc0055",
    },
  },
}));

function BulkInfoModal({ open, onClose, bulkMessageId }) {
  const classes = useStyles();
  const [loadingInfos, setLoadingInfos] = useState(false);
  const [bulkInfos, setBulkInfos] = useState({});

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!bulkMessageId) return;

    (async () => {
      setLoadingInfos(true);
      try {
        const { data } = await api.get(
          `/bulk-messaging-infos/${bulkMessageId}`
        );
        setBulkInfos(data);
        setLoadingInfos(false);
      } catch (error) {
        toastError(error);
        setLoadingInfos(false);
      }
    })();
  }, [bulkMessageId]);

  // const loadMore = () => {
  //   const newPage = contactsPage + 1;
  //   setContactsPage((prevState) => prevState + 1);
  //   getContacts({ action: "add", newPage });
  // };

  // const handleScroll = (e) => {
  //   if (!hasMoreContacts || loadingContacts) return;

  //   const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

  //   if (scrollHeight - (scrollTop + 100) < clientHeight) {
  //     loadMore();
  //   }
  // };

  return (
    <Dialog
      // open={true}
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen
      className={classes.dialogContainer}
    >
      <DialogTitle id="form-dialog-title">Relatório de envios</DialogTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Contato</TableCell>
              <TableCell>
                {`Sucesso: ${bulkInfos?.success || 0} / 
              Falhas: ${bulkInfos?.failed || 0} / Pendentes: ${
                  bulkInfos?.pending || 0
                }  `}
              </TableCell>
              <TableCell>Tentativas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bulkInfos?.contacts?.map((contact) => {
              const { name, number, attempts, jobStatus } = contact;
              return (
                <TableRow>
                  <TableCell>{name}</TableCell>
                  <TableCell>{number}</TableCell>
                  <TableCell>
                    {jobStatus === false && (
                      <Chip
                        className={classes.chipError}
                        label="mensagem não enviada"
                        size="small"
                        avatar={
                          <Avatar color={{ backgroundColor: "red" }}>
                            <ErrorOutlineIcon />
                          </Avatar>
                        }
                      />
                    )}
                    {jobStatus === true && (
                      <Chip
                        label="mensagem enviada"
                        color="primary"
                        size="small"
                        avatar={
                          <Avatar color={{ backgroundColor: "red" }}>
                            <DoneIcon />
                          </Avatar>
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>{attempts || 0}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button
          style={{
            position: "absolute",
            bottom: "10px",
          }}
          onClick={handleClose}
          color="primary"
          variant="outlined"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkInfoModal;
