import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import openSocket from "../../services/socket-io";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  typography: {
    subtitle6: {
      fontSize: 12,
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function ApiKey() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket({
      scope: "apikey",
      userId: user.id,
      component: "ApiKey",
    });

    socket.on("settings", (data) => {
      if (data.action === "update") {
        setSettings((prevState) => {
          const aux = [...prevState];
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key);
          aux[settingIndex].value = data.setting.value;
          return aux;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s.key === key);
    return value;
  };

  return (
    // <div className={classes.root}>
    //   <Container className={classes.container} maxWidth="sm">
    //     <Typography variant="body2" gutterBottom>
    //       {i18n.t('mainDrawer.listItems.token')}
    //     </Typography>

    <Paper className={classes.paper}>
      <TextField
        id="api-token-setting"
        readonly
        label="Api Key"
        margin="dense"
        variant="outlined"
        fullWidth
        value={
          settings && settings.length > 0 && getSettingValue("userApiToken")
        }
      />
    </Paper>
    //   </Container>
    // </div>
  );
}

export default ApiKey;
