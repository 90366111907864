import { toast } from 'react-toastify';
import { i18n } from '../translate/i18n';

const toastError = (err) => {
  const errorMsg = err.response?.data?.message || err.response?.data?.error;
  console.log(err);
  if (errorMsg) {
    if (i18n.exists(`backendErrors.${errorMsg}`)) {
      toast.error(i18n.t(`backendErrors.${errorMsg}`), {
        toastId: errorMsg,
      });
    } else if (errorMsg.includes('OTHER_OPEN_TICKET')) {
      toast.error(i18n.t('Há outro ticket aberto para este contato'), {
        toastId: errorMsg,
      });
    } else {
      toast.error(errorMsg, {
        toastId: errorMsg,
      });
    }
  } else {
    toast.error('An error occurred!');
  }
};

export default toastError;
