import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ChatIcon from "@material-ui/icons/Chat";
import { Avatar, makeStyles } from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { TicketModalUserQueueCon } from "../TicketModalUserQueueCon";

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: "100%",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  maxWidth: {
    width: "100%",
  },
  buttonColorError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

export const MultiVcardContacts = ({ modalOpen, onClose, contacts }) => {
  const classes = useStyles();
  const [selectedContact, setSelectedContact] = useState({});
  const [createTicketModalOpen, setCreateTicketModalOpen] = useState(false);

  /*  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user.id,
        status: "open",
        queueId: selectedQueue,
        whatsappId: selectedWhatsapp.id,
        channel:
          selectedWhatsapp.channel === "whatsappcloud"
            ? "whatsappbusiness"
            : selectedWhatsapp.channel,
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
    onClose();
  }; */

  const createContact = async (contact) => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const contactObj = {
            name: contact.name,
            number: contact.number,
            email: "",
          };
          const { data } = await api.post("/contact", contactObj);
          setSelectedContact(data);
        } catch (err) {
          console.log(err);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  const formatNumber = (number) => {
    if (!number) return;
    // format from 5511999999999 to +55 (11) 99999-9999 and from 551199999999 to +55 (11) 9999-9999
    if (number.length === 13) {
      return `+${number.slice(0, 2)} (${number.slice(2, 4)}) ${number.slice(
        4,
        9
      )}-${number.slice(9)}`;
    } else if (number.length === 12) {
      return `+${number.slice(0, 2)} (${number.slice(2, 4)}) ${number.slice(
        4,
        8
      )}-${number.slice(8)}`;
    }
  };

  const handleNewChat = async () => {
    setCreateTicketModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <TicketModalUserQueueCon
        selectedContact={selectedContact}
        modalOpen={createTicketModalOpen}
        onClose={() => setCreateTicketModalOpen(false)}
      />
      <Dialog
        open={modalOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle
          style={{
            backgroundColor: "#008069",
            color: "#fff",
          }}
          id="form-dialog-title"
        >
          <IconButton
            style={{
              color: "#fff",
              marginRight: 15,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {`${contacts?.length} contatos`}
        </DialogTitle>

        <DialogContent
          dividers
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              backgroundColor: "#f0f2f5",
              width: "100%",
              padding: 10,
            }}
          >
            {contacts?.map((contact) => {
              return (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    dropShadow: "0px 0px 3px 3px rgba(0, 0, 0, 1)",
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      style={{
                        width: 50,
                        height: 50,
                        margin: "15px 15px 15px 0px",
                      }}
                      alt={contact.name}
                      src={contact.profilePicUrl}
                    />
                    <Box>
                      <Box fontWeight="fontWeightBold">{contact.name}</Box>
                      <Box>{formatNumber(contact.number)}</Box>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => {
                      createContact(contact);
                      handleNewChat();
                    }}
                  >
                    <ChatIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
