import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: "100%",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  maxWidth: {
    width: "100%",
  },
  buttonColorError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  searchTextField: {
    padding: "5px 15px 3px 15px",
    margin: 0,
    backgroundColor: "#f0f2f5",
    borderRadius: 7,
  },

  footerContactsBox: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "#fff",
    borderTop: "1px solid #f0f2f5",
    padding: "9px 0px 30px 15px ",
    zIndex: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },

  footerContactsList: {
    fontSize: 11,
  },

  sendButton: {
    backgroundColor: "#00a884",

    // on hover
    "&:hover": {
      backgroundColor: "#007a5f",
    },

    "& svg": {
      color: "#fff",
    },
  },
}));

export const ForwardMessagesModal = ({
  modalOpen,
  onClose,
  selectedMessages,
  whatsappId,
}) => {
  const classes = useStyles();
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    setContacts([]);
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber, channel: "whatsapp" },
          });
          setContacts((prevState) => [...prevState, ...data.contacts]);
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const formatNumber = (number) => {
    if (!number) return;
    // format from 5511999999999 to +55 (11) 99999-9999 and from 551199999999 to +55 (11) 9999-9999
    if (number.length === 13) {
      return `+${number.slice(0, 2)} (${number.slice(2, 4)}) ${number.slice(
        4,
        9
      )}-${number.slice(9)}`;
    } else if (number.length === 12) {
      return `+${number.slice(0, 2)} (${number.slice(2, 4)}) ${number.slice(
        4,
        8
      )}-${number.slice(8)}`;
    }
  };

  const sendMessages = async () => {
    try {
      selectedContacts.forEach(async (contact) => {
        await api.post("/messages/forward-messages", {
          toNumber: contact.number,
          messageIds: selectedMessages,
          whatsappId,
        });
      });
      onClose();
      setContacts([]);
      setSelectedContacts([]);
      setSearchParam("");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={modalOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle
          style={{
            backgroundColor: "#008069",
            color: "#fff",
          }}
          id="form-dialog-title"
        >
          <IconButton
            style={{
              color: "#fff",
              marginRight: 15,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {`Encaminhar mensagens para`}
        </DialogTitle>

        <DialogContent
          dividers
          style={{
            margin: 0,
            padding: 0,
            minHeight: "80vh",
            overflowY: "hidden",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              backgroundColor: "#FFF",
              width: "100%",
              padding: 10,
            }}
          >
            <TextField
              className={classes.searchTextField}
              value={searchParam}
              // placeholder
              onChange={(e) => setSearchParam(e.target.value)}
              // variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    style={{
                      fontSize: 19,
                      opacity: 0.7,
                      marginRight: 30,
                    }}
                  />
                ),
                disableUnderline: true,
                endAdornment: (
                  <CloseIcon
                    style={{
                      fontSize: 19,
                      opacity: 0.7,
                      cursor: "pointer",
                    }}
                    onClick={() => setSearchParam("")}
                  />
                ),
              }}
            />
            <Box
              style={{
                height: "65vh",
                overflowY: "scroll",
              }}
            >
              {contacts?.map((contact) => {
                return (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      dropShadow: "0px 0px 3px 3px rgba(0, 0, 0, 1)",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={selectedContacts.find(
                          (ctt) => ctt.id === contact.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedContacts((prevState) => [
                              ...prevState,
                              {
                                id: contact.id,
                                name: contact.name,
                                number: contact.number,
                              },
                            ]);
                          } else {
                            setSelectedContacts((prevState) =>
                              prevState.filter((ctt) => ctt.id !== contact.id)
                            );
                          }
                        }}
                      />
                      <Avatar
                        style={{
                          width: 50,
                          height: 50,
                          margin: "15px 15px 15px 0px",
                        }}
                        alt={contact.name}
                        src={contact.profilePicUrl}
                      />
                      <Box>
                        <Box fontWeight="fontWeightBold">{contact.name}</Box>
                        <Box>{formatNumber(contact.number)}</Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {selectedContacts.length > 0 && (
              <Box className={classes.footerContactsBox}>
                {selectedContacts.length > 0 && (
                  <Typography noWrap className={classes.footerContactsList}>
                    {selectedContacts
                      .reverse()
                      .map((ctt) => ctt.name)
                      .join(", ")}
                  </Typography>
                )}
                <IconButton className={classes.sendButton}>
                  <SendIcon onClick={sendMessages} />
                </IconButton>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
