import React from "react";

function Emoji4() {
  return (
    <img
      src="/rating-4.png"
      alt="emoji4"
      style={{
        width: "30px",
      }}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="24"
    //   height="24"
    // >
    //   <defs>
    //     <style>{`.b { fill: #864e20; } .e { fill: #e6e7e8; }`}</style>
    //   </defs>
    //   <rect
    //     x="1"
    //     y="1"
    //     width="22"
    //     height="22"
    //     rx="7.656"
    //     style={{ fill: "#89fc46" }}
    //   />
    //   <path
    //     className="b"
    //     d="M8.907 9.844a.182.182 0 0 1-.331.1 2.016 2.016 0 0 0-.569-.567 1.731 1.731 0 0 0-1.915 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021z"
    //   />
    //   <path
    //     className="b"
    //     d="M18.81 9.844a.182.182 0 0 1-.331.1 2.026 2.026 0 0 0-.568-.567 1.732 1.732 0 0 0-1.916 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021z"
    //   />
    //   <path
    //     d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z"
    //     style={{ fill: "#50c900" }}
    //   />
    //   <path
    //     d="M7.127 12h9.746a1.937 1.937 0 0 1 1.937 1.937 1.938 1.938 0 0 1-1.937 1.938H7.127a1.937 1.937 0 0 1-1.937-1.937A1.937 1.937 0 0 1 7.127 12z"
    //     style={{ fill: "#fff" }}
    //   />
    //   <ellipse className="e" cx="12" cy="13.938" rx="6.188" ry="0.25" />
    //   <ellipse className="e" cx="7.257" cy="13.938" rx="0.208" ry="1.438" />
    //   <ellipse className="e" cx="9.628" cy="13.938" rx="0.208" ry="1.438" />
    //   <ellipse className="e" cx="12" cy="13.938" rx="0.208" ry="1.438" />
    //   <ellipse className="e" cx="14.372" cy="13.938" rx="0.208" ry="1.438" />
    //   <ellipse className="e" cx="16.743" cy="13.938" rx="0.208" ry="1.438" />
    // </svg>
  );
}

export default Emoji4;
