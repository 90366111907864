import React from "react";

import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    display: "flex",
    // flex: "none",
    minHeight: "3.5em",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "0 1em",
    },

    [theme.breakpoints.up("md")]: {
      minHeight: "59px",
    },
  },

  arrowBack: {
    marginLeft: "1em",
  },
}));

function TicketHeader({ loading, children }) {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.push("/tickets");
  };

  const darkMode = localStorage.getItem("darkMode") === "true" ? true : false;

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card
          square
          className={classes.ticketHeader}
          style={{
            backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
          }}
        >
          <Button color="primary" onClick={handleBack}>
            <ArrowBackIos className={classes.arrowBack} />
          </Button>
          {children}
        </Card>
      )}
    </>
  );
}

export default TicketHeader;
