import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MessageInput from "../MessageInput";
import DraftInput from "../DraftInput";
import TicketListItem from "../TicketListItem";
import { Divider } from "@material-ui/core";
import MessageCard from "./MessageCard";

const AntTabs = withStyles({
  root: {
    borderBottom: "0px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#088069",
  },
  padding: 0,
  margin: 0,
  border: 0,
  height: "0.5em",
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#00A884",
      opacity: 1,
    },
    "&$selected": {
      color: "#088069",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#00A884",
    },
    fontSize: "1.2em",
    fontWeight: "bold",
    padding: 0,
    margin: 0,
    border: 0,
    height: "0.5em",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function CustomizedTabs(props) {
  const {
    searchParam,
    searchedMessages,
    searchedTickets
  } = props;
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Mensagens" />
          <AntTab label="Tickets" />
        </AntTabs>
        {/* <Typography className={classes.padding} /> */}
      </div>
      {/* <div className={classes.demo2}> */}
      <div      >
        {value === 0 ? (
          <div
            style={{
              marginTop: "1em",
            }}
          >
            {searchedMessages.map((message) => {
              return (
                <>
                  <MessageCard message={message} searchParam={searchParam} />
                  <Divider
                    style={{
                      margin: "1.3em 0",
                    }}
                  />
                </>
              );
            })}
          </div>
        ) : (
          searchedTickets?.map((ticket) => {
            if (ticket?.id) {
              return (
                <>
                  <TicketListItem ticket={ticket} />
                  <Divider />
                </>
              )
            }
          })
        )}
        {/* <StyledTab label="whatsapp" />
          <StyledTab label="Datasets" /> */}
      </div>
    </div>
  );
}
