import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import ContactDrawer from "../ContactDrawer";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import PermissionModal from "../PermissionModal";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";

const options = [
  {
    text: i18n.t("messagesList.header.buttons.solverWithoutGoodbye"),
    value: "solveWithoutGoodbye",
  },
  { text: i18n.t("contactModal.form.mainInfo"), value: "seeMainInfo" },
  { text: i18n.t("messagesList.header.buttons.return"), value: "return" },
  { text: i18n.t("ticketOptionsMenu.transfer"), value: "transfer" },
  { text: i18n.t("ticketOptionsMenu.delete"), value: "delete" },
  { text: "Enviar formulário de avaliação", value: "rating" },
];

const useStyles = makeStyles((theme) => ({
  popper: {
    position: "absolute",
    height: "20em",
  },
  btnGroup: {
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      width: "33vw",
    },
  },
}));

export default function TicketActionButtons({ ticket, contact }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const [
    acceptTicketWithouSelectQueueOpen,
    setAcceptTicketWithouSelectQueueOpen,
  ] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleUpdateTicketStatus = async (status, userId, sendMessage) => {
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status,
        userId: userId || null,
        sendMessage,
      });
      setLoading(false);
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const sendRating = async () => {
    try {
      const { data: url } = await api.post(`/rating-url/${ticket.id}`);

      const message = {
        read: 1,
        fromMe: true,
        mediaUrl: "",
        body: `Avalie o nosso atendimento atendimento: ${url}`,
      };

      await api.post(`/messages/${ticket.id}`, message);

      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleMenuItemClick = (value) => {
    setLoading(true);
    switch (value) {
      case "resolve":
        handleUpdateTicketStatus("closed", user.id, true);
        break;
      case "solveWithoutGoodbye":
        handleUpdateTicketStatus("closed", user?.id, false);
        break;
      case "return":
        handleUpdateTicketStatus("pending", user?.id, false);
        break;
      case "delete":
        user.profile === "admin"
          ? setConfirmationOpen(true)
          : setPermissionOpen(true);
        setLoading(false);
        break;
      case "transfer":
        setTransferTicketModalOpen(true);
        setLoading(false);
        break;
      case "seeMainInfo":
        setLoading(false);
        handleDrawerOpen();
        break;
      case "rating":
        sendRating();
      default:
        break;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <PermissionModal open={permissionOpen} onClose={setPermissionOpen} />
      {!ticket.queueId && (
        <AcceptTicketWithouSelectQueue
          modalOpen={acceptTicketWithouSelectQueueOpen}
          onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
          ticketId={ticket.id}
        />
      )}
      {ticket.status === "pending" || ticket.status === "closed" ? (
        <ButtonWithSpinner
          loading={loading}
          startIcon={ticket.status === "closed" && <Replay />}
          size="small"
          onClick={() => {
            ticket.queueId
              ? handleUpdateTicketStatus("open", user.id, false)
              : setAcceptTicketWithouSelectQueueOpen(true);
          }}
          // onClick={() => handleUpdateTicketStatus("open", null, false)}
        >
          {ticket.status === "pending"
            ? i18n.t("messagesList.header.buttons.accept")
            : i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <ButtonGroup
              variant="contained"
              color="primary"
              ref={anchorRef}
              aria-label="split button"
              zindex="tooltip"
              className={classes.btnGroup}
            >
              <Button onClick={() => handleMenuItemClick("resolve")}>
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  i18n.t("messagesList.header.buttons.resolve")
                )}
              </Button>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              className={classes.popper}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.text}
                            onClick={(event) =>
                              handleMenuItemClick(option.value)
                            }
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      )}
      {/* MODAL BOX - DRAWERS AND MODALS SHOULD BE INSIDE AND TOGGLED ON AND OFF BASED ON USER INTERACTION */}
      <Box>
        {drawerOpen && (
          <ContactDrawer
            loading={loading}
            contact={contact}
            open={drawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        )}
        <ConfirmationModal
          title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
            ticket.id
          } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
            ticket.contact.name
          }?`}
          open={confirmationOpen}
          onClose={setConfirmationOpen}
          onConfirm={handleDeleteTicket}
        >
          {i18n.t("ticketOptionsMenu.confirmationModal.message")}
        </ConfirmationModal>
        <TransferTicketModal
          modalOpen={transferTicketModalOpen}
          onClose={() => {
            setTransferTicketModalOpen(false);
          }}
          ticketid={ticket.id}
          ticketChannel={ticket.channel}
        />
      </Box>
    </>
  );
}
