import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  firstDivMap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  progressBar: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

function ChartDepartmentBar({ dataChart: { getDataDashboard }, period }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getDataChart = async () => {
      try {
        const { dataProgressDepartmentBar } = await getDataDashboard(period);

        setData(dataProgressDepartmentBar);
      } catch (error) {
        console.log(error);
      }
    };

    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <div>
      <div>
        <span>{i18n.t('dashboard.charts.progressBar.callsByDepartment')}</span>
      </div>
      <hr />
      {data.map((element) => {
        if (element.value === 0) return <></>;
        return (
          <div className={classes.firstDivMap}>
            <span style={{ width: 100 }}>{element.name}</span>
            <div className="progress" style={{ height: 15, width: 150 }}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${element.value}%` }}
              />
            </div>
            <span>{element.value}</span>
          </div>
        );
      })}
    </div>
  );
}

export default ChartDepartmentBar;
