import React, { createContext, useState } from "react";

import useAuth from "../../hooks/useAuth.js";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const { loading, user, isAuth, handleLogin, handleLogout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        isAuth,
        handleLogin,
        handleLogout,
        isSocketConnected,
        setIsSocketConnected,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
