import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

export function TagsContainer({ contact }) {
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadTags();
      // if (Array.isArray(ticket.tags)) {
      //     setSelecteds(ticket.tags);
      // }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post("/tags", data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get("/tags/list");
      setTags(data);
      setSelecteds(contact.tags);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post("/tags/sync", data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const uniqueTagsList = () => {
    const filteredTags = tags?.filter((tag) => {
      const found = selecteds.find((selected) => selected.id === tag.id);
      return !found;
    });

    return filteredTags;
  };

  const onChange = async (value, reason) => {
    let optionsChanged = [];
    if (reason === "create-option") {
      if (isArray(value)) {
        for (const item of value) {
          if (isString(item)) {
            const newTag = await createTag({
              name: item,
              contactId: contact.id,
            });
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    } else {
      optionsChanged = value;
    }
    setSelecteds(optionsChanged);
    await syncTags({ contactId: contact.id, tags: optionsChanged });
  };

  return (
    <Paper style={{ padding: 2 }}>
      <Autocomplete
        multiple
        size="small"
        options={uniqueTagsList()}
        value={selecteds}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                backgroundColor: option?.color || "#eee",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={i18n.t("mainDrawer.listItems.tags")}
          />
        )}
        PaperComponent={({ children }) => (
          <Paper style={{ width: 400, marginLeft: 12 }}>{children}</Paper>
        )}
      />
    </Paper>
  );
}
