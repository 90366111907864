import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import FlipIcon from "@material-ui/icons/Flip";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { toast } from "react-toastify";

import BulkInfoModal from "../../components/BulkInfoModal";
import BulkMessagesModal from "../../components/BulkMessagesModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import PermissionModal from "../../components/PermissionModal";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const statusTagsStyle = {
  color: "#fff",
  borderRadius: "3px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.8em",
  textAlign: "center",
  width: "80px",
  margin: "auto",
  fontWeight: "bold",
  padding: 0,
  minHeight: "0px",
  maxHeight: "20px",
  height: "20px",
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  bulkStopped: {
    ...statusTagsStyle,
    backgroundColor: "#daa520",
  },

  bulkRunning: {
    ...statusTagsStyle,
    backgroundColor: "#20dace",

    // animate border rounding
    animation: `$borderRounding 2000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: "infinite",
  },

  "@keyframes borderRounding": {
    "0%": {
      backgroundColor: "#20dace",
    },

    "50%": {
      backgroundColor: "#15b0a6",
    },

    "100%": {
      backgroundColor: "#20dace",
    },
  },

  bulkUnstarted: {
    ...statusTagsStyle,
    backgroundColor: "#8400ff",
  },

  bulkCancelled: {
    ...statusTagsStyle,
    backgroundColor: "#ff0063",
  },

  bulkComplete: {
    ...statusTagsStyle,
    backgroundColor: "#00da00",
  },

  repeatWarning: {
    transition: "0.3s ease-in-out",
    color: "#ff0063",

    // animation
    animation: `$repeatWarning 1000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: "infinite",
  },

  "@keyframes repeatWarning": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },

  bulkActions: {
    height: "20px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    alignItems: "center",

    "& button": {
      padding: "0px",
      // minWidth: "30px",
      width: "17px",
      height: "17px",
      margin: "0px",

      // icon
      "& svg": {
        width: "17px",
        height: "17px",

        // on hover
        "&:hover": {
          color: "#ff0063",
          transition: "0.3s",
        },
      },
    },
  },
}));

const bulkStatuses = {
  stopped: "bulkStopped",
  running: "bulkRunning",
  unstarted: "bulkUnstarted",
  cancelled: "bulkCancelled",
  complete: "bulkComplete",
};

const bulkStatusesTranslated = {
  stopped: "Pausado",
  running: "Em execução",
  unstarted: "Não iniciado",
  cancelled: "Cancelado",
  complete: "Completo",
};

const BulkMessaging = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [bulkMessages, setBulkMessages] = useState([]);
  // const [schedules, dispatch] = useReducer(reducer, []);

  const [selectedBulk, setSelectedBulk] = useState(null);
  const [bulkMessagesModalOpen, setBulkMessagesModalOpen] = useState(false);
  const [bulkInfosModalOpen, setBulkInfosModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingBulkMessages, setDeletingBulkMessages] = useState({});
  const [permissionOpen, setPermissionOpen] = React.useState(false);
  const [failedContacts, setFailedContacts] = useState(null);
  const [failedLoading, setFailedLoading] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data } = await api.get("/bulk-messaging");

        data.forEach((bulkMessage) => {
          if (
            bulkMessage.status === "running" ||
            bulkMessage.status === "unstarted"
          ) {
            let now = new Date();
            let trigger = new Date(bulkMessage.trigger);
            let diff = trigger.getTime() - now.getTime();

            if (diff < 0) {
              diff = 5000;
              trigger = new Date(now.getTime() + 5000);
            }

            let messagesIntervalTime =
              bulkMessage.contacts.filter((c) => c.jobStatus === null).length *
              bulkMessage.messageInterval;
            let clustersIntervalTime =
              (bulkMessage.contacts.filter((c) => c.jobStatus === null).length /
                bulkMessage.clusterSize -
                1) *
              bulkMessage.clusterInterval;

            const delayTime = messagesIntervalTime + clustersIntervalTime;

            const nowDate = new Date(trigger.getTime());
            const finalDate = new Date(trigger.getTime() + delayTime * 1000);

            bulkMessage.delay = finalDate;
          }
        });

        setBulkMessages(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [bulkMessagesModalOpen]);

  bulkMessages.forEach((bulkMessage) => {
    if (bulkMessage.status === "running") {
      let now = new Date();
      let trigger = new Date(bulkMessage.trigger);
      let diff = trigger.getTime() - now.getTime();

      if (diff < 0) {
        diff = 5000;
      }

      let messagesIntervalTime =
        bulkMessage.contacts.filter((c) => c.jobStatus === null).length *
        bulkMessage.messageInterval;
      let clustersIntervalTime =
        (bulkMessage.contacts.filter((c) => c.jobStatus === null).length /
          bulkMessage.clusterSize -
          1) *
        bulkMessage.clusterInterval;

      let delayTime =
        diff + (messagesIntervalTime + clustersIntervalTime) * 1000;

      setTimeout(() => {
        setBulkMessages((bulkMessages) =>
          bulkMessages.map((b) => {
            if (b.id === bulkMessage.id && b.status === "running") {
              b.status = "complete";
            }
            return b;
          })
        );
      }, delayTime);

      // setBulkMessages((bulkMessages) =>
      //   bulkMessages.map((b) => {
      //     if (b.id === bulkMessage.id && b.status === "running") {
      //       b.delay = `${delayTime / 1000} segundos}`;
      //     }
      //     return b;
      //   })
      // );
    }
  });

  const getFailedContacts = async (bulkMessageId) => {
    setFailedContacts(null);
    setFailedLoading(true);
    (async () => {
      try {
        const { data } = await api.get(
          `/bulk-messaging/${bulkMessageId}/failed`
        );
        setFailedContacts(data);
        setFailedLoading(false);
      } catch (err) {
        setFailedLoading(false);
        toastError(err);
      }
    })();
  };

  const handleOpenBulkMessagesModal = () => {
    setSelectedBulk(null);
    setBulkMessagesModalOpen(true);
  };

  const handleCloseBulkMessagesModal = () => {
    setSelectedBulk(null);
    setBulkMessagesModalOpen(false);
  };

  const handleOpenBulkInfosModal = (bulkMessage) => {
    setSelectedBulk(bulkMessage);
    setBulkInfosModalOpen(true);
  };

  const handleCloseBulkInfosModal = () => {
    setSelectedBulk(null);
    setBulkInfosModalOpen(false);
  };

  const handleEditBulkMessages = (bulkMessage) => {
    setSelectedBulk(bulkMessage);
    setBulkMessagesModalOpen(true);
  };

  const handleBulkMessagingStatus = async (bulkMessage, status) => {
    try {
      const response = await api.put(
        `/bulk-messaging-handle/${bulkMessage.id}`,
        {
          status,
        }
      );

      setBulkMessages(
        bulkMessages.map((b) => {
          if (b.id === bulkMessage.id) {
            b.status = response.data.status;
          }
          return b;
        })
      );
    } catch (err) {
      toastError(err);
    }
  };

  const handleResendAllMessages = async (bulkMessage) => {
    try {
      const response = await api.put(
        `/bulk-messaging-handle/${bulkMessage.id}/resend-all`
      );

      setBulkMessages(
        bulkMessages.map((b) => {
          if (b.id === bulkMessage.id) {
            b.status = response.data;
          }
          return b;
        })
      );
    } catch (err) {
      toastError(err);
    }
  };

  const duplicateBulkMessage = async (bulkMessage) => {
    try {
      const response = await api.post(
        `/duplicate-bulk-messaging/${bulkMessage.id}`
      );

      setBulkMessages([...bulkMessages, response.data]);
    } catch (err) {
      toastError(err);
    }
  };

  const handleResendFailedMessages = async (bulkMessage) => {
    try {
      const response = await api.put(
        `/bulk-messaging-handle/${bulkMessage.id}/resend-failed`
      );

      setBulkMessages(
        bulkMessages.map((b) => {
          if (b.id === bulkMessage.id) {
            b.status = response.data;
          }
          return b;
        })
      );
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteBulkMessages = async (bulkMessageId) => {
    try {
      await api.delete(`/bulk-messaging/${bulkMessageId}`);
      setBulkMessages(bulkMessages.filter((b) => b.id !== bulkMessageId));
      toast.success("Envio em massa deletado!");
    } catch (err) {
      toastError(err);
    }
  };

  const showDelay = (bulkMessage) => {
    const { delay, trigger, status } = bulkMessage;

    if (status === "running" || status === "unstarted") {
      // convert delay to date
      let delayDate = new Date(delay);
      // format delay date to string dd/mm/yyyy hh:mm:ss
      return delayDate.toLocaleString("pt-BR");
    }
  };

  return (
    <MainContainer>
      <PermissionModal open={permissionOpen} onClose={setPermissionOpen} />

      <ConfirmationModal
        title="Excluir envio em massa?"
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteBulkMessages(deletingBulkMessages.id)}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      <BulkMessagesModal
        open={bulkMessagesModalOpen}
        onClose={handleCloseBulkMessagesModal}
        aria-labelledby="form-dialog-title"
        bulkMessageId={selectedBulk && selectedBulk.id}
      />

      <BulkInfoModal
        open={bulkInfosModalOpen}
        onClose={handleCloseBulkInfosModal}
        aria-labelledby="form-dialog-title"
        bulkMessageId={selectedBulk && selectedBulk.id}
      />

      <MainHeader>
        <Title>Envio em Massa</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenBulkMessagesModal}
          >
            Adicionar envio em massa
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{"Protocolo"}</TableCell>
              <TableCell align="center">{"Nome"}</TableCell>
              <TableCell align="center">{"Data de Envio"}</TableCell>
              <TableCell align="center">{"Completo em"}</TableCell>
              <TableCell align="center">{"Status"}</TableCell>
              <TableCell align="center">{"Ações"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {bulkMessages.map((bulkMessage) => (
                <TableRow key={bulkMessage.id}>
                  <TableCell align="center">{bulkMessage.protocol}</TableCell>
                  <TableCell align="center">{bulkMessage.name}</TableCell>
                  <TableCell align="center">
                    {new Date(bulkMessage.trigger)
                      .toLocaleDateString("pt-BR", {
                        // timezone para o horário de brasília
                        timeZone: "America/Sao_Paulo",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .split(", ")
                      .join(" - ")}
                  </TableCell>
                  <TableCell align="center">
                    {
                      // delay in seconds convert to minutes, hours or days
                      showDelay(bulkMessage)
                    }
                  </TableCell>
                  <TableCell align="center">
                    <Box className={classes[bulkStatuses[bulkMessage.status]]}>
                      {bulkStatusesTranslated[bulkMessage.status]}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box className={classes.bulkActions}>
                      {user?.name === "admin" && (
                        <Tooltip title="Editar (visível somente para o usuário Admin)">
                          <IconButton
                            size="small"
                            onClick={() => handleEditBulkMessages(bulkMessage)}
                          >
                            <Edit color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {bulkMessage.status === "unstarted" && (
                        <Tooltip title="Iniciar">
                          <IconButton
                            size="small"
                            onClick={(e) =>
                              handleBulkMessagingStatus(bulkMessage, "running")
                            }
                          >
                            <PlayCircleOutlineIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {bulkMessage.status === "running" && (
                        <Tooltip title="Pausar">
                          <IconButton
                            size="small"
                            onClick={(e) =>
                              handleBulkMessagingStatus(bulkMessage, "stopped")
                            }
                          >
                            <PauseCircleOutlineIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {bulkMessage.status === "stopped" && (
                        <Tooltip title="Continuar">
                          <IconButton
                            size="small"
                            onClick={(e) =>
                              handleBulkMessagingStatus(bulkMessage, "running")
                            }
                          >
                            <PlayCircleOutlineIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {(bulkMessage.status === "cancelled" ||
                        bulkMessage.status === "complete") && (
                        <>
                          <Tooltip title="Reenviar todos">
                            <IconButton
                              size="small"
                              onClick={(e) =>
                                handleResendAllMessages(bulkMessage)
                              }
                            >
                              <RepeatIcon color="secondary" />
                            </IconButton>
                          </Tooltip>

                          {bulkMessage.contacts.find(
                            (c) => c.jobStatus === false
                          ) && (
                            <Tooltip
                              title={
                                failedLoading
                                  ? "Carregando..."
                                  : `Reenviar ${failedContacts} envios falhados`
                              }
                            >
                              <IconButton
                                size="small"
                                onMouseOver={() => {
                                  getFailedContacts(bulkMessage.id);
                                }}
                                onClick={(e) => {
                                  handleResendFailedMessages(bulkMessage);
                                }}
                              >
                                {/* {bulkMessage.contacts.length > 0 ? ( */}
                                <RotateLeftIcon
                                  className={classes.repeatWarning}
                                />
                                {/* ) : (
                                  <RotateLeftIcon color="secondary" />
                                )} */}
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}

                      <Tooltip title="Detalhes">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            handleOpenBulkInfosModal(bulkMessage);
                          }}
                        >
                          <ErrorOutlineIcon color="secondary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Duplicar">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            duplicateBulkMessage(bulkMessage);
                          }}
                        >
                          <FlipIcon color="secondary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Deletar">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            user.profile === "admin"
                              ? setConfirmModalOpen(true)
                              : setPermissionOpen(true);
                            setDeletingBulkMessages(bulkMessage);
                          }}
                        >
                          <DeleteOutline color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default BulkMessaging;
