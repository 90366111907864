import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  ListItem,
  MenuItem,
  Paper,
  Radio,
  Select,
  Step,
  StepButton,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import MoodIcon from "@material-ui/icons/Mood";
import clsx from "clsx";
import { Picker } from "emoji-mart";
import { toast } from 'react-toastify';

import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ModalImageChatbot from "../ModalImageChatbot";
import { TemplatesTable } from "./TemplatesTable";
import { VariablesModal } from "./VariablesModal";
import { useStyles } from './styles';

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const defaultBulkMessage = {
  bulkMessagingData: {
    name: "",
    trigger: new Date().toISOString().split(".")[0].substring(0, 16),
    status: "",
    message: "",
    mediaPath: null,
    messageInterval: 5,
    clusterSize: 5,
    clusterInterval: 5,
    connectionId: null,
    scheduled: false,
  },
  contacts: [],
};

// eslint-disable-next-line
const defaultVariables = ["${contactName}"];

function getSteps() {
  return ["Cadastrar campanha", "Selecionar envios", "Revisar dados"];
}

export default function BulkMessagesModal({ open, onClose, bulkMessageId, onSave }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [bulkMessage, setBulkMessage] = useState(defaultBulkMessage);
  const [newUser, setNewUser] = useState({
    name: "",
    number: "",
  });
  const [addContactsType, setAddContactsType] = useState("manual");
  const [triggerType, setTriggerType] = useState("immediate");
  const [csvName, setCsvName] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [toTransferContacts, setToTransferContacts] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [contactsPage, setContactsPage] = useState(1);
  const [hasMoreContacts, setHasMoreContacts] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [variablesModalOpen, setVariablesModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [styledMessage, setStyledMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [channel, setChannel] = useState();
  const { whatsApps } = useContext(WhatsAppsContext);
  const [templateParamsLength, setTemplateParamsLength] = useState(0);
  const [templateParams, setTemplateParams] = useState([]);
  const steps = getSteps();

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const getDateFormat = (oldDate) => {
    const date = new Date(oldDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const newDate = `${year}-${month}-${day}T${hour}:${minute}`;

    return newDate;
  };

  const handleClose = () => {
    setBulkMessage(defaultBulkMessage);
    setCompleted({});
    setChannel(null);
    setActiveStep(0);
    setTriggerType("immediate");
    setAddContactsType("manual");
    setNewUser({
      name: "",
      number: "",
    });
    onClose();
  };

  const countTemplateParams = (text) => {
    var regex = /\{{\d+}}/g;
    var matches = text?.match(regex);

    if (matches) {
      return matches.length;
    } else {
      return 0;
    }
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setBulkMessage(defaultBulkMessage);
    setNewUser({
      name: "",
      number: "",
    });
  };

  const handleFinish = async () => {
    if (bulkMessage.bulkMessagingData.templateParams === "null") {
      delete bulkMessage.bulkMessagingData.templateParams;
    }

    if (bulkMessageId) {
      try {
        await api.put(`/bulk-messaging/${bulkMessageId}`, bulkMessage);

      } catch (error) {
        toastError(error);
      }
    }

    if (!bulkMessageId) {
      try {
        const { data } = await api.post("/bulk-messaging", bulkMessage);
        const { invalidContacts } = data;

        if (invalidContacts?.length) {
          const invalids = invalidContacts.map((contact) => contact.name);
          toast.error(
            `Os seguintes contatos são inválidos: ${invalids.join(', ')}`,
            { autoClose: false }
          )
        }
      } catch (error) {
        toastError(error);
      }
    }

    handleClose();
    handleReset();
  };

  const handleUpdateMedia = async (e) => {
    if (!e.target.files) {
      return;
    }

    const img = e.target.files[0];
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", img);
    const response = await api.post("/bulk-messaging/upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const url = `${process.env.REACT_APP_BACKEND_URL}/public/${img.name}`;

    setBulkMessage({
      ...bulkMessage,
      bulkMessagingData: {
        ...bulkMessage.bulkMessagingData,
        mediaPath: url,
      },
    });
  };

  const handleCreateContact = () => {
    if (newUser.name.length === 0) {
      setBulkMessage({
        ...bulkMessage,
        contacts: [
          ...bulkMessage.contacts,
          { name: newUser.number, number: newUser.number },
        ],
      });
    } else {
      setBulkMessage({
        ...bulkMessage,
        contacts: [...bulkMessage.contacts, newUser],
      });
    }
    setNewUser({ name: "", number: "" });
  };

  const setContactsByCSV = async (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    const fileName = file.name;

    try {
      setLoadingContacts(true);
      reader.onload = (e) => {
        const csvData = e.target.result;

        // remove \r from string
        let normalizedCsv = csvData.replace(/\r/g, "");

        normalizedCsv += "\n";
        const lines = normalizedCsv.split("\n");
        const headers = lines[0].split(",");

        const result = [];
        for (let i = 1; i < lines.length; i++) {
          const object = {};
          const currentLine = lines[i].split(",");
          for (let j = 0; j < headers.length; j++) {
            object[headers[j]] = currentLine[j];
          }

          if (!object.name || !object.number) continue;

          result.push(object);
        }

        setBulkMessage({
          ...bulkMessage,
          contacts: [...bulkMessage.contacts, ...result],
        });

        setCsvName(fileName);
      };

      reader.readAsText(file);
      setLoadingContacts(false);
    } catch (error) {
      toastError(error);
      setLoadingContacts(false);
    }
  };

  const getContacts = useCallback(async ({ action, newPage }) => {
    setLoadingContacts(true);

    let params;

    if (action === "reset") {
      params = {
        pageNumber: 1,
        searchParam,
        tags: selectedTags,
        channel,
      };
    }

    if (action === "add") {
      params = {
        pageNumber: newPage,
        searchParam,
        tags: selectedTags,
        channel,
      };
    }

    try {
      const { data } = await api.get("/contacts", {
        params,
      });

      let formattedContacts = [];

      if (channel === "whatsapp" || channel === "whatsappcloud") {
        formattedContacts = data.contacts.map((contact) => ({
          name: contact.name,
          number: contact.number,
        }));
      } else if (channel === "telegram") {
        formattedContacts = data.contacts.map((contact) => ({
          name: contact.name,
          number: "",
        }));
      } else if (channel === "email") {
        formattedContacts = data.contacts.map((contact) => ({
          name: contact.name,
          number: contact.email,
        }));
      }

      if (action === "reset") {
        setContacts([...formattedContacts]);
      } else {
        setContacts([...contacts, ...formattedContacts]);
      }

      setHasMoreContacts(data.hasMore);
      setLoadingContacts(false);
    } catch (error) {
      toastError(error);
      setLoadingContacts(false);
    }
  }, [setLoadingContacts, setHasMoreContacts, setContacts, searchParam, channel, selectedTags]);

  const getTags = useCallback(async () => {
    try {
      const { data } = await api.get("/tags");
      setTags(data.tags);
    } catch (error) {
      toastError(error);
    }
  }, []);

  const loadMore = () => {
    const newPage = contactsPage + 1;
    setContactsPage((prevState) => prevState + 1);
    getContacts({ action: "add", newPage });
  };

  const handleScroll = (e) => {
    if (!hasMoreContacts || loadingContacts) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleMessage = (e) => {
    const { value } = e.target;
    // if value last letter is "$"
    if (value[value.length - 1] === "$") {
      setVariablesModalOpen(true);
      return;
    }

    // const formatedMessage = value.replace(
    //   new RegExp(`(\\${defaultVariables.join("|\\")})`, "g"),
    //   (match) => variables[match]
    // );

    setBulkMessage({
      ...bulkMessage,
      bulkMessagingData: {
        ...bulkMessage.bulkMessagingData,
        message: e.target.value,
      },
    });

    const beautyMessage = beautifyMessage(value);

    setStyledMessage(beautyMessage);
  };

  const handleAddEmoji = (e) => {
    const emoji = e.native;
    // setInputMessage(prevState => prevState + emoji);
    setBulkMessage({
      ...bulkMessage,
      bulkMessagingData: {
        ...bulkMessage.bulkMessagingData,
        message: bulkMessage.bulkMessagingData.message + emoji,
      },
    });
  };

  const beautifyMessage = (message) => (
    <Typography>
      {message.split(" ").map((word, key) => {
        if (word[0] === "$") {
          return (
            <Chip
              size="small"
              key={key}
              className={classes.variable}
              label={word}
            />
          );
        }
        return ` ${word}`;
      })}
    </Typography>
  );

  useEffect(() => {
    const templateText = bulkMessage.bulkMessagingData.templateText;

    setTemplateParamsLength(countTemplateParams(templateText));
    setTemplateParams(Array(countTemplateParams(templateText)).fill(""));
  }, [bulkMessage.bulkMessagingData.templateId]);

  useEffect(() => {
    if (!bulkMessageId) return;

    (async () => {
      setLoadingContacts(true);
      try {
        const { data } = await api.get(`/bulk-messaging/${bulkMessageId}`);
        setBulkMessage(data);
        setChannel(data.bulkMessagingData?.connection?.channel);
        setTriggerType(
          data.bulkMessagingData.scheduled ? "scheduled" : "immediate"
        );
        setStyledMessage(beautifyMessage(data.bulkMessagingData.message));
        setLoadingContacts(false);

        setTemplateParamsLength(
          JSON.parse(data.bulkMessagingData.templateParams)?.length || 0
        );
        setTemplateParams(
          JSON.parse(data.bulkMessagingData?.templateParams) || []
        );
      } catch (error) {
        toastError(error);
        setLoadingContacts(false);
      }
    })();
  }, [bulkMessageId]);

  useEffect(() => {
    if (activeStep === 1) {
      getTags();
    }
  }, [activeStep, getTags]);

  useEffect(() => {
    if (activeStep === 1) {
      getContacts({ action: "reset" });
    }
  }, [activeStep, getContacts]);

  // const renderText = (text) => {
  //   const styledText = <></>;

  //   console.log("teste render text", text.split(" "));

  //   text.split(" ").forEach((word) => {
  //     if (word.startsWith("${") && word.endsWith("}")) {
  //       // styledTextArray.push(
  //       //   <Chip label={word} />
  //       // );

  //     } else {
  //       styledTextArray.push(<span>{`${word} `}</span>);
  //     }
  //   });

  //   return styledTextArray;
  // };

  return (
    <Dialog
      // open={true}
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen
      className={classes.dialogContainer}
    >
      <DialogTitle id="form-dialog-title">
        {bulkMessageId
          ? "Editar envio de mensagem em massa"
          : "Criar envio de mensagem em massa"}
      </DialogTitle>
      <VariablesModal
        open={variablesModalOpen}
        handleClose={() => setVariablesModalOpen(false)}
        variables={defaultVariables}
        bulkMessage={bulkMessage}
        setBulkMessage={setBulkMessage}
      />

      <div className={classes.stepper}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[index]}
                disabled
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              Todas as etapas foram completadas.
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <Paper className={classes.containerBulkMessageContainer}>
            {activeStep === 0 && (
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                className={classes.formBulkMessageContainer}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "49%" }}>
                    <Typography className={classes.inputLabel}>
                      Nome da campanha
                    </Typography>
                    <TextField
                      className={classes.simpleTextField}
                      style={{
                        width: "100%",
                      }}
                      value={bulkMessage?.bulkMessagingData?.name}
                      onChange={(e) => {
                        setBulkMessage({
                          ...bulkMessage,
                          bulkMessagingData: {
                            ...bulkMessage.bulkMessagingData,
                            name: e.target.value,
                          },
                        });
                      }}
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Box>
                  <Box style={{ width: "49%" }}>
                    <Typography className={classes.inputLabel}>
                      Conexão de origem
                    </Typography>
                    <TextField
                      select
                      variant="outlined"
                      className={classes.simpleSelectField}
                      value={
                        whatsApps.find(
                          (whatsApp) =>
                            whatsApp.id ===
                            bulkMessage?.bulkMessagingData?.connectionId
                        ) || ""
                      }
                      onChange={(e) => {
                        setTimeout(() => {
                          setBulkMessage({
                            ...bulkMessage,
                            bulkMessagingData: {
                              ...bulkMessage.bulkMessagingData,
                              connectionId: e.target.value.id,
                            },
                          });
                        }, 100);
                        if (channel !== e.target.value.channel) {
                          setContacts([]);
                          setSelectedContacts([]);
                          setHasMoreContacts(false);
                          setContactsPage(1);
                          setToTransferContacts([]);
                          setBulkMessage({
                            ...bulkMessage,
                            contacts: [],
                          });
                        }
                        setChannel(e.target.value.channel);
                      }}
                      // defaultValue={
                      //   bulkMessage?.bulkMessagingData?.connectionId
                      //     ? whatsApps.find(
                      //         (whatsApp) =>
                      //           whatsApp.id ===
                      //           bulkMessage?.bulkMessagingData?.connectionId
                      //       )
                      //     : null
                      // }
                    >
                      {whatsApps.map((whatsApp) => (
                        <MenuItem key={whatsApp.id} value={whatsApp}>
                          {whatsApp.channel === "email" && (
                            <>
                              {/* <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" /> */}
                              <EmailIcon
                                style={{
                                  fontSize: "1.2em",
                                  marginLeft: "0.1em",
                                  marginRight: "0.4em",
                                }}
                              />
                              {whatsApp.name}
                            </>
                          )}
                          {whatsApp.channel === "whatsapp" && (
                            <>
                              <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                              {whatsApp.name}
                            </>
                          )}
                          {whatsApp.channel === "telegram" && (
                            <>
                              <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                              {whatsApp.name}
                            </>
                          )}
                          {whatsApp.channel === "whatsappcloud" && (
                            <>
                              <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                              {whatsApp.name}
                            </>
                          )}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>

                {
                  // if channel is whatsappcloud
                  channel === "whatsappcloud" ||
                  (!channel &&
                    bulkMessage?.bulkMessagingData?.connection?.channel ===
                      "whatsappcloud") ? (
                    <>
                      <Box
                        style={{
                          width: "100%",
                          height: "35vh",
                          overflow: "scroll",
                        }}
                      >
                        <TemplatesTable
                          whatsappId={
                            bulkMessage?.bulkMessagingData?.connectionId
                          }
                          setBulkMessage={setBulkMessage}
                          bulkMessage={bulkMessage}
                        />
                        {console.log(
                          "bulkMessage",
                          bulkMessage?.bulkMessagingData
                        )}
                      </Box>
                      {channel === "whatsappcloud" && (
                        <>
                          <Typography
                            variant="subtitle1"
                            style={{ alignSelf: "center" }}
                          >
                            Parâmetros do template: {templateParamsLength}
                          </Typography>
                        </>
                      )}

                      {/* <Typography className={classes.inputLabel}>
                        {
                          bulkMessage?.bulkMessagingData?.template
                            ?.components[0].format
                        }
                      </Typography> */}

                      {bulkMessage?.bulkMessagingData?.template?.components[0]
                        .format === "VIDEO" && <p>video</p>}
                      {bulkMessage?.bulkMessagingData?.template?.components[0]
                        .format === "LOCATION" && <p>localização</p>}
                      {bulkMessage?.bulkMessagingData?.template?.components[0]
                        .format === "IMAGE" && <p>imagem</p>}
                      {bulkMessage?.bulkMessagingData?.template?.components[0]
                        .format === "DOCUMENT" && <p>documento</p>}

                      {["VIDEO", "DOCUMENT", "IMAGE"].includes(
                        bulkMessage?.bulkMessagingData?.template?.components[0]
                          .format
                      ) && (
                        <TextField
                          value={bulkMessage?.bulkMessagingData?.mediaPath}
                          // disabled
                          name="name"
                          margin="dense"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  marginBottom: "0.8em",
                                }}
                              >
                                {bulkMessage?.bulkMessagingData?.mediaPath ? (
                                  <>
                                    <Tooltip
                                      title={
                                        bulkMessage?.bulkMessagingData
                                          ?.mediaPath
                                      }
                                    >
                                      <ModalImageChatbot
                                        imageUrl={
                                          bulkMessage?.bulkMessagingData
                                            ?.mediaPath
                                        }
                                        style={{ width: "10px" }}
                                      />
                                    </Tooltip>

                                    <IconButton component="span">
                                      <Tooltip title="Remover mídia">
                                        <CancelIcon
                                          onClick={() =>
                                            setBulkMessage({
                                              ...bulkMessage,
                                              bulkMessagingData: {
                                                ...bulkMessage.bulkMessagingData,
                                                mediaPath: null,
                                              },
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  </>
                                ) : (
                                  <Tooltip title="Anexar imagem">
                                    <Button component="label">
                                      <AttachFileIcon />

                                      <input
                                        accept={
                                          bulkMessage?.bulkMessagingData
                                            ?.template?.components[0].format ===
                                          "IMAGE"
                                            ? "image/png, image/jpeg, image/jpg"
                                            : bulkMessage?.bulkMessagingData
                                                ?.template?.components[0]
                                                .format === "VIDEO"
                                            ? "video/mp4"
                                            : bulkMessage?.bulkMessagingData
                                                ?.template?.components[0]
                                                .format === "DOCUMENT"
                                            ? "application/pdf"
                                            : ""
                                        }
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                          handleUpdateMedia(e);
                                        }}
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}

                      <Box
                        style={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        {
                          // create a select for each template param
                          channel === "whatsappcloud" &&
                            [...Array(templateParamsLength)].map((_, index) => {
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    // marginTop: "-1.5em",
                                    // justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    style={{ alignSelf: "center" }}
                                  >{`{{${index + 1}}}-`}</Typography>
                                  <Select
                                    defaultValue={"text"}
                                    value={
                                      templateParams[index] === "contactName"
                                        ? "contactName"
                                        : "text"
                                    }
                                    style={{
                                      margin: "1em 0",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <ListItem
                                      value="text"
                                      onClick={() => {
                                        const newTemplateParams = [
                                          ...templateParams,
                                        ];
                                        newTemplateParams[index] = "";
                                        setTemplateParams(newTemplateParams);
                                      }}
                                    >
                                      Texto Livre
                                    </ListItem>

                                    <ListItem
                                      value="contactName"
                                      onClick={() => {
                                        const newTemplateParams = [
                                          ...templateParams,
                                        ];
                                        newTemplateParams[index] =
                                          "contactName";
                                        setTemplateParams(newTemplateParams);
                                        setBulkMessage({
                                          ...bulkMessage,
                                          bulkMessagingData: {
                                            ...bulkMessage.bulkMessagingData,
                                            templateParams: newTemplateParams,
                                          },
                                        });
                                      }}
                                    >
                                      Nome do contato
                                    </ListItem>
                                  </Select>
                                  {templateParams[index] !== "contactName" && (
                                    <Input
                                      placeholder={`Parâmetro ${index + 1}`}
                                      value={templateParams[index]}
                                      onChange={(e) => {
                                        const newTemplateParams = [
                                          ...templateParams,
                                        ];
                                        newTemplateParams[index] =
                                          e.target.value;
                                        setTemplateParams(newTemplateParams);
                                        setBulkMessage({
                                          ...bulkMessage,
                                          bulkMessagingData: {
                                            ...bulkMessage.bulkMessagingData,
                                            templateParams: newTemplateParams,
                                          },
                                        });
                                      }}
                                      fullWidth
                                      autoFocus
                                      style={{
                                        margin: "1em 0",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                </Box>
                              );
                            })
                        }
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        display="flex"
                        sx={{ width: "100%" }}
                        justifyContent="space-between"
                      >
                        <Typography className={classes.inputLabel}>
                          Mensagem
                        </Typography>
                        <Typography className={classes.messageLength}>
                          {`${
                            bulkMessage?.bulkMessagingData?.message?.length || 0
                          }/${1024}`}
                        </Typography>
                      </Box>
                      <TextField
                        className={classes.simpleTextField}
                        value={bulkMessage?.bulkMessagingData?.message}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="emojiPicker"
                              component="span"
                              onClick={(e) =>
                                setShowEmoji((prevState) => !prevState)
                              }
                            >
                              <MoodIcon className={classes.sendMessageIcons} />
                            </IconButton>
                          ),
                        }}
                        inputProps={{
                          maxLength: 1024,
                        }}
                        characterLimit={1024}
                        onChange={(e) => handleMessage(e)}
                        multiline
                        minRows={4}
                        variant="outlined"
                        size="small"
                        required
                      />
                      {showEmoji ? (
                        <div className={classes.emojiBox}>
                          <ClickAwayListener
                            onClickAway={(e) => setShowEmoji(false)}
                          >
                            <Picker
                              perLine={16}
                              showPreview={false}
                              showSkinTones={false}
                              onSelect={handleAddEmoji}
                            />
                          </ClickAwayListener>
                        </div>
                      ) : null}
                      <Typography className={classes.observationText}>
                        Observação: para inserir variáveis na mensagem digite
                        "$" e defina um whatsapp não oficial para obter o nome
                        original do contato.
                      </Typography>
                      <Typography className={classes.inputLabel}>
                        Arquivo
                      </Typography>
                      <TextField
                        className={classes.simpleTextField}
                        value={bulkMessage?.bulkMessagingData?.mediaPath || ""}
                        variant="outlined"
                        size="small"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {bulkMessage?.bulkMessagingData?.mediaPath && (
                                <Box
                                  style={{
                                    borderRadius: "10px",
                                    marginRight: "10px",
                                    marginLeft: "-20px",
                                    overflow: "hidden",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                >
                                  <ModalImageChatbot
                                    imageUrl={
                                      bulkMessage?.bulkMessagingData?.mediaPath
                                    }
                                  />
                                </Box>
                              )}
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment position="end">
                              {bulkMessage?.bulkMessagingData?.mediaPath && (
                                <Button
                                  className={classes.removeUrl}
                                  onClick={() =>
                                    setBulkMessage({
                                      ...bulkMessage,
                                      bulkMessagingData: {
                                        ...bulkMessage.bulkMessagingData,
                                        mediaPath: null,
                                      },
                                    })
                                  }
                                >
                                  remover
                                </Button>
                              )}
                              <Button
                                component="label"
                                variant="contained"
                                className={classes.searchButton}
                              >
                                Procurar
                                <input
                                  type="file"
                                  hidden
                                  disabled={loadingContacts}
                                  onChange={(e) => {
                                    handleUpdateMedia(e);
                                  }}
                                />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )
                }
              </Box>
            )}

            {activeStep === 1 && (
              <Box
                width="100%"
                display="flex"
                className={classes.contactsBulkMessageContainer}
              >
                <Box width="30%">
                  <Paper className={classes.optionCard}>
                    <Radio
                      size="small"
                      checked={addContactsType === "manual"}
                      onClick={() => setAddContactsType("manual")}
                    />

                    <Box>
                      <Typography className={classes.cardTitle}>
                        Digitar os números
                      </Typography>
                      <Typography className={classes.cardDescription}>
                        Ao selecionar esta opção você poderá cadastrar
                        manualmente os contatos que receberão o envio.
                      </Typography>
                    </Box>
                  </Paper>
                  <Paper className={classes.optionCard}>
                    <Radio
                      size="small"
                      checked={addContactsType === "leads"}
                      onClick={() => setAddContactsType("leads")}
                    />

                    <Box>
                      <Typography className={classes.cardTitle}>
                        Selecionar leads
                      </Typography>
                      <Typography className={classes.cardDescription}>
                        Ao selecionar esta opção você poderá selecionar os leads
                        cadastrados na plataforma e utiliza-los no envio.
                      </Typography>
                    </Box>
                  </Paper>
                  <Paper className={classes.optionCard}>
                    <Radio
                      size="small"
                      checked={addContactsType === "csv"}
                      onClick={() => setAddContactsType("csv")}
                    />

                    <Box>
                      <Typography className={classes.cardTitle}>
                        Importar lista de leads
                      </Typography>
                      <Typography className={classes.cardDescription}>
                        Ao selecionar esta opção você poderá inserir arquivos do
                        tipo .csv contendo os leads que receberão o envio.
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
                <Box width="70%" className={classes.contactsContainer}>
                  <Box width="100%" display="flex">
                    {addContactsType === "manual" && (
                      <>
                        <Box width="50%">
                          <TextField
                            className={classes.contactSimpleTextField}
                            value={newUser?.name}
                            onChange={(e) =>
                              setNewUser({ ...newUser, name: e.target.value })
                            }
                            onKeyDown={(e) => {
                              if (
                                (newUser.number.length === 12 ||
                                  newUser.number.length === 13) &&
                                e.key === "Enter"
                              ) {
                                handleCreateContact();
                              }
                            }}
                            variant="outlined"
                            size="small"
                            label="Nome do contato"
                          />
                        </Box>
                        <Box width="50%">
                          <TextField
                            className={classes.contactSimpleTextField}
                            value={newUser?.number}
                            onChange={(e) =>
                              setNewUser({ ...newUser, number: e.target.value })
                            }
                            onKeyDown={(e) => {
                              if (
                                (newUser.number.length === 12 ||
                                  newUser.number.length === 13) &&
                                e.key === "Enter"
                              ) {
                                handleCreateContact();
                              }
                            }}
                            variant="outlined"
                            size="small"
                            label="Número do contato"
                            InputProps={{
                              inputProps: {
                                maxLength: 13,
                                pattern: "[0-9]*",
                              },
                            }}
                            error={
                              newUser.number.length < 12 ||
                              newUser.number.length > 13
                            }
                            helperText={
                              newUser.number.length < 12 ||
                              newUser.number.length > 13
                                ? "Exemplo: 5527998851972"
                                : ""
                            }
                          />
                        </Box>
                        <Button
                          variant="contained"
                          className={classes.addContactBtn}
                          color="primary"
                          disabled={
                            newUser.number === "" ||
                            newUser.number.length < 12 ||
                            newUser.number.length > 13
                          }
                          onClick={() => {
                            handleCreateContact();
                          }}
                        >
                          +
                        </Button>
                      </>
                    )}

                    {addContactsType === "leads" && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box className={classes.filtersBox}>
                          <TextField
                            className={classes.contactSearchTextField}
                            value={searchParam}
                            onChange={(e) => setSearchParam(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                setContactsPage(1);
                              }
                            }}
                            InputProps={
                              searchParam.length > 0 && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setSearchParam("");
                                      }}
                                    >
                                      <ClearIcon size="small" />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }
                            }
                            variant="outlined"
                            size="small"
                            label="Nome ou número do contato"
                          />

                          <TextField
                            className={classes.tagsInput}
                            select
                            variant="outlined"
                            size="small"
                            label="Tags"
                            value={selectedTags}
                            onChange={(e) => {
                              setContactsPage(1);
                              setSelectedTags(e.target.value);
                            }}
                            SelectProps={{
                              multiple: true,
                              // dont move dropdown on select option
                              MenuProps: {
                                getContentAnchorEl: () => null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              },

                              renderValue: (selected) => (
                                <Box
                                  sx={{
                                    fontSize: "0.7em",
                                  }}
                                >
                                  {
                                    // join the tags name
                                    selected
                                      .map((value) =>
                                        tags.find((t) => t.id === value)
                                      )
                                      .map((tag) => tag.name)
                                      .join(", ")
                                  }
                                </Box>
                              ),
                            }}
                          >
                            {tags?.map((tag) => (
                              <MenuItem key={tag.id} value={tag.id}>
                                {tag.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <Button
                            variant="contained"
                            className={classes.searchButton}
                            onClick={() => {
                              getContacts({ action: "reset" });
                            }}
                          >
                            Pesquisar
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            className={
                              addContactsType === "leads"
                                ? classes.countContactsOnLead
                                : classes.countContacts
                            }
                          >
                            {`Contatos buscados: ${contacts?.length}`}
                          </Typography>
                          <TableContainer
                            onScroll={handleScroll}
                            className={classes.tableContainerOnLeads}
                            // set shadow
                          >
                            <Table className={classes.tableStyle} stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      disableRipple
                                      color="default"
                                      checkedIcon={
                                        <span
                                          className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                          )}
                                        />
                                      }
                                      icon={<span className={classes.icon} />}
                                      inputProps={{
                                        "aria-label": "decorative checkbox",
                                      }}
                                      // {...props}
                                      size="small"
                                      checked={
                                        toTransferContacts.length ===
                                        contacts?.length
                                      }
                                      onClick={() => {
                                        if (
                                          toTransferContacts.length ===
                                          contacts?.length
                                        ) {
                                          setToTransferContacts([]);
                                        } else {
                                          setToTransferContacts(
                                            contacts?.map((contact) => contact)
                                          );
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>Nome</TableCell>
                                  {channel === "whatsapp" ||
                                  channel === "whatsappcloud" ? (
                                    <TableCell>Número</TableCell>
                                  ) : channel === "email" ? (
                                    <TableCell>Email</TableCell>
                                  ) : null}
                                  <TableCell />
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {loadingContacts && (
                                  <TableRow>
                                    <TableCell>
                                      <CircularProgress
                                        style={{
                                          width: "12px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          margin: "auto",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <CircularProgress
                                        style={{
                                          width: "12px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <CircularProgress
                                        style={{
                                          width: "12px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                                {contacts?.map((contact, index) => (
                                  <TableRow key={`contact-${index}`}>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        disableRipple
                                        color="default"
                                        checkedIcon={
                                          <span
                                            className={clsx(
                                              classes.icon,
                                              classes.checkedIcon
                                            )}
                                          />
                                        }
                                        icon={<span className={classes.icon} />}
                                        inputProps={{
                                          "aria-label": "decorative checkbox",
                                        }}
                                        size="small"
                                        checked={toTransferContacts.includes(
                                          contact
                                        )}
                                        onClick={() => {
                                          if (
                                            toTransferContacts.includes(contact)
                                          ) {
                                            setToTransferContacts(
                                              toTransferContacts.filter(
                                                (item) => item !== contact
                                              )
                                            );
                                          } else {
                                            setToTransferContacts([
                                              ...toTransferContacts,
                                              contact,
                                            ]);
                                          }
                                        }}
                                        // inputProps={{ "aria-labelledby": labelId }}
                                      />
                                    </TableCell>
                                    <TableCell>{contact?.name}</TableCell>
                                    {channel === "whatsapp" ||
                                    channel === "whatsappcloud" ? (
                                      <TableCell>
                                        {
                                          // format phone number to brazilian format for 12 and 13 digits +55 (27) 99885-1972
                                          contact?.number?.length === 12
                                            ? `+${contact?.number?.slice(
                                                0,
                                                2
                                              )} (${contact?.number?.slice(
                                                2,
                                                4
                                              )}) ${contact?.number?.slice(
                                                4,
                                                8
                                              )}-${contact?.number?.slice(
                                                8,
                                                12
                                              )}`
                                            : `+${contact?.number?.slice(
                                                0,
                                                2
                                              )} (${contact?.number?.slice(
                                                2,
                                                4
                                              )}) ${contact?.number?.slice(
                                                4,
                                                9
                                              )}-${contact?.number?.slice(
                                                9,
                                                13
                                              )}`
                                        }
                                      </TableCell>
                                    ) : channel === "email" ? (
                                      <TableCell>{contact.number}</TableCell>
                                    ) : null}
                                    <TableCell> </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            disabled={toTransferContacts.length === 0}
                            className={classes.transferSelectedButton}
                            onClick={() => {
                              setBulkMessage({
                                ...bulkMessage,
                                contacts: [
                                  ...bulkMessage.contacts,
                                  ...toTransferContacts,
                                ],
                              });

                              setContacts([
                                ...contacts.filter(
                                  (item) => !toTransferContacts.includes(item)
                                ),
                              ]);

                              setToTransferContacts([]);
                            }}
                          >
                            {`Transferir marcados (${toTransferContacts.length})`}
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {addContactsType === "csv" && (
                      <div className={ classes.csvActionsContainer }>
                        <TextField
                          className={classes.simpleTextField}
                          value={csvName}
                          variant="outlined"
                          placeholder="Inserir arquivo CSV"
                          size="small"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  component="label"
                                  variant="contained"
                                  className={classes.searchButton}
                                >
                                  Procurar
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      setContactsByCSV(e);
                                    }}
                                  />
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          target="_blank"
                          href="/example.csv"
                          className={ classes.downloadButton }
                        >
                          Download exemplo
                        </Button>
                      </div>
                    )}
                  </Box>

                  <Box>
                    <Typography
                      className={
                        addContactsType === "leads"
                          ? classes.countContactsOnLead
                          : classes.countContacts
                      }
                      sx={{
                        marginTop: addContactsType === "leads" ? 35 : 0,
                      }}
                    >
                      {`Contatos carregados: ${bulkMessage?.contacts?.length}`}
                    </Typography>
                    <TableContainer
                      // tableContainerOnLeads
                      className={
                        addContactsType === "leads"
                          ? classes.tableContainerOnLeads
                          : classes.tableContainer
                      }
                    >
                      <Table className={classes.tableStyle} stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                disableRipple
                                color="default"
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                inputProps={{
                                  "aria-label": "decorative checkbox",
                                }}
                                // {...props}
                                size="small"
                                checked={
                                  selectedContacts.length ===
                                  bulkMessage?.contacts?.length
                                }
                                onClick={() => {
                                  if (
                                    selectedContacts.length ===
                                    bulkMessage?.contacts?.length
                                  ) {
                                    setSelectedContacts([]);
                                  } else {
                                    setSelectedContacts(
                                      bulkMessage?.contacts?.map(
                                        (contact) => contact
                                      )
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>Nome</TableCell>
                            {channel === "whatsapp" ||
                            channel === "whatsappcloud" ? (
                              <TableCell>Número</TableCell>
                            ) : channel === "email" ? (
                              <TableCell>Email</TableCell>
                            ) : null}
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bulkMessage?.contacts?.map((contact, index) => (
                            <TableRow key={`contact-${index}`}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  disableRipple
                                  color="default"
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  icon={<span className={classes.icon} />}
                                  inputProps={{
                                    "aria-label": "decorative checkbox",
                                  }}
                                  size="small"
                                  checked={selectedContacts.includes(contact)}
                                  onClick={() => {
                                    if (selectedContacts.includes(contact)) {
                                      setSelectedContacts(
                                        selectedContacts.filter(
                                          (item) => item !== contact
                                        )
                                      );
                                    } else {
                                      setSelectedContacts([
                                        ...selectedContacts,
                                        contact,
                                      ]);
                                    }
                                  }}
                                  // inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell>{contact.name}</TableCell>
                              {channel === "whatsapp" ||
                              channel === "whatsappcloud" ? (
                                <TableCell>
                                  {
                                    // format phone number to brazilian format for 12 and 13 digits +55 (27) 99885-1972
                                    contact?.number?.length === 12
                                      ? `+${contact?.number?.slice(
                                          0,
                                          2
                                        )} (${contact?.number?.slice(
                                          2,
                                          4
                                        )}) ${contact?.number?.slice(
                                          4,
                                          8
                                        )}-${contact?.number?.slice(8, 12)}`
                                      : `+${contact?.number?.slice(
                                          0,
                                          2
                                        )} (${contact?.number?.slice(
                                          2,
                                          4
                                        )}) ${contact?.number?.slice(
                                          4,
                                          9
                                        )}-${contact?.number?.slice(9, 13)}`
                                  }
                                </TableCell>
                              ) : channel === "email" ? (
                                <TableCell>{contact.number}</TableCell>
                              ) : null}
                              <TableCell>
                                <IconButton className={classes.actionButton}>
                                  <DeleteIcon
                                    onClick={() => {
                                      setBulkMessage({
                                        ...bulkMessage,
                                        contacts: bulkMessage.contacts.filter(
                                          (item) => item !== contact
                                        ),
                                      });
                                    }}
                                    className={classes.actionIcon}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      disabled={selectedContacts.length === 0}
                      className={classes.deleteSelectedButton}
                      onClick={() => {
                        setBulkMessage({
                          ...bulkMessage,
                          contacts: bulkMessage.contacts.filter(
                            (item) => !selectedContacts.includes(item)
                          ),
                        });
                        setSelectedContacts([]);
                      }}
                    >
                      {`Deletar marcados (${selectedContacts.length})`}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {activeStep === 2 && (
              <Box width="100%" className={classes.formBulkMessageContainer}>
                <Box className={classes.temporalBulkForm}>
                  <Box width="40%" className={classes.dividedContainer}>
                    <ButtonGroup disableElevation color="primary">
                      <Button
                        className={classes.groupButton}
                        variant={triggerType === "immediate" ? "contained" : ""}
                        onClick={() => {
                          setTriggerType("immediate");
                          setBulkMessage({
                            ...bulkMessage,
                            bulkMessagingData: {
                              ...bulkMessage.bulkMessagingData,
                              scheduled: false,
                            },
                          });
                        }}
                      >
                        Imediato
                      </Button>
                      <Button
                        className={classes.groupButton}
                        variant={
                          triggerType === "scheduled" ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setTriggerType("scheduled");
                          setBulkMessage({
                            ...bulkMessage,
                            bulkMessagingData: {
                              ...bulkMessage.bulkMessagingData,
                              scheduled: true,
                            },
                          });
                        }}
                      >
                        Agendado
                      </Button>
                    </ButtonGroup>

                    <Box>
                      <Typography className={classes.inputLabel}>
                        Data do agendamento
                      </Typography>

                      <TextField
                        variant="outlined"
                        size="small"
                        id="datetime-local"
                        type="datetime-local"
                        disabled={triggerType === "immediate"}
                        defaultValue={
                          bulkMessageId
                            ? getDateFormat(
                                bulkMessage?.bulkMessagingData?.trigger
                              )
                            : new Date()
                                .toISOString()
                                .split(".")[0]
                                .substring(0, 16)
                        }
                        className={classes.temporalFields}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          // convert e.target.value to "2021-08-31T18:00:00.000Z"
                          const date = new Date(e.target.value);
                          try {
                            const isoDate = date.toISOString();

                            setBulkMessage({
                              ...bulkMessage,
                              bulkMessagingData: {
                                ...bulkMessage.bulkMessagingData,
                                trigger: isoDate,
                              },
                            });
                          } catch (error) {}
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography className={classes.inputLabel}>
                        Intervalo entre mensagens (segundos)
                      </Typography>

                      <TextField
                        variant="outlined"
                        size="small"
                        id="datetime-local"
                        type="number"
                        // only positive
                        inputProps={{ min: "0", step: "1" }}
                        // disabled={triggerType === "immediate"}
                        value={
                          bulkMessage?.bulkMessagingData?.messageInterval || 0
                        }
                        onChange={(e) =>
                          setBulkMessage({
                            ...bulkMessage,
                            bulkMessagingData: {
                              ...bulkMessage.bulkMessagingData,
                              messageInterval: e.target.value,
                            },
                          })
                        }
                        className={classes.temporalFields}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography className={classes.inputLabel}>
                        Intervalo entre grupos (leads x segundos)
                      </Typography>

                      <Box display="flex">
                        <TextField
                          variant="outlined"
                          size="small"
                          id="datetime-local"
                          type="number"
                          inputProps={{ min: "0", step: "1" }}
                          // disabled={triggerType === "immediate"}
                          value={
                            bulkMessage?.bulkMessagingData?.clusterSize || 0
                          }
                          onChange={(e) =>
                            setBulkMessage({
                              ...bulkMessage,
                              bulkMessagingData: {
                                ...bulkMessage.bulkMessagingData,
                                clusterSize: e.target.value,
                              },
                            })
                          }
                          className={classes.firstSlicedTemporalFields}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Box className={classes.slicedTemporalFieldsX}>x</Box>
                        {/* <Box className={classes.slicedTemporalFieldsX}>
                          <CloseIcon
                            className={classes.slicedTemporalFieldsIcon}
                          />
                        </Box> */}
                        <TextField
                          variant="outlined"
                          size="small"
                          id="datetime-local"
                          type="number"
                          inputProps={{ min: "0", step: "1" }}
                          // disabled={triggerType === "immediate"}
                          value={
                            bulkMessage?.bulkMessagingData?.clusterInterval || 0
                          }
                          onChange={(e) =>
                            setBulkMessage({
                              ...bulkMessage,
                              bulkMessagingData: {
                                ...bulkMessage.bulkMessagingData,
                                clusterInterval: e.target.value,
                              },
                            })
                          }
                          className={classes.secondSlicedTemporalFields}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Divider orientation="vertical" flexItem />

                  <Box width="40%">
                    <Typography className={classes.valueTitle}>
                      Nome da campanha:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {bulkMessage?.bulkMessagingData?.name}
                    </Typography>
                    <Typography className={classes.valueTitle}>
                      Contatos selecionados:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {bulkMessage?.contacts.length}
                    </Typography>
                    <Typography className={classes.valueTitle}>
                      Mensagem:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {styledMessage}
                    </Typography>

                    <Typography className={classes.valueTitle}>
                      Arquivo:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {bulkMessage?.bulkMessagingData?.mediaPath ||
                        "Nenhum arquivo selecionado"}
                    </Typography>
                    {/* {
                      // if === immediate
                      triggerType === "scheduled" && (
                        <> */}
                    <Typography className={classes.valueTitle}>
                      Tempo de Intervalo:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {bulkMessage?.bulkMessagingData?.messageInterval || 0}
                    </Typography>

                    <Typography className={classes.valueTitle}>
                      Intervalo entre grupos:
                    </Typography>
                    <Typography className={classes.valueText}>
                      {`${bulkMessage?.bulkMessagingData?.clusterSize || 0} x ${
                        bulkMessage?.bulkMessagingData?.clusterInterval || 0
                      }`}
                    </Typography>
                    {/* </>
                      )
                    } */}
                  </Box>
                </Box>
              </Box>
            )}

            <Box className={classes.actionsContainer}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Voltar
              </Button>

              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                  className={classes.button}
                >
                  Finalizar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleComplete}
                  className={classes.button}
                  disabled={
                    (["VIDEO", "DOCUMENT", "IMAGE"].includes(
                      bulkMessage?.bulkMessagingData?.template?.components[0]
                        .format
                    ) &&
                      !bulkMessage?.bulkMessagingData?.mediaPath) ||
                    bulkMessage?.bulkMessagingData?.name === "" ||
                    !bulkMessage?.bulkMessagingData?.name ||
                    ((bulkMessage?.bulkMessagingData?.message === "" ||
                      !bulkMessage?.bulkMessagingData?.message) &&
                      !bulkMessage?.bulkMessagingData?.templateId &&
                      !bulkMessage?.bulkMessagingData?.customTemplateId) ||
                    !bulkMessage?.bulkMessagingData?.connectionId ||
                    (templateParamsLength > 0 &&
                      templateParams.some(
                        (param) => param === "" || param === null
                      ))
                  }
                >
                  Continuar
                </Button>
              )}
            </Box>
          </Paper>
        )}
      </div>
    </Dialog>
  );
}
