import React from "react";

import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

export const MultiSelect = ({ label, filters, options, onChange, name }) => {
  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <InputLabel
        shrink
        style={{
          backgroundColor: "#fafafa",
          fontSize: "1.2em",
          marginTop: -7,
          marginLeft: 10,
        }}
      >
        {label}
      </InputLabel>

      <Select
        style={{
          marginTop: -9,
          fontSize: "1.1em",
          height: 40,
          width: "100%",
        }}
        shrink={true}
        notched
        multiple
        label={label}
        displayEmpty
        variant="outlined"
        value={filters?.[name] || []}
        onChange={onChange}
        name={name}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "Todos";
          }

          return selected
            .map((id) => {
              const entity = options?.find((option) => option.id === id);
              return entity?.name;
            })
            .join(", ");
        }}
      >
        {options?.length > 0 &&
          options.map((option) => (
            <MenuItem dense key={option.id} value={option.id}>
              <Checkbox
                size="small"
                color="primary"
                checked={filters?.[name]?.includes(option.id)}
              />
              <ListItemText primary={option?.name} />
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};
