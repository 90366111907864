import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import toastError from '../../errors/toastError';

function LocationPreview({
  image, link, description, clientUrl,
}) {
  useEffect(() => {}, [image, link, description]);

  const handleLocation = async () => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div>
      <div style={{ width: '200px' }}>
        <div
          style={{
            minWidth: '200px',
            maxHeight: '120px',
            overflow: 'hidden',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            borderRadius: '10px',
          }}
        >
          <img
            src={image}
            alt=""
            onClick={handleLocation}
            style={{
              width: '100%',
              height: '100%',
              // objectFit: "cover",
            }}
          />
        </div>
        {description && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              color: '#3fa3bf',
              marginLeft: '2px',
              marginBottom: 0,
              width: '100%',
              fontSize: '1em',
              whiteSpace: 'nowrap',
              textWrap: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
                // variant="subtitle1"
            color="primary"
            gutterBottom
          >
            <Link
              href={clientUrl}
              target="_blank"
              style={{ textDecoration: 'none', color: '#3fa3bf' }}
            >
              {description.split('\n')[0]}
            </Link>
          </Typography>
          <Typography
            style={{
              color: '#223440',
              marginLeft: '2px',
              marginTop: '-2px',
              marginBottom: '1em',
              width: '100%',
              fontSize: '0.9em',
              whiteSpace: 'nowrap',
              textWrap: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
                // variant="subtitle1"
            color="primary"
            gutterBottom
          >
            {description.split('\n')[1]}
          </Typography>
        </div>
        )}
        <div
          style={{
            display: 'block',
            content: '',
            clear: 'both',
          }}
        />
        {/* <div>
          	<Divider />
            <Button
              fullWidth
              color="primary"
              onClick={handleLocation}
              disabled={!link}
            >
              Visualizar
            </Button>
          </div> */}
      </div>
    </div>
  );
}

export default LocationPreview;
