import React, { useState, useCallback, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import {
  Edit,
  Link,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  Facebook,
  DeleteOutline,
} from "@material-ui/icons";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";

import PermissionModal from "../../components/PermissionModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import axios from "axios";
import ApiKey from "../ApiKey/index";
import HubButton from "./HubButton";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));

function CustomToolTip({ title, content, children }) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}

function Connections() {
  const classes = useStyles();

  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );
  const { user } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);

  const handleClose = async () => {
    try {
      await api.post("/whatsappsession/resetBackend/0");
    } catch (err) {
      toastError(err);
    }
    setOpen(false);
  };

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
    setOpen(true);
  };

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleWebhook = async (whatsApp) => {
    try {
      await api.put(`/webhook/${whatsApp.id}`);
      toast.success(i18n.t("WEBHOOK UPDATED"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId,
      });
    }
    user.profile === "admin"
      ? setConfirmModalOpen(true)
      : setPermissionOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const renderActionButtons = (connection) => (
    <>
      <PermissionModal open={permissionOpen} onClose={setPermissionOpen} />
      {connection.channel === "whatsapp" && (
        <>
          {connection.status === "qrcode" && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleOpenQrModal(connection)}
            >
              {i18n.t("connections.buttons.qrcode")}
            </Button>
          )}
          {connection.status === "DISCONNECTED" && (
            <>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleStartWhatsAppSession(connection.id)}
              >
                {i18n.t("connections.buttons.tryAgain")}
              </Button>{" "}
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleRequestNewQrCode(connection.id)}
              >
                {i18n.t("connections.buttons.newQr")}
              </Button>
            </>
          )}
          {(connection.status === "CONNECTED" ||
            connection.status === "PAIRING" ||
            connection.status === "TIMEOUT") && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleOpenConfirmationModal("disconnect", connection.id);
              }}
            >
              {i18n.t("connections.buttons.disconnect")}
            </Button>
          )}
          {connection.status === "OPENING" && (
            <Button size="small" variant="outlined" disabled color="default">
              {i18n.t("connections.buttons.connecting")}
            </Button>
          )}
        </>
      )}
      {connection.channel !== "whatsapp" && (
        <Typography size="small" variant="outlined" color="secondary">
          {connection.status}
        </Typography>
      )}
    </>
  );

  const renderStatusToolTips = (connection) => (
    <div className={classes.customTableCell}>
      {connection.status === "DISCONNECTED" && (
        <CustomToolTip
          title={i18n.t("connections.toolTips.disconnected.title")}
          content={i18n.t("connections.toolTips.disconnected.content")}
        >
          <SignalCellularConnectedNoInternet0Bar color="secondary" />
        </CustomToolTip>
      )}
      {connection.status === "OPENING" && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {connection.status === "qrcode" && (
        <CustomToolTip
          title={i18n.t("connections.toolTips.qrcode.title")}
          content={i18n.t("connections.toolTips.qrcode.content")}
        >
          <CropFree />
        </CustomToolTip>
      )}
      {connection.status === "CONNECTED" && (
        <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
          <SignalCellular4Bar style={{ color: green[500] }} />
        </CustomToolTip>
      )}
      {(connection.status === "TIMEOUT" || connection.status === "PAIRING") && (
        <CustomToolTip
          title={i18n.t("connections.toolTips.timeout.title")}
          content={
            connection.channel !== "email"
              ? i18n.t("connections.toolTips.timeout.content")
              : i18n.t("connections.toolTips.timeout.contentEmail")
          }
        >
          <SignalCellularConnectedNoInternet2Bar color="secondary" />
        </CustomToolTip>
      )}
    </div>
  );

  return (
    <MainContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Use Google's location service?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmar reiniciar o backend da applicação?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
      <QrcodeModal
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
      />
      <WhatsAppModal
        open={whatsAppModalOpen}
        onClose={handleCloseWhatsAppModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <MainHeader>
        <Title>{i18n.t("connections.title")}</Title>
        <MainHeaderButtonsWrapper>
          <HubButton />

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenWhatsAppModal}
          >
            {i18n.t("connections.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("connections.table.id")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.channel")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.session")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.lastUpdate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.default")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton />
            ) : (
              <>
                {whatsApps?.length > 0 &&
                  whatsApps.map((whatsApp) => (
                    <TableRow key={whatsApp.id}>
                      <TableCell align="center">{whatsApp.id}</TableCell>
                      <TableCell align="center">{whatsApp.name}</TableCell>
                      <TableCell align="center">{whatsApp.channel}</TableCell>
                      <TableCell align="center">
                        {/* working */}
                        {renderStatusToolTips(whatsApp)}
                      </TableCell>
                      <TableCell align="center">
                        {renderActionButtons(whatsApp)}
                      </TableCell>
                      <TableCell align="center">
                        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {whatsApp.isDefault && (
                          <div className={classes.customTableCell}>
                            <CheckCircle style={{ color: green[500] }} />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {["whatsappcloud", "facebook"].includes(
                          whatsApp.channel
                        ) && (
                          <Tooltip title="Reconectar webhook">
                            <IconButton
                              size="small"
                              style={{
                                marginLeft: "-30px",
                              }}
                              onClick={() => handleWebhook(whatsApp)}
                            >
                              <Link color="secondary" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <IconButton
                          size="small"
                          onClick={() => handleEditWhatsApp(whatsApp)}
                        >
                          <Edit color="secondary" />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            handleOpenConfirmationModal("delete", whatsApp.id);
                          }}
                        >
                          <DeleteOutline color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}

export default Connections;
