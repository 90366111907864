import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import { Box, Divider, Typography } from "@material-ui/core";

import TicketsListSkeleton from "../TicketsListSkeleton";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ContactCard from "./ContactCard";
import MessageCard from "./MessageCard";
import TicketListItem from "../TicketListItem";
import CustomizedTabs from "./CustomizedTabs";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    overflow: "scroll",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function SearchedTicketsList(props) {
  const {
    status,
    searchParam,
    showAll,
    showUnread,
    selectedQueueIds,
    updateCount,
    style,
    searched,
    setSearchLoading,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [searchedMessages, setSearchedMessages] = useState([]);
  const [searchedTickets, setSearchedTickets] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues?.map((q) => q.id);

  useEffect(() => {
    setSearchedMessages([]);
    setPageNumber(1);
  }, [status, searched, showAll, showUnread, selectedQueueIds]);

  useEffect(() => {
    if (!status && !searchParam) return;
    setLoading(true);
    setSearchLoading(true);

    try {
      (async () => {
        const params = {
          searchParam,
          pageNumber,
          showAll: true,
          showUnread,
          queueIds: JSON.stringify(userQueueIds),
          /*  status,
          withUnreadMessages, */
        };

        const { data } = await api.get("/tickets/search", {
          params,
        });

        setSearchedContacts(data.contacts);

        console.log({ data });
        // data.tickets.filter((t) => selectedQueueIds.includes(t.queueId))

        setSearchedTickets(
          ...data.contacts.map((c) => {
            const tickets = [];
            c.tickets.forEach((t) => {
              if (selectedQueueIds.includes(t.queueId)) {
                tickets.push(t);
              }
            });
            return tickets;
          })
        );

        setSearchedMessages(data.messages);

        setHasMore(data.hasMore);
        setLoading(false);
        setSearchLoading(false);
      })();
    } catch (error) {
      console.log(error);
      toastError(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searched, selectedQueueIds]);

  console.log({ searchedTickets });

  useEffect(() => {
    if (!status && !searchParam) return;
    setLoading(true);
    setSearchLoading(true);

    try {
      (async () => {
        const params = {
          searchParam,
          pageNumber,
          showAll: true,
          queueIds: JSON.stringify(userQueueIds),
          /*  status,
          withUnreadMessages, */
        };

        const { data } = await api.get("/tickets/search", {
          params,
        });

        // setSearchedContacts((prevState) => [...prevState, ...data.tickets]);

        setSearchedMessages((prevState) => [...prevState, ...data.messages]);

        setHasMore(data.hasMore);
        setLoading(false);
        setSearchLoading(false);
      })();
    } catch (error) {
      console.log(error);
      toastError(error);
      setLoading(false);
    }
  }, [pageNumber]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {searchedContacts.length === 0 &&
          searchedMessages.length === 0 &&
          !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <Box
              overflow="scroll"
              style={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "1rem",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  color: "#088069",
                }}
              >
                Contatos
              </Typography>

              {searchedContacts.map((contact) => {
                return (
                  <>
                    <ContactCard contact={contact} />
                    <Divider />
                  </>
                );
              })}

              <CustomizedTabs
                searchParam={searchParam}
                searchedMessages={searchedMessages}
                searchedTickets={searchedTickets}
              />
            </Box>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
}

export default SearchedTicketsList;
