import React, { useEffect, useState } from "react";
import { uuid } from "uuidv4";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  DialogContentText,
  Tooltip,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";
import ModalImageChatbot from "../ModalImageChatbot";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function TemplateModal({ open, onClose, selectedTemplate, whatsApps }) {
  const classes = useStyles();

  const [newTemplate, setNewTemplate] = useState(selectedTemplate);
  const [error, setError] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [category, setCategory] = useState("UTILITY");
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mediaPath, setMediaPath] = useState(
    // "https://setting.com.br/wp-content/uploads/2019/02/exemplo-de-inovacao-processo-1.jpg"
    null
  );

  const uuidNum = uuid();

  useEffect(() => {
    if (!selectedWhatsApp) return;
    async function getTemplates() {
      try {
        setLoading(true);
        const { wabaAccessToken, wabaAccountId } = selectedWhatsApp;

        const response = await fetch(
          `https://graph.facebook.com/v15.0/${wabaAccountId}/message_templates`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${wabaAccessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        console.log({ templatesData: data });

        const filteredTemplates = data.data.filter((template) => {
          return (
            template.name.includes("notificame") &&
            template.components[0].format === category.toUpperCase()
          );
        });

        setTemplates(filteredTemplates);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    getTemplates();
  }, [selectedWhatsApp, category]);

  const handleClose = () => {
    onClose();
  };

  const generateTemplateComponents = ({
    header = "",
    body = "",
    footer = "",
  }) => {
    const components = [
      { type: "HEADER", format: "TEXT", text: header },
      { type: "BODY", text: body },
      { type: "FOOTER", text: footer },
    ];
    return components;
  };

  const handleAddTemplate = async () => {
    setLoading(true);

    console.log({ category });

    if (category === "video") {
      try {
        const data = await api.post("/template", {
          name: newTemplate.name,
          templateId: template.id,
          templateName: template.name,
          type: "video",
          mediaPath,
          connectionId: selectedWhatsApp.id,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      handleClose();
      return;
    }

    if (category === "image") {
      try {
        const data = await api.post("/template", {
          name: newTemplate.name,
          templateId: template.id,
          templateName: template.name,
          type: "image",
          mediaPath,
          connectionId: selectedWhatsApp.id,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      handleClose();
      return;
    }

    if (mediaPath) {
      const handle = await api.post("/WhatsAppMeta/generate-media-id", {
        mediaPath,
        selectedWhatsApp,
      });

      try {
        // setLoading(true);
        // const templateName = `notificame_${newTemplate.name}`
        const templateName = `notificame_${uuidNum}`;

        const payload = {
          name: templateName.split("-").join("_").toLocaleLowerCase(),
          components: [
            {
              type: "HEADER",
              format: "IMAGE",
              example: {
                header_handle: [handle.data],
              },
            },
            {
              type: "BODY",
              text: newTemplate.body,
            },
          ],
          language: "pt_BR",
          category,
        };

        const response = await fetch(
          `https://graph.facebook.com/v15.0/${
            selectedWhatsApp.wabaAccountId
          }/message_templates?name=${templateName
            .split("-")
            .join("_")
            .toLocaleLowerCase()}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${selectedWhatsApp.wabaAccessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to send payload.");
        }

        const data = await response.json();
        setLoading(false);
        handleClose(data);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        // setLoading(true);
        // const templateName = `notificame_${newTemplate.name}`
        const templateName = `notificame_${uuidNum}`;
        const response = await fetch(
          `https://graph.facebook.com/v15.0/${
            selectedWhatsApp.wabaAccountId
          }/message_templates?name=${templateName
            .split("-")
            .join("_")
            .toLocaleLowerCase()}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${selectedWhatsApp.wabaAccessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              language: "pt_BR",
              category,
              components: generateTemplateComponents({
                header: newTemplate.header,
                body: newTemplate.body,
                footer: newTemplate.footer,
              }),
            }),
          }
        );
        const data = await response.json();
        handleClose(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      // setLoading(true);
      const response = await fetch(
        `https://graph.facebook.com/v15.0/${selectedTemplate.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${selectedTemplate.whatsApp.wabaAccessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language: "pt_BR",
            category: "TRANSACTIONAL",
            components: generateTemplateComponents({
              header: newTemplate.header,
              body: newTemplate.body,
              footer: newTemplate.footer,
            }),
          }),
        }
      );
      const data = await response.json();
      handleClose(data);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  const handleUpdateMedia = async (e) => {
    if (!e.target.files) {
      return;
    }

    const img = e.target.files[0];
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", img);
    const response = await api.post("/chatbot/node-upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const fileName = img.name;

    const url = `${process.env.REACT_APP_BACKEND_URL}/public/${fileName}`;

    setMediaPath(url);
    // setCategory("MARKETING");
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        // open={true}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {/* {selectedTemplate
						? `${i18n.t('userModal.title.edit')}`
						: `${i18n.t('userModal.title.add')}`
					} */}
          {selectedTemplate.header
            ? `${i18n.t("templates.templateModal.title.update")}`
            : `${i18n.t("templates.templateModal.title.create")}`}
        </DialogTitle>
        <DialogContent>
          {!selectedTemplate.header && (
            <>
              <InputLabel
                id="connection"
                style={{
                  marginTop: "1em",
                }}
              >
                {i18n.t("templates.templateModal.connection")}
              </InputLabel>
              <Select
                margin="dense"
                id="connection"
                fullWidth
                required
                value={selectedWhatsApp}
                label={i18n.t("templates.templateModal.connection")}
                onChange={(e) => setSelectedWhatsApp(e.target.value)}
              >
                {whatsApps
                  .filter((whatsApp) => whatsApp.wabaAccessToken)
                  .map((whatsApp) => (
                    <MenuItem value={whatsApp}>{whatsApp.name}</MenuItem>
                  ))}
              </Select>
            </>
          )}
          <InputLabel
            id="type"
            style={{
              marginTop: "1em",
            }}
          >
            {/* {i18n.t("templates.templateModal.type")} */}
            Tipo
          </InputLabel>
          <Select
            margin="dense"
            id="category"
            fullWidth
            required
            value={category}
            label={i18n.t("templates.templateModal.connection")}
            onChange={(e) => setCategory(e.target.value)}
            disabled={mediaPath}
          >
            <MenuItem value="AUTHENTICATION">Autenticação</MenuItem>
            <MenuItem value="MARKETING">Marketing</MenuItem>
            <MenuItem value="UTILITY">Utilidade</MenuItem>
            <MenuItem value="video">Vídeo</MenuItem>
            <MenuItem value="image">Imagem</MenuItem>
          </Select>
          {!["video", "image"].includes(category) && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="HEADER"
                label={i18n.t("templates.templateModal.header")}
                type="text"
                fullWidth
                required
                inputProps={{
                  maxLength: 60,
                }}
                initialValue={selectedTemplate.header}
                value={newTemplate.header}
                helperText={`${newTemplate.header?.length || 0}/${60}`}
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, header: e.target.value })
                }
              />
              <TextField
                multiline
                minRows={4}
                autoFocus
                margin="dense"
                id="BODY"
                label={i18n.t("templates.templateModal.body")}
                type="text"
                fullWidth
                required
                inputProps={{
                  maxLength: 1024,
                }}
                characterLimit={1024}
                helperText={`${newTemplate.body?.length || 0}/${1024}`}
                initialValue={selectedTemplate.body}
                value={newTemplate.body}
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, body: e.target.value })
                }
              />
              <TextField
                autoFocus
                margin="dense"
                id="FOOTER"
                label={i18n.t("templates.templateModal.footer")}
                type="text"
                fullWidth
                required
                inputProps={{
                  maxLength: 60,
                }}
                initialValue={selectedTemplate.footer}
                value={newTemplate.footer}
                helperText={`${newTemplate.footer?.length || 0}/${60}`}
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, footer: e.target.value })
                }
              />

              <InputLabel
                style={{
                  marginTop: "1em",
                }}
              >
                Imagem
              </InputLabel>
              <TextField
                // defaultValue={node.header}
                // onBlur={(e) => {
                // handleUpdateNode(node.id, {
                // header: e.target.value,
                // });
                // }}
                value={mediaPath}
                disabled
                name="name"
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        marginBottom: "0.8em",
                      }}
                    >
                      {mediaPath ? (
                        <>
                          <Tooltip title={mediaPath}>
                            <ModalImageChatbot
                              imageUrl={mediaPath}
                              style={{ width: "10px" }}
                            />
                          </Tooltip>

                          <IconButton component="span">
                            <Tooltip title="Remover mídia">
                              <CancelIcon onClick={() => setMediaPath(null)} />
                            </Tooltip>
                          </IconButton>
                        </>
                      ) : (
                        <Tooltip title="Anexar imagem">
                          <Button component="label">
                            <AttachFileIcon />

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleUpdateMedia(e);
                              }}
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {category === "image" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="NAME"
                label="Nome"
                type="text"
                fullWidth
                required
                initialValue={selectedTemplate.name}
                value={newTemplate.name}
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, name: e.target.value })
                }
              />
              <InputLabel
                id="connection"
                style={{
                  marginTop: "1em",
                }}
              >
                Template modelo
              </InputLabel>
              <Select
                margin="dense"
                id="connection"
                fullWidth
                required
                value={template}
                label={"Template modelo"}
                onChange={(e) => setTemplate(e.target.value)}
              >
                {templates.map((template) => (
                  <MenuItem value={template}>{template.name}</MenuItem>
                ))}
              </Select>

              <InputLabel
                style={{
                  marginTop: "1em",
                }}
              >
                Imagem
              </InputLabel>
              <TextField
                // defaultValue={node.header}
                // onBlur={(e) => {
                // handleUpdateNode(node.id, {
                // header: e.target.value,
                // });
                // }}
                value={mediaPath}
                disabled
                name="name"
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        marginBottom: "0.8em",
                      }}
                    >
                      {mediaPath ? (
                        <>
                          <Tooltip title={mediaPath}>
                            <ModalImageChatbot
                              imageUrl={mediaPath}
                              style={{ width: "10px" }}
                            />
                          </Tooltip>

                          <IconButton component="span">
                            <Tooltip title="Remover mídia">
                              <CancelIcon onClick={() => setMediaPath(null)} />
                            </Tooltip>
                          </IconButton>
                        </>
                      ) : (
                        <Tooltip title="Anexar imagem">
                          <Button component="label">
                            <AttachFileIcon />

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleUpdateMedia(e);
                              }}
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {category === "video" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="NAME"
                label="Nome"
                type="text"
                fullWidth
                required
                initialValue={selectedTemplate.name}
                value={newTemplate.name}
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, name: e.target.value })
                }
              />
              <InputLabel
                id="connection"
                style={{
                  marginTop: "1em",
                }}
              >
                Template modelo
              </InputLabel>
              <Select
                margin="dense"
                id="connection"
                fullWidth
                required
                value={template}
                label={"Template modelo"}
                onChange={(e) => setTemplate(e.target.value)}
              >
                {templates.map((template) => (
                  <MenuItem value={template}>{template.name}</MenuItem>
                ))}
              </Select>

              <InputLabel
                style={{
                  marginTop: "1em",
                }}
              >
                Vídeo
              </InputLabel>
              <TextField
                // defaultValue={node.header}
                // onBlur={(e) => {
                // handleUpdateNode(node.id, {
                // header: e.target.value,
                // });
                // }}
                value={mediaPath}
                disabled
                name="name"
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        marginBottom: "0.8em",
                      }}
                    >
                      {mediaPath ? (
                        <>
                          <Tooltip title={mediaPath}>
                            <ModalImageChatbot
                              imageUrl={mediaPath}
                              style={{ width: "10px" }}
                            />
                          </Tooltip>

                          <IconButton component="span">
                            <Tooltip title="Remover mídia">
                              <CancelIcon onClick={() => setMediaPath(null)} />
                            </Tooltip>
                          </IconButton>
                        </>
                      ) : (
                        <Tooltip title="Anexar vídeo">
                          <Button component="label">
                            <AttachFileIcon />

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleUpdateMedia(e);
                              }}
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {error && (
            <DialogContentText color="error">
              Erro ao criar template
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {i18n.t("templates.templateModal.buttons.cancel")}
          </Button>

          {!selectedTemplate.header ? (
            <Button
              onClick={() => {
                handleAddTemplate();
              }}
              color="primary"
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                i18n.t("templates.templateModal.buttons.create")
              )}
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleUpdateTemplate();
              }}
              color="primary"
            >
              {/* <Button disabled={addTemplateBtnDisabled()} onClick={handleAddTemplate} color="primary"> */}
              {i18n.t("templates.templateModal.buttons.update")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TemplateModal;
