import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: "100%",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  maxWidth: {
    width: "100%",
  },
  buttonColorError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

export const TicketModalUserQueueCon = ({
  modalOpen,
  onClose,
  selectedContact,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user.id,
        status: "open",
        queueId: selectedQueue,
        whatsappId: selectedWhatsapp.id,
        channel:
          selectedWhatsapp.channel === "whatsappcloud"
            ? "whatsappbusiness"
            : selectedWhatsapp.channel,
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={modalOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        {/* aplicar i18n */}
        <DialogTitle id="form-dialog-title">Criar ticket</DialogTitle>

        <DialogContent dividers>
          <TextField
            as={Select}
            select
            // label={i18n.t("contactModal.form.name")}
            label={`Conexão`}
            name="channel"
            autoFocus
            defaultValue={""}
            onChange={(e) => setSelectedWhatsapp(e.target.value)}
            variant="outlined"
            margin="dense"
            className={classes.textField}
          >
            {whatsApps
              .filter(
                (whatsApp) =>
                  whatsApp.channel !== "email" &&
                  whatsApp.channel !== "facebook" &&
                  whatsApp.channel !== "instagram"
              )
              .map((whatsApp) => (
                <MenuItem key={whatsApp.id} value={whatsApp}>
                  {whatsApp.channel === "whatsapp" && (
                    <>
                      <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                      {whatsApp.name}
                    </>
                  )}
                  {whatsApp.channel === "telegram" && (
                    <>
                      <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                      {whatsApp.name}
                    </>
                  )}
                  {/* {whatsApp.channel === "instagram" && (
											<SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
											)}
											{whatsApp.channel === "facebook" && (
												<SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
											)} */}
                  {whatsApp.channel === "whatsappcloud" && (
                    <>
                      <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                      {whatsApp.name}
                    </>
                  )}
                </MenuItem>
              ))}
          </TextField>

          <InputLabel>{i18n.t("ticketsList.acceptModal.queue")}</InputLabel>
          <Select
            autoHighlight
            required
            value={selectedQueue}
            className={classes.autoComplete}
            onChange={(e) => setSelectedQueue(e.target.value)}
            label={i18n.t("ticketsList.acceptModal.queue")}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {user.queues.map((queue) => (
              <MenuItem key={queue.id} value={queue.id}>
                {queue.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact || !selectedQueue}
            onClick={() => handleSaveTicket(selectedContact.id)}
            color="primary"
            loading={loading}
          >
            {i18n.t("newTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </div>
  );
};
