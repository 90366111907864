import React from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';

export default function ModalTitle({ title, closeModalFn }) {
	return (
		<>
			<Box display="flex" justifyContent="space-between" mb={1}>
				<Typography variant="h5" component="h2" style={{fontWeight:"bold"}}>
					{title}
				</Typography>
				<Button
					style={{ marginTop: '-10px', fontSize: '1.3em' }}
					onClick={closeModalFn}
				>
					X
				</Button>
			</Box>
			<Divider />
		</>
	);
}
