import React from 'react';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';

function App() {
  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes />
      </ErrorBoundary>
    </div>
  );
}

export default App;
