import React, { useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { Box, Typography } from "@material-ui/core";
import TemplateRadioItem from "./TemplateRadioItem";

export default function TemplateRadio({
  templates,
  value,
  setValue,
  setCustomValue,
  setTemplateParamsLength,
  setTemplateParams,
  setSelectedTemplateRaw,
}) {
  const handleChange = (event) => {
    setCustomValue(null);
    setValue(event.target.value);
  };

  console.log({ templates });

  const countTemplateParams = (text) => {
    var regex = /\{{\d+}}/g;
    var matches = text?.match(regex);

    if (matches) {
      return matches.length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (value) {
      const selectedTemplate = templates.find(
        (template) => template.id === value
      );
      const templateText = templates
        .find((template) => template.id === value)
        .components?.find((component) => component.type === "BODY").text;

      setTemplateParamsLength(countTemplateParams(templateText));
      setTemplateParams(Array(countTemplateParams(templateText)).fill(""));
    }
  }, [value]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ maxHeight: "540px", overflow: "scroll" }}
    >
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="templates"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          {templates.map((template) => {
            if (!template.status) {
              console.log({ template });
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    width: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                    margin: "1em 0",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      setCustomValue(template.id);
                    }}
                  >
                    {template.name}
                  </Typography>
                </div>
              );
            }
            return (
              <TemplateRadioItem
                setSelectedTemplateRaw={setSelectedTemplateRaw}
                key={template.id}
                template={template}
                outlined={template.id === value}
                templateIsApproved={template.status === "APPROVED"}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
