import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MessageInput from "../MessageInput";
import DraftInput from "../DraftInput";

const AntTabs = withStyles({
  root: {
    borderBottom: "0px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
  padding: 0,
  margin: 0,
  border: 0,
  height: "0.5em",
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
    fontSize: "0.8em",
    padding: 0,
    margin: 0,
    border: 0,
    height: "0.5em",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

// const StyledTabs = withStyles({
//   indicator: {
//     display: "flex",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//     "& > span": {
//       maxWidth: 40,
//       width: "100%",
//       backgroundColor: "#635ee7",
//     },
//   },
// })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

// const StyledTab = withStyles((theme) => ({
//   root: {
//     textTransform: "none",
//     color: "#fff",
//     fontWeight: theme.typography.fontWeightRegular,
//     fontSize: theme.typography.pxToRem(15),
//     marginRight: theme.spacing(1),
//     "&:focus": {
//       opacity: 1,
//     },
//   },
// }))((props) => <Tab disableRipple {...props} />);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   padding: {
//     padding: theme.spacing(1),
//   },
//   demo1: {
//     backgroundColor: theme.palette.background.paper,
//   },
//   demo2: {
//     backgroundColor: "#2e1534",
//   },
//   padding: 0,
//   margin: 0,
//   border: 0,
//   height: "0.5em",
// }));

export default function CustomizedTabs(props) {
  const {
    ticketId,
    ticketStatus,
    ticketChannel,
    ticketOwnerId,
    templateBarStatus,
    ticket,
  } = props;
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Responder" />
          <AntTab label="Mensagem Privada" />
        </AntTabs>
        {/* <Typography className={classes.padding} /> */}
      </div>
      {/* <div className={classes.demo2}> */}
      <div>
        {value === 0 ? (
          <MessageInput
            ticketStatus={ticketStatus}
            ticketChannel={ticketChannel}
            ticketOwnerId={ticketOwnerId}
            templateBarStatus={templateBarStatus}
            ticket={ticket}
            whatsappId={ticket?.whatsappId}
          />
        ) : (
          <DraftInput ticketId={ticketId} />
        )}
        {/* <StyledTab label="whatsapp" />
          <StyledTab label="Datasets" /> */}
      </div>
    </div>
  );
}
