import React from 'react';
import parse from 'html-react-parser';
import * as sanitizeHtml from 'sanitize-html';
import {
	Backdrop,
	Box,
	Divider,
	Fade,
	Modal,
	Typography,
} from '@material-ui/core';
import EmailMessageModal from '../EmailMessageModal';
import ModalTitle from '../ModalTitle';
import ModalActionBtns from '../ModalActionBtns';
import EmailAttachment from './EmailAttachment';

export default function EmailReadMessageModal({
	modalOpened,
	handleModalOpened,
	message,
	messageMediaUrls,
}) {
	const boxStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: '60%',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
		maxWidth: '95%',
		maxHeight: '85%',
		padding: '2%',
		borderRadius: '10px',
		overflow: 'scroll',
	};

	const [replyModalOpened, setReplyModalOpened] = React.useState(false);

	const msgBody = JSON.parse(message.body);
	const sanitizedHtml = sanitizeHtml(msgBody.body);

	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={modalOpened}
				onClose={() => handleModalOpened(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalOpened && !replyModalOpened}>
					<Box sx={boxStyle}>
						<ModalTitle
							title="Ler Mensagem"
							closeModalFn={() => handleModalOpened(false)}
						/>
						<Typography
							id="transition-modal-title"
							align="center"
							variant="h5"
							component="h2"
							style={{ marginBottom: '10px', fontWeight: 'bold' }}
						>
							{msgBody.subject &&
								msgBody.subject.charAt(0).toUpperCase() +
									msgBody.subject.slice(1)}
						</Typography>
						<Typography
							id="transition-modal-subtitle"
							variant="caption"
							component="h2"
							align="center"
							style={{ margin: '10px 0' }}
						>
							Enviado por {msgBody.sentBy}
						</Typography>
						{messageMediaUrls && messageMediaUrls.length > 0 && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-evenly',
									height: '100%',
									mb: 1,
									flexWrap: 'wrap',
								}}
							>
								{messageMediaUrls.map((mediaUrl) => (
									<EmailAttachment
										key={mediaUrl}
										messageAttachment={mediaUrl}
									/>
								))}
							</Box>
						)}
						<Typography
							id="transition-modal-subtitle"
							variant="caption"
							component="h2"
							align="center"
						>
							{msgBody.sentTo.length > 1
								? 'Message enviada para' +
								  msgBody.sentTo.map((to, index) =>
										index === msgBody.sentTo.length - 1 ? to : to + ', '
								  )
								: ''}
						</Typography>
						<Divider />
						<Box
							align="center"
							flexDirection={'column'}
							alignItems={'center'}
							maxHeight={'300px'}
							overflow="scroll"
							mb={3}
						>
							<Typography
								variant="body2"
								color="textPrimary"
								style={{
									maxHeight: '50%',
									overflowY: 'auto',
									overflowX: 'hidden',
									whiteSpace: 'pre-wrap',
									wordWrap: 'break-word',
									textAlign: 'justify',
									padding: '10px',
									borderRadius: '10px',
									backgroundColor: '#f5f5f5',
									border: '1px solid #e0e0e0',
								}}
							>
								{parse(sanitizedHtml)}
							</Typography>
						</Box>
						<ModalActionBtns
							cancelBtnText="Cancelar"
							cancelBtnFn={() => handleModalOpened(false)}
							confirmBtnText="Responder"
							confirmBtnFn={() => setReplyModalOpened(true)}
						/>
					</Box>
				</Fade>
			</Modal>
			<EmailMessageModal
				modalOpened={replyModalOpened}
				handleModalOpened={setReplyModalOpened}
				ticketId={message.ticketId}
				message={message}
			/>
		</>
	);
}
