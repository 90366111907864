import React, { useContext, useEffect, useState } from "react";

import { IconButton, Paper, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import ContactDrawer from "../ContactDrawer";
import CustomizedTabs from "../CustomizedTabs";
import MessagesList from "../MessagesList";
import SearchDrawer from '../SearchDrawer';
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";

const drawerWidth = 640;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    // backgroundColor: `${!darkmode ? "202c33" : "#f0f2f5"}`,
    // backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "49px",
    // maxWidth: "50%",
    // flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      width: "60vw",
    },
  },
  ticketActionButtons: {
    // backgroundColor: `${!darkmode ? "202c33" : "#f0f2f5"}`,
    // backgroundColor: "red",
    maxWidth: "50%",
    // flexBasis: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      // maxWidth: "40vw",
    },
    zIndex: 10,
    marginRight: "1em",
  },

  searchMessages: {
    marginLeft: "auto",

    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      marginRight: "-20px",
      width: "3vw",
      margin: 0,
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

function Ticket() {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [templateBarStatus, setTemplateBarStatus] = useState(false);
  const [aroundMessages, setAroundMessages] = useState([]);
  const [searchedMessage, setSearchedMessage] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const darkMode = localStorage.getItem("darkMode") === "true" ? true : false;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  useEffect(() => {
    setSearchedMessage(null);
    setSearchLoading(false);
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get(`/tickets/${ticketId}`);

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = openSocket({
      scope: "ticket",
      userId: user.id,
      component: "Ticket",
    });

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(
          classes.mainWrapper
          // { [classes.mainWrapperShift]: drawerOpen }
        )}
      >
        <TicketHeader loading={loading}>
          <div
            className={classes.ticketInfo}
            style={{
              backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
            }}
          >
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={() => {
                handleDrawerOpen();
                handleSearchClose();
              }}
            />
          </div>
          <div
            className={classes.ticketActionButtons}
            style={{
              backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
            }}
          >
            <IconButton className={classes.searchMessages}>
              <Search
                contact={contact}
                ticket={ticket}
                onClick={() => {
                  handleSearchOpen();
                  handleDrawerClose();
                }}
              />
            </IconButton>
            <TicketActionButtons ticket={ticket} contact={contact} />
          </div>
        </TicketHeader>

        <ReplyMessageProvider>
          <MessagesList
            contact={contact}
            ticketId={ticketId}
            ticketChannel={ticket.channel}
            isGroup={ticket.isGroup}
            templateBarStatus={templateBarStatus}
            setTemplateBarStatus={setTemplateBarStatus}
            aroundMessages={aroundMessages}
            setAroundMessages={setAroundMessages}
            whatsappId={ticket.whatsappId}
            searchedMessage={searchedMessage}
            setSearchedMessage={setSearchedMessage}
            searchLoading={searchLoading}
            setSearchLoading={setSearchLoading}
          />
          <CustomizedTabs
            ticket={ticket}
            ticketId={ticket.id}
            ticketStatus={ticket.status}
            ticketChannel={ticket.channel}
            ticketOwnerId={ticket.userId}
            templateBarStatus={templateBarStatus}
          />
        </ReplyMessageProvider>
      </Paper>

      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />

      <SearchDrawer
        open={searchOpen}
        handleDrawerClose={handleSearchClose}
        contact={contact}
        ticket={ticket}
        setAroundMessages={setAroundMessages}
        setSearchedMessage={setSearchedMessage}
        searchedMessage={searchedMessage}
        searchLoading={searchLoading}
        setSearchLoading={setSearchLoading}
      />
    </div>
  );
}

export default Ticket;
