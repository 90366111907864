import api from "../../services/api";
import React, { useState, useEffect } from "react";

import { Box, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import toastError from "../../errors/toastError";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { MultiSelect } from "./MultiSelect";
//import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "./PdfPrint";
import { ExcelPrint } from "./ExcelPrint";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },

  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  topContent: {
    gap: theme.spacing(2),
  },

  filtersContent: {
    gap: theme.spacing(2),
  },

  selectInput: {
    width: "19%",
  },

  actionsContent: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
  },
}));

const groupByOptions = [
  {
    name: "Atendente",
    value: "userId",
  },
  {
    name: "Setor",
    value: "queueId",
  },
  {
    name: "Conexão",
    value: "whatsappId",
  },
  // {
  //   name: "Etiqueta",
  //   value: "tagId",
  // },
];

function Report() {
  const classes = useStyles();

  const [filters, setFilters] = useState({
    searchParam: "",
    period: "3",
    userIds: [],
    queueIds: [],
    connectionIds: [],
    tagIds: [],
    groupBy: "",
  });

  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [connections, setConnections] = useState([]);
  const [groupBy, setGroupBy] = useState("userId");
  const [isOpen, setIsOpen] = React.useState(false);
  const [pdfData, setPdfData] = useState({ name: "" });

  const handleClosePDF = () => {
    setIsOpen(false);
  };

  const handleCreatePDF = () => {
    setIsOpen(true);
  };

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });

    handleClosePDF();
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { users },
        } = await api.get("/users/");
        setUsers(users);
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const { data } = await api.get("/queue/");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const { data } = await api.get("/whatsapp/");
        setConnections(data);
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const {
          data: { tags },
        } = await api.get("/tags/");
        setTags(tags);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const getPdfReport = async () => {
    const { data } = await api.get("/report/", {
      params: {
        ...filters,
        user: JSON.stringify(filters.userIds),
        queue: JSON.stringify(filters.queueIds),
        connection: JSON.stringify(filters.connectionIds),
        tag: JSON.stringify(filters.tagIds),
        groupBy,
      },
    });

    setPdfData(data);
    handleCreatePDF();
  };

  const getExcelReport = async () => {
    const { data } = await api.get("/report/", {
      params: {
        ...filters,
        user: JSON.stringify(filters.userIds),
        queue: JSON.stringify(filters.queueIds),
        connection: JSON.stringify(filters.connectionIds),
        tag: JSON.stringify(filters.tagIds),
        groupBy,
      },
    });

    ExcelPrint(data.tickets);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Box>
      <MainHeader>
        <Title>Relatórios</Title>
      </MainHeader>
      <Box className={classes.mainContainer}>
        <Box display="flex" marginBottom={3} className={classes.topContent}>
          <TextField
            className={classes.selectInput}
            size="small"
            variant="outlined"
            label="Pesquisar"
            onChange={handleFilterChange}
            name="searchParam"
            value={filters.searchParam}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Período"
            onChange={handleFilterChange}
            name="period"
            value={filters.period}
          >
            <MenuItem value="0">Todos</MenuItem>
            <MenuItem value="1">Hoje</MenuItem>
            <MenuItem value="2">Ontem</MenuItem>
            <MenuItem value="3">Últimos 7 dias</MenuItem>
            <MenuItem value="4">Últimos 30 dias</MenuItem>
            <MenuItem value="5">Este mês</MenuItem>
            <MenuItem value="6">Últimos 6 meses</MenuItem>
            <MenuItem value="7">Este ano</MenuItem>
          </TextField>
        </Box>

        <Box display="flex" marginBottom={3} className={classes.filtersContent}>
          <MultiSelect
            label="Atendentes"
            options={users}
            filters={filters}
            onChange={handleChange}
            name="userIds"
          />

          <MultiSelect
            label="Setores"
            options={queues}
            filters={filters}
            onChange={handleChange}
            name="queueIds"
          />

          <MultiSelect
            label="Conexões"
            options={connections}
            filters={filters}
            onChange={handleChange}
            name="connectionIds"
          />

          <MultiSelect
            label="Etiquetas"
            options={tags}
            filters={filters}
            onChange={handleChange}
            name="tagIds"
          />

          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Agrupar por"
            onChange={(e) => {
              handleClosePDF();
              setGroupBy(e.target.value);
            }}
            value={groupBy}
            name="groupBy"
          >
            {groupByOptions?.map((option) => (
              <MenuItem value={option.value}>{option.name}</MenuItem>
            ))}
          </TextField>
        </Box>

        <Box display="flex" marginBottom={3} className={classes.actionsContent}>
          <Button variant="contained" color="primary" onClick={getPdfReport}>
            Gerar relatório em PDF
          </Button>

          <Button variant="contained" color="primary" onClick={getExcelReport}>
            Gerar relatório em Excel
          </Button>
        </Box>
        {isOpen && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >{/*
            <PDFDownloadLink document={MyDocument} fileName="mypdf.pdf">
              {({ blob, url, loading, error }) =>
                loading ? "Carregando documento..." : ""
              }
            </PDFDownloadLink>
            
            //<PDFViewer width="100%" height="800">
            //  {<MyDocument pdfData={pdfData} groupBy={groupBy} />}
            //</PDFViewer>*/}
          </Box>
          
        )}
      </Box>
    </Box>
  );
}

export default Report;
