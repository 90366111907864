import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import React from 'react';

export function VariablesModal({
  open,
  handleClose,
  variables,
  bulkMessage,
  setBulkMessage,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Variáveis de mensagem</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              display: 'flex',
              gap: '0.3em',
              flexWrap: 'wrap',
            }}
          >
            {variables.map((variable) => (
              <Chip
                style={{
                  cursor: 'pointer',
                }}
                key={variable}
                label={variable}
                onClick={(e) => {
                  setBulkMessage({
                    ...bulkMessage,
                    bulkMessagingData: {
                      ...bulkMessage.bulkMessagingData,
                      message:
                        bulkMessage.bulkMessagingData.message.concat(variable),
                    },
                  });
                  handleClose();
                }}
              />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
