import React, { useContext, useEffect, useRef, useState } from "react";

import { Avatar, MenuItem, Select, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
});

const initialState = {
  name: "",
  number: "",
  email: "",
  company: "",
  channel: "whatsapp",
};

function ContactModal({ open, onClose, contactId, initialValues, onSave }) {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);

  const [contact, setContact] = useState(initialState);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async (values) => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => ({ ...prevState, ...initialValues }));
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {/* {i18n.t("contactModal.form.mainInfo")} */}
                  Conexão
                </Typography>
                <Field
                  style={{ width: "100%" }}
                  as={Select}
                  select
                  label={i18n.t("contactModal.form.name")}
                  name="channel"
                  autoFocus
                  defaultValue="whatsapp"
                  // SelectProps={{
                  //   renderValue: (value) => (
                  //     <>
                  //       {value === "whatsapp" && (
                  //         <div style={{ display: "flex" }}>
                  //           <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                  //           {/* {value} */}
                  //         </div>
                  //       )}
                  //       {value === "telegram" && (
                  //         <>
                  //           <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                  //           {/* {value} */}
                  //         </>
                  //       )}
                  //       {/* {value === "instagram" && (
                  //         <SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
                  //       )}
                  //       {value === "facebook" && (
                  //         <SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
                  //       )} */}
                  //       {value === "whatsappcloud" && (
                  //         <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                  //         // {value}
                  //       )}
                  //     </>
                  //   ),
                  // }}
                  // error={touched.name && Boolean(errors.name)}
                  // helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                >
                  {whatsApps
                    .filter((wpp) =>
                      user.profile !== "admin"
                        ? wpp?.id === user?.whatsapp?.id
                        : wpp
                    )
                    .filter(
                      (whatsApp) =>
                        // whatsApp.channel !== 'email'
                        whatsApp.channel !== "facebook" &&
                        whatsApp.channel !== "instagram"
                    )
                    .map((whatsApp) => (
                      <MenuItem
                        key={whatsApp.id}
                        value={
                          whatsApp.channel === "whatsappcloud"
                            ? "whatsappbusiness"
                            : whatsApp.channel
                        }
                      >
                        {whatsApp.channel === "whatsapp" && (
                          <>
                            <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                            {whatsApp.name}
                          </>
                        )}
                        {whatsApp.channel === "telegram" && (
                          <>
                            <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                            {whatsApp.name}
                          </>
                        )}
                        {whatsApp.channel === "whatsappcloud" && (
                          <>
                            <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                            {whatsApp.name}
                          </>
                        )}
                        {whatsApp.channel === "email" && (
                          <>
                            {/* <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" /> */}
                            {`email: ${whatsApp.name}`}
                          </>
                        )}
                        {/* {whatsApp.channel === "instagram" && (
                          <SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
                          )}
                        {whatsApp.channel === "facebook" && (
                          <SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
                        )} */}

                        {/* {whatsApp.name} */}
                      </MenuItem>
                    ))}
                </Field>
                <Typography
                  style={{ marginBottom: 8, marginTop: 12 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="5513912344321"
                  variant="outlined"
                  margin="dense"
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.email")}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  placeholder="Email address"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.company")}
                  name="company"
                  error={touched.company && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
                <Typography
                  style={{ marginBottom: 8, marginTop: 12 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.extraInfo")}
                </Typography>

                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div
                            className={classes.extraAttr}
                            key={`${index}-info`}
                          >
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraName")}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraValue")}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1, marginTop: 8 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default ContactModal;
