import React, { useContext, useEffect, useReducer, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import openSocket from "../../services/socket-io";

import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import PresentationMessagesList from "./PresentationMessagesList";
import PresentationClock from "./PresentationClock";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    top: 0,
    left: 0,
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundImage: "url(/presentation-bg.jpg)",
    // backgroundImage: "url(/vilavelha.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    zIndex: 1201,
    justifyContent: "center",
    alignItems: "center",
  },

  glassmorphism: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    background: "rgba(255, 255, 255, 0.01)",
    backdropFilter: "blur(2px)",
    borderRadius: "10px",
    zIndex: 1202,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  slideDots: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "absolute",
    bottom: "45%",
    right: "20%",
    zIndex: 1300,
  },

  slideDot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    margin: "0 5px",
    cursor: "pointer",
  },

  slideDotSelected: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    margin: "0 5px",
    cursor: "pointer",
  },

  messageCard: {
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "10px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
  },

  messageCard2: {
    position: "absolute",
    bottom: "-60px",
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "10px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
    transform: "scale(0.7)",
  },

  messageCard0: {
    position: "absolute",
    top: "-60px",
    display: "flex",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: "white",
    padding: "10px 10px",
    borderRadius: "10px 10px 10px 10px",
    width: "640px",
    boxShadow: "0 3px 5px 5px rgba(31, 38, 135, 0.37)",
    gap: "20px",
    transform: "scale(0.7)",
  },

  avatar: {
    marginLeft: "-30px",
    boxShadow: "0 0px 5px 5px rgba(31, 38, 135, 0.37)",
  },

  cardText: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  name: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },

  message: {},
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    }
    return [queue, ...state];
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function Presentation() {
  const classes = useStyles();

  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [messagesListLength, setMessagesListLength] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket({
      scope: "presentation",
      userId: user.id,
      component: "Presentation",
    });

    socket.on("queue", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // change active index on press up or down arrow
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp") {
        if (activeIndex > 0) {
          setActiveIndex(activeIndex - 1);
        }
        if (activeIndex === 0) {
          setActiveIndex(messagesListLength - 1);
        }
      }

      if (e.key === "ArrowDown") {
        if (activeIndex < messagesListLength - 1) {
          setActiveIndex(activeIndex + 1);
        }
        if (activeIndex === messagesListLength - 1) {
          setActiveIndex(0);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeIndex, messagesListLength]);

  const example = {
    contact: {
      id: 50,
      name: "dev",
      number: "5527998851973",
      email: "",
      profilePicUrl:
        "https://pps.whatsapp.net/v/t61.24694-24/257156993_300824145336227_8022561056555532783_n.jpg?ccb=11-4&oh=01_AdR3X8nuRjJtGO8aWuF-WfMDg9PjvbZpAukENrvyYpPjvg&oe=644E99FA",
      extraInfo: [],
      tags: [],
    },
    ticketId: "0",
    ticketChannel: "whatsapp",
  };

  return (
    <MainContainer>
      <Box className={classes.mainPaper}>
        <Box className={classes.glassmorphism}>
          <Box className={classes.slideDots}>
            {[...Array(messagesListLength).keys()].map((index) => (
              <Box
                className={
                  activeIndex === index
                    ? classes.slideDotSelected
                    : classes.slideDot
                }
                onClick={() => {
                  setActiveIndex(index);
                }}
              />
            ))}
          </Box>

          <PresentationClock />

          <PresentationMessagesList
            contact={example.contact}
            ticketId={example.ticketId}
            ticketChannel={example.ticketChannel}
            isGroup={false}
            templateBarStatus
            activeIndex={activeIndex}
            setMessagesListLength={setMessagesListLength}
          />

          {/* <Box className={classes.messageCard0}>
            <Avatar className={classes.avatar} src="/profilepic.png" />
            <Box className={classes.cardText}>
              <Typography className={classes.name}>Sirius Black</Typography>
              <Typography className={classes.message}>
                Aqui em Hogwarts está um dia ensolarado.
              </Typography>
            </Box>
          </Box>

          <Box className={classes.messageCard}>
            <Avatar className={classes.avatar} src="/profilepic.png" />
            <Box className={classes.cardText}>
              <Typography className={classes.name}>Matheus Tostes</Typography>
              <Typography className={classes.message}>
                Aqui em Vila Velha está chovendo demais! Já alagou tudo.
              </Typography>
            </Box>
          </Box>

          <Box className={classes.messageCard2}>
            <Avatar className={classes.avatar} src="/profilepic2.png" />
            <Box className={classes.cardText}>
              <Typography className={classes.name}>Nathalia Amaral</Typography>
              <Typography className={classes.message}>
                Por aqui tambem! Estou com medo de sair de casa.
              </Typography>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </MainContainer>
  );
}

export default Presentation;
