import React from 'react';

import { Box, Button, Divider, DialogActions } from '@material-ui/core';

export default function ModalActionBtns({
	cancelBtnText,
	confirmBtnText,
	cancelBtnFn,
	confirmBtnFn,
}) {
	return (
		<>
			<Divider />
			<Box display={'flex'} justifyContent={'flex-end'} pt={3}>
				<DialogActions>
					<Button onClick={cancelBtnFn} color="secondary" variant="outlined">
						{cancelBtnText}
					</Button>
					<Button
						type="submit"
						color="primary"
						variant="contained"
						onClick={confirmBtnFn}
					>
						{confirmBtnText}
					</Button>
				</DialogActions>
			</Box>
		</>
	);
}
