import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import {
  Button,
  Box,
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SchedulesOutlinedIcon from "@material-ui/icons/Event";
import BarChartIcon from "@material-ui/icons/BarChart";
import PhotoFilterIcon from "@material-ui/icons/PhotoFilter";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PersonIcon from "@material-ui/icons/Person";

import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import DescriptionIcon from "@material-ui/icons/Description";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ThemeContext } from "../../context/Theme/ThemeContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import EmailMessageModal from "../EmailMessageModal";
import WabaSendTemplateModal from "./WabaSendTemplateModal";
import ScheduleModal from "../ScheduleModal";
import CustomWebcam from "./CustomWebcam";
import { SendContactModal } from "./SendContactModal";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: theme.palette.background.default,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  hiddenInput: {
    display: "none",
  },

  messageInputWrapper: {
    // padding: 6,
    padding: "4px 12px",
    marginRight: 7,
    background: theme.palette.background.paper,
    display: "flex",
    borderRadius: 8,
    flex: 1,
    position: "relative",
    boxShadow: "0 1px 6px rgba(0, 0, 0, 0.05)",
  },

  messageInput: {
    // paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
    margin: 0,
    padding: 0,
  },

  uploadInput: {
    display: "none",
    cursor: "pointer",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  viewMediaInputWrapperDarkMode: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#202c33",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  inputMenu: {
    display: "flex",
    flexDirection: "column",
  },

  // addIconMenuOpen: {
  //   transform: "rotate(45deg)",
  // },

  // animate add icon on open menu with rotate and back on close

  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(135deg)",
    },
  },

  "@keyframes unrotate": {
    "0%": {
      transform: "rotate(135deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },

  addIconMenuOpen: {
    animation: `$rotate 0.3s ease-in-out`,
    transform: "rotate(135deg)",
    fontWeight: "900",
  },

  addIconMenuClosed: {
    animation: `$unrotate 0.3s ease-in-out`,
    fontWeight: "900",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  emojiBtn: {
    padding: 0,
    marginRight: 7,
    color: "#A5B0B8",
  },

  attachBtn: {
    padding: 0,
  },

  menuItem: {
    cursor: "pointer",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
    paddingBottom: 10,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginMsgWrapperDarkMode: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
    paddingBottom: 10,
    backgroundColor: "#3d3d3d",
  },

  replyginMsgContainerDarkMode: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    backgroundColor: "#2e2e2e",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  messageQuickAnswersWrapper: {
    maxHeight: "50vh",
    overflowY: "auto",
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
}));

function MessageInput({
  ticketStatus,
  ticketChannel,
  ticketOwnerId,
  templateBarStatus,
  ticket,
  whatsappId,
}) {
  const classes = useStyles();
  const { ticketId } = useParams();

  const [sendContactsModalOpen, setSendContactsModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const { darkMode } = useContext(ThemeContext);
  const [isCamOn, setIsCamOn] = useState(false);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    const emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
    setIsCamOn(false);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: value.substring(1) },
        });

        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const handleCameraClick = (event) => {
    setAnchorEl(null);
    setIsCamOn(true);
  };

  const renderReplyingMessage = (message) => (
    <div
      className={
        darkMode
          ? classes.replyginMsgWrapperDarkMode
          : classes.replyginMsgWrapper
      }
    >
      <div
        className={
          darkMode
            ? classes.replyginMsgContainerDarkMode
            : classes.replyginMsgContainer
        }
      >
        <span
          className={clsx(classes.replyginContactMsgSideColor, {
            [classes.replyginSelfMsgSideColor]: !message.fromMe,
          })}
        />
        <div className={classes.replyginMsgBody}>
          {!message.fromMe && (
            <span className={classes.messageContactName}>
              {message.contact?.name}
            </span>
          )}
          {message.body}
        </div>
      </div>
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open" || templateBarStatus}
        onClick={() => setReplyingMessage(null)}
      >
        <ClearIcon className={classes.sendMessageIcons} />
      </IconButton>
    </div>
  );

  if (medias.length > 0) {
    return (
      <Paper
        elevation={0}
        square
        className={
          darkMode
            ? classes.viewMediaInputWrapperDarkMode
            : classes.viewMediaInputWrapper
        }
      >
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading || templateBarStatus}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  }

  return (
    <Paper square elevation={0} className={classes.mainWrapper}>
      {replyingMessage && renderReplyingMessage(replyingMessage)}

      <SendContactModal
        modalOpen={sendContactsModalOpen}
        onClose={() => setSendContactsModalOpen(false)}
        ticketId={ticketId}
        whatsappId={whatsappId}
      />

      <ScheduleModal
        open={scheduleModalOpen}
        onClose={() => setScheduleModalOpen(false)}
        aria-labelledby="form-dialog-title"
        scheduleId={null}
        contact={ticket?.contact}
      />

      {isCamOn && <CustomWebcam setMedias={setMedias} cancel={isCamOn} />}

      <div
        className={classes.newMessageBox}
        style={{
          backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
        }}
      >
        {ticketChannel === "email" && (
          <Box
            component="div"
            width="100%"
            display="flex"
            alignContent="center"
            py={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEmailModalOpen(true)}
              disabled={
                user.id !== ticketOwnerId ||
                ticketStatus === "closed" ||
                templateBarStatus
              }
            >
              {ticketStatus === "closed"
                ? "Reabrea o ticket para encaminhar novos emails"
                : "Enviar email"}
            </Button>
            <EmailMessageModal
              modalOpened={emailModalOpen}
              handleModalOpened={setEmailModalOpen}
              ticketId={ticketId}
            />
          </Box>
        )}

        {ticketChannel !== "email" && (
          <Hidden only={["sm", "xs"]}>
            {ticketChannel === "whatsappbusiness" && (
              <>
                <Button
                  disabled={loading || recording || ticketStatus !== "open"}
                  onClick={() => setTemplateModalOpen(true)}
                >
                  <WhatsAppIcon
                    color={
                      loading || recording || ticketStatus !== "open"
                        ? "disabled"
                        : "primary"
                    }
                  />
                </Button>
                <WabaSendTemplateModal
                  open={templateModalOpen}
                  closeModalFn={() => setTemplateModalOpen(false)}
                  ticketId={ticketId}
                />{" "}
              </>
            )}

            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <AddIcon
                className={
                  Boolean(anchorEl)
                    ? classes.addIconMenuOpen
                    : classes.addIconMenuClosed
                }
              />
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
              className={classes.inputMenu}
              anchorOrigin={{
                vertical: "top",
              }}
              transformOrigin={{
                vertical: "bottom",
              }}
              style={{
                marginBottom: "-50px",
              }}
            >
              {/* <MenuItem  onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  id="upload-button"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={
                      loading ||
                      recording ||
                      ticketStatus !== "open" ||
                      templateBarStatus
                    }
                  >
                    <AttachFileIcon className={classes.sendMessageIcons} />
                  </IconButton>
                  Mídias
                </label>
              </MenuItem> */}

              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                  id="upload-button-document"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button-document">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={
                      loading ||
                      recording ||
                      ticketStatus !== "open" ||
                      templateBarStatus
                    }
                    className={classes.attachBtn}
                  >
                    <DescriptionIcon
                      style={{
                        color: "#7F66FF",
                        marginRight: "0.5rem",
                      }}
                    />
                  </IconButton>
                  Documento
                </label>
              </MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  accept="image/*,video/*"
                  id="upload-button-media"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button-media">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={
                      loading ||
                      recording ||
                      ticketStatus !== "open" ||
                      templateBarStatus
                    }
                    className={classes.attachBtn}
                  >
                    <PhotoLibraryIcon
                      style={{
                        color: "#017BFC",
                        marginRight: "0.5rem",
                      }}
                    />
                  </IconButton>
                  Fotos e vídeos
                </label>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleMenuItemClick();
                  handleCameraClick();
                }}
              >
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.attachBtn}
                >
                  <PhotoCameraIcon
                    style={{
                      color: "#FF3074",
                      marginRight: "0.5rem",
                    }}
                  />
                </IconButton>
                Câmera
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleMenuItemClick();
                  setSendContactsModalOpen(true);
                }}
              >
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.attachBtn}
                >
                  <PersonIcon
                    style={{
                      color: "#019DE1",
                      marginRight: "0.5rem",
                    }}
                  />
                </IconButton>
                Contato
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleMenuItemClick();
                  setScheduleModalOpen(true);
                }}
              >
                <IconButton
                  aria-label="schedule"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.attachBtn}
                >
                  <SchedulesOutlinedIcon
                    style={{
                      color: "#ff5d00",
                      marginRight: "0.5rem",
                    }}
                  />
                </IconButton>
                Agendar
              </MenuItem>

              <MenuItem disabled={true}>
                <IconButton
                  aria-label="schedule"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.attachBtn}
                >
                  <BarChartIcon
                    style={{
                      color: "#FFBC37",
                      transform: "rotate(90deg)",
                      marginRight: "0.5rem",
                    }}
                  />
                </IconButton>
                Enquete
                <Typography
                  variant="caption"
                  style={{
                    opacity: "0.5",
                    textAlign: "bottom",
                    marginLeft: "0.5rem",
                  }}
                >
                  {`(em desenvolvimento)`}
                </Typography>
              </MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  accept="image/*"
                  id="upload-button-sticker"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button-sticker">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={
                      loading ||
                      recording ||
                      ticketStatus !== "open" ||
                      templateBarStatus
                    }
                    className={classes.attachBtn}
                  >
                    <PhotoFilterIcon
                      style={{
                        color: "#02A698",
                        marginRight: "0.5rem",
                      }}
                    />
                  </IconButton>
                  Nova Figurinha
                </label>
              </MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <FormControlLabel
                  style={{ marginRight: 7, color: "gray" }}
                  label={i18n.t("messagesInput.signMessage")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={signMessage}
                      onChange={(e) => {
                        setSignMessage(e.target.checked);
                      }}
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              </MenuItem>
            </Menu>
          </Hidden>
        )}

        {ticketChannel !== "email" && (
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
            >
              <MenuItem onClick={handleMenuItemClick}>
                <IconButton
                  aria-label="emojiPicker"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                  className={classes.emojiBtn}
                >
                  <MoodIcon
                    style={{
                      color: `${darkMode ? "#fff" : "#A5B0B8"}`,
                    }}
                  />
                </IconButton>
              </MenuItem>
              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  id="upload-button"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={
                      loading ||
                      recording ||
                      ticketStatus !== "open" ||
                      templateBarStatus
                    }
                  >
                    <AttachFileIcon className={classes.sendMessageIcons} />
                  </IconButton>
                </label>
                <IconButton
                  aria-label="schedule"
                  component="span"
                  disabled={
                    loading ||
                    recording ||
                    ticketStatus !== "open" ||
                    templateBarStatus
                  }
                  onClick={() => setScheduleModalOpen(true)}
                >
                  <SchedulesOutlinedIcon className={classes.sendMessageIcons} />
                </IconButton>
              </MenuItem>
              <MenuItem onClick={handleMenuItemClick}>
                <FormControlLabel
                  style={{ marginRight: 7, color: "gray" }}
                  label={i18n.t("messagesInput.signMessage")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={signMessage}
                      onChange={(e) => {
                        setSignMessage(e.target.checked);
                      }}
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              </MenuItem>
              {ticketChannel === "whatsappbusiness" && (
                <MenuItem>
                  <Button
                    disabled={loading || recording || ticketStatus !== "open"}
                    onClick={() => setTemplateModalOpen(true)}
                  >
                    <WhatsAppIcon
                      color={
                        loading || recording || ticketStatus !== "open"
                          ? "disabled"
                          : "primary"
                      }
                    />
                  </Button>
                  <WabaSendTemplateModal
                    open={templateModalOpen}
                    closeModalFn={() => setTemplateModalOpen(false)}
                    ticketId={ticketId}
                  />{" "}
                </MenuItem>
              )}
            </Menu>
          </Hidden>
        )}

        <div className={classes.messageInputWrapper}>
          {showEmoji && (
            <div className={classes.emojiBox}>
              <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                <Picker
                  perLine={16}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleAddEmoji}
                />
              </ClickAwayListener>
            </div>
          )}
          <InputBase
            inputRef={(input) => {
              input && input.focus();
              input && (inputRef.current = input);
            }}
            className={[
              classes.messageInput,
              ticketChannel === "email" ? classes.hiddenInput : "",
            ].join(" ")}
            placeholder={
              ticketStatus === "open"
                ? i18n.t("messagesInput.placeholderOpen")
                : i18n.t("messagesInput.placeholderClosed")
            }
            multiline
            maxRows={5}
            value={inputMessage}
            onChange={handleChangeInput}
            disabled={
              recording ||
              loading ||
              ticketStatus !== "open" ||
              templateBarStatus
            }
            onPaste={(e) => {
              ticketStatus === "open" && handleInputPaste(e);
            }}
            onKeyPress={(e) => {
              if (loading || e.shiftKey) return;
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            // add icon emoji picker inside input, on start
            startAdornment={
              <IconButton
                aria-label="emojiPicker"
                component="span"
                disabled={
                  loading ||
                  recording ||
                  ticketStatus !== "open" ||
                  templateBarStatus
                }
                onClick={(e) => setShowEmoji((prevState) => !prevState)}
                className={classes.emojiBtn}
              >
                <MoodIcon
                  style={{
                    color: `${darkMode ? "#fff" : "#A5B0B8"}`,
                  }}
                />
              </IconButton>
            }
          />
          {typeBar ? (
            <ul className={classes.messageQuickAnswersWrapper}>
              {quickAnswers.map((value, index) => (
                <li
                  className={classes.messageQuickAnswersWrapperItem}
                  key={index}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => handleQuickAnswersClick(value.message)}
                    style={{ color: "black" }}
                  >
                    {`${value.shortcut} - ${value.message}`}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div />
          )}
        </div>
        {inputMessage ? (
          <IconButton
            aria-label="sendMessage"
            component="span"
            onClick={handleSendMessage}
            disabled={loading || templateBarStatus}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        ) : recording ? (
          <div className={classes.recorderWrapper}>
            <IconButton
              aria-label="cancelRecording"
              component="span"
              fontSize="large"
              disabled={loading || templateBarStatus}
              onClick={handleCancelAudio}
            >
              <HighlightOffIcon className={classes.cancelAudioIcon} />
            </IconButton>
            {loading ? (
              <div>
                <CircularProgress className={classes.audioLoading} />
              </div>
            ) : (
              <RecordingTimer />
            )}

            <IconButton
              aria-label="sendRecordedAudio"
              component="span"
              onClick={handleUploadAudio}
              disabled={loading || templateBarStatus}
            >
              <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
            </IconButton>
          </div>
        ) : (
          ticketChannel !== "email" && (
            <IconButton
              aria-label="showRecorder"
              component="span"
              disabled={loading || ticketStatus !== "open" || templateBarStatus}
              onClick={handleStartRecording}
            >
              <MicIcon className={classes.sendMessageIcons} />
            </IconButton>
          )
        )}
      </div>
    </Paper>
  );
}

export default MessageInput;
