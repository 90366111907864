import React from "react";

function Emoji2() {
  return (
    <img
      src="/rating-2.png"
      alt="emoji2"
      style={{
        width: "30px",
      }}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="24"
    //   height="24"
    // >
    //   <defs>
    //     <style>{`.b { fill: #d36900; } .c { fill: #ff8300; }`}</style>
    //   </defs>
    //   <rect
    //     x="1"
    //     y="1"
    //     width="22"
    //     height="22"
    //     rx="7.656"
    //     style={{ fill: "#ff8300" }}
    //   />
    //   <path
    //     className="b"
    //     d="M8.907 9.844a.182.182 0 0 1-.331.1 2.016 2.016 0 0 0-.569-.567 1.731 1.731 0 0 0-1.915 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021zM18.81 9.844a.182.182 0 0 1-.331.1 2.026 2.026 0 0 0-.568-.567 1.732 1.732 0 0 0-1.916 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021z"
    //   />
    //   <path
    //     className="c"
    //     d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z"
    //   />
    //   <path
    //     className="b"
    //     d="M7.3 15.353s3.75-2.125 6.625-2.312 3.906.219 2.656.5-2.906-.156-5.484.734-4.528 1.61-3.797 1.078z"
    //   />
    //   <path
    //     className="c"
    //     d="M13.585 14.614c-1.384-.09-3.474 1.357-3.474 1.357-.427.379.6-.052 1.954-.572s2.066-.015 2.7-.141.205-.558-1.18-.644z"
    //   />
    // </svg>
  );
}

export default Emoji2;
