import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import EmailMessageModal from "../EmailMessageModal";
import PermissionModal from "../PermissionModal";
import { AuthContext } from "../../context/Auth/AuthContext";

function MessageOptionsMenu({
  message,
  menuOpen,
  handleClose,
  anchorEl,
  selectedMessages,
  setSelectedMessages,
}) {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    if (message.mediaType !== "email") {
      setReplyingMessage(message);
      handleClose();
    } else {
      user.profile === "admin" || user.canDeleteMessages
        ? setConfirmationOpen(true)
        : setPermissionOpen(true);
    }
  };

  const handleOpenConfirmationModal = (e) => {
    user.profile === "admin" || user.canDeleteMessages
      ? setConfirmationOpen(true)
      : setPermissionOpen(true);
    handleClose();
  };

  const handleReplyEmail = () => {
    setEmailOpen(true);
  };

  const handleSelectedMessages = () => {
    if (!selectedMessages.includes(message.id)) {
      setSelectedMessages([...selectedMessages, message.id]);
    }
  };

  return (
    <>
      <PermissionModal open={permissionOpen} onClose={setPermissionOpen} />
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <EmailMessageModal
        modalOpened={emailOpen}
        handleModalOpened={() => setEmailOpen(false)}
        ticketId={message.ticketId}
        message={message}
      />
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {!message.fromMe && message.mediaType !== "email" && (
          <div>
            <MenuItem onClick={hanldeReplyMessage}>
              {i18n.t("messageOptionsMenu.reply")}
            </MenuItem>
            {/* <MenuItem onClick={presentationMessage}>Apresentar</MenuItem> */}
          </div>
        )}
        {message.mediaType === "email" && (
          <MenuItem onClick={handleReplyEmail}>
            {i18n.t("messageOptionsMenu.reply")}
          </MenuItem>
        )}

        {(message.mediaType === "chat" ||
          message.mediaType === "image" ||
          message.mediaType === "audio" ||
          message.mediaType === "video") && (
          <MenuItem
            onClick={() => {
              handleSelectedMessages();
              handleClose();
            }}
          >
            Encaminhar
          </MenuItem>
        )}

        {message.fromMe &&
          message.mediaType !== "email" &&
          message.mediaType !== "template" && (
            <MenuItem onClick={handleOpenConfirmationModal}>
              {i18n.t("messageOptionsMenu.delete")}
            </MenuItem>
          )}
      </Menu>
    </>
  );
}

export default MessageOptionsMenu;
