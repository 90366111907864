import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  userHeader: {
    fontWeight: "900",
    marginTop: 10,
    // textAlign: "center",
    // alignContent: "center",
    // alignItems: "center",
    // justifyContent: "center",
    flexDirection: "row",
  },
  divider: {
    fontWeight: "900",
    marginTop: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  viewHeader: {
    flexDirection: "row",
    marginBottom: 3,
  },
  view: {
    flexDirection: "row",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    marginBottom: 3,
  },
  columnId: {
    fontSize: 10,
    width: "7%",
  },
  column: {
    fontSize: 10,
    width: "15%",
  },
  tags: {
    width: "20%",
    fontSize: 10,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  messages: {
    flex: 1,
  },
  message: {
    margin: 2,
    padding: 2,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    fontSize: 10,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    width: "100%",
  },
});

const defineEntityType = (entity) => {
  switch (entity) {
    case "userId":
      return "Atendente: ";
    case "queueId":
      return "Setor: ";
    case "whatsappId":
      return "Conexão: ";
    default:
      return "";
  }
};

const defineEntity = (key, groupBy, tickets) => {
  switch (groupBy) {
    case "userId":
      const userId = tickets[key][0].user?.id;
      return `${userId} - ${key}`;
    case "queueId":
      const queueId = tickets[key][0].queue?.id;
      return `${queueId} - ${key}`;
    case "whatsappId":
      const whatsappId = tickets[key][0].whatsapp?.id;
      return `${whatsappId} - ${key}`;
    default:
      return "";
  }
};

export const MyDocument = ({ pdfData, groupBy }) => {
  return (
    <Document style={styles.document}>
      <Page style={styles.page}>
        {Object.keys(pdfData?.tickets).map((key) => (
          <>
            <View>
              <Text style={styles.userHeader}>
                {key === "unassigned"
                  ? "Não Atribuído"
                  : `${defineEntityType(groupBy)} ${defineEntity(
                      key,
                      groupBy,
                      pdfData?.tickets
                    )}`}
              </Text>
              <View style={styles.viewHeader}>
                <Text style={styles.columnId}>ID</Text>

                {groupBy !== "queueId" && (
                  <Text style={styles.column}>Setor</Text>
                )}

                {groupBy !== "userId" && (
                  <Text style={styles.column}>Atendente</Text>
                )}

                <Text style={styles.column}>Contato</Text>

                <Text style={styles.tags}>Etiquetas</Text>

                <Text style={styles.column}>Mensagem</Text>
              </View>

              {pdfData?.tickets[key].map((ticket) => (
                <View style={styles.view}>
                  <Text style={styles.columnId}>{ticket?.id} </Text>

                  {groupBy !== "queueId" && (
                    <Text style={styles.column}>
                      {ticket?.queue?.name || "Sem Setor"}
                    </Text>
                  )}

                  {groupBy !== "userId" && (
                    <Text style={styles.column}>
                      {ticket?.user?.name || "Sem Atendente"}
                    </Text>
                  )}
                  <Text style={styles.column}>{ticket?.contact?.name}</Text>

                  <Text style={styles.tags}>
                    {ticket?.contact?.tags
                      ?.map((tag) => {
                        return tag?.name;
                      })
                      .join(", ")}
                  </Text>

                  <View style={styles.messages}>
                    {ticket?.messages?.map((message) => (
                      <Text style={styles.message}>
                        {message?.body
                          ?.replace("<p>", "")
                          .replace("</p>", "")
                          .replace("<p></p>", "")}
                      </Text>
                    ))}
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.divider}>
              -------------------------------------------------------------
            </Text>
          </>
        ))}
      </Page>
    </Document>
  );
};
