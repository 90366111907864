import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";
import { Box, CircularProgress } from "@material-ui/core";

function ExportingModal({
 open,
 onClose,
 onConfirm,
 exportingLoading,
 exportCount,
}) {
 return (
  <Dialog
   open={open}
   onClose={() => onClose(false)}
   aria-labelledby="confirm-dialog"
   disableBackdropClick={true}
  >
   <DialogTitle id="confirm-dialog">
    {exportingLoading ? `Exportando Contatos` : `Operação Concluída`}
   </DialogTitle>
   <DialogContent dividers>
    <Typography>
     {exportingLoading ? (
      <Box
       display="flex"
       flexDirection="row"
       alignItems="center"
       justifyContent="center"
      >
       <CircularProgress style={{ color: "#009688" }} size={20} />
      </Box>
     ) : (
      `Contatos exportados: ${exportCount}`
     )}
    </Typography>
   </DialogContent>
   <DialogActions>
    <Button
     variant="contained"
     onClick={() => {
      onClose(false);
      onConfirm();
     }}
     color="secondary"
     disabled={exportingLoading}
    >
     Fechar
    </Button>
   </DialogActions>
  </Dialog>
 );
}

export default ExportingModal;
