import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import MessageIcon from "@material-ui/icons/Message";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EmailIcon from "@material-ui/icons/Email";
import TelegramIcon from "@material-ui/icons/Telegram";
import PhoneIcon from "@material-ui/icons/Phone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EditIcon from "@material-ui/icons/Edit";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../services/api";
import NewTicketModal from "../../components/NewTicketModal";
import ScheduleModal from "../../components/ScheduleModal";
import ContactModal from "../../components/ContactModal";
import CardModal from "./CardModal";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 220,
    borderRadius: 3,
    // padding: 10,
    backgroundColor: "#fff",
    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.2)",
    marginBottom: 15,

    // scale on hover
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all 0.2s ease-in-out",
    },
  },

  cover: {
    borderRadius: "3px 3px 0 0",
    width: "100%",
    maxHeight: "40px",
    objectFit: "cover",
    opacity: 0.8,
    transition: "all 0.5s ease-in-out",

    "&:hover": {
      // change height to auto with transition
      maxHeight: "500px",
      opacity: 1,
      transition: "all 0.5s ease-in-out",
    },
  },

  container: {
    backgroundColor: "#fff",
    borderRadius: "3px",
    padding: "0px 10px 5px 10px",
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },

  contactHeader: {
    display: "flex",
    justifyContent: "space-between",

    "& > *": {
      fontSize: 13,
      fontWeight: 500,
    },
  },

  titleBox: {
    display: "flex",
    alignItems: "center",
  },

  contactTitle: {
    fontSize: 12,
    fontWeight: 500,
  },

  contactId: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontStyle: "italic",
    opacity: 0.8,
  },

  status: {
    marginTop: -5,
    fontSize: 10,
    display: "block",
  },

  infoBox: {
    "& > *": {
      fontStyle: "italic",
    },
  },

  info: {
    display: "flex",
    alignItems: "center",
  },

  infoIcon: {
    fontSize: 12,
    marginRight: 2,
    opacity: 0.7,
  },

  infoRow: {
    display: "flex",
    justifyContent: "space-between",

    "& > *": {
      fontSize: 12,
    },
  },

  description: {
    margin: "5px 0",
    fontSize: 11,
    display: "flex",
    alignItems: "center",
  },

  removeColumnBtn: {
    marginLeft: 210,
    marginBottom: -17,
    padding: 2,
    backgroundColor: "#dbdbdb",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2)",

    "&:hover": {
      backgroundColor: "#FD1C74",
    },

    zIndex: 1,
  },

  removeIcon: {
    color: "#fff",
    fontSize: 10,
  },

  email: {
    width: "130px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  lastMessageBox: {
    display: "flex",
    alignItems: "center",
    color: "grey",
  },

  lastMessage: {
    fontSize: 10,
    color: "grey",
    width: "190px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  tagsBox: {
    display: "flex",
    gap: 3,
    flexWrap: "wrap",
  },

  tag: {
    cursor: "pointer",
    fontWeight: 500,
    textAlign: "center",
    textShadow:
      "0px 0px 1px rgba(0,0,0,1), 0px 0px 1px rgba(0,0,0,1), 0px 0px 1px rgba(0,0,0,1)",
    color: "#fff",
    padding: "1px 5px",
    borderRadius: 3,
    fontSize: 9,
    maxWidth: "80px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  actionsBox: {
    display: "flex",
    // justifyContent: "flex-end",
  },

  actionIcon: {
    fontSize: 15,

    "&:hover": {
      color: "#FD1C74",
    },
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  expiresIn: {
    fontSize: 10,
    fontStyle: "italic",
    opacity: 0.8,
  },
}));

const Card = ({ contact, removeCard, users, reloadBoard }) => {
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);

  const classes = useStyles();

  const formatNumber = (number) => {
    if (number.length === 13) {
      return `(${number.slice(2, 4)}) ${number.slice(4, 9)}-${number.slice(
        9,
        13
      )}`;
    }
    if (number.length === 12) {
      return `(${number.slice(2, 4)}) ${number.slice(4, 8)}-${number.slice(
        8,
        12
      )}`;
    }
  };

  const handleNewTicketModalOpen = () => {
    if (!contact) return;
    setNewTicketModalOpen(true);
  };

  const removeContact = async (contactId) => {
    try {
      await api.post("/sales-funnel/unpin-contact", {
        contactId,
        salesFunnelId: contact.funnelContact.salesFunnelId,
      });

      removeCard(contactId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <IconButton size="small" className={classes.removeColumnBtn}>
        <CloseIcon
          className={classes.removeIcon}
          onClick={() => removeContact(contact.id)}
        />
      </IconButton>
      <div className={classes.card}>
        <ContactModal
          open={contactModalOpen}
          onClose={() => {
            reloadBoard();
            setContactModalOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          contactId={contact.id}
        />
        <ScheduleModal
          open={scheduleModalOpen}
          onClose={() => {
            reloadBoard();
            setScheduleModalOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          scheduleId={null}
          contact={contact}
        />
        <NewTicketModal
          initialContact={contact}
          modalOpen={newTicketModalOpen}
          onClose={(e) => {
            reloadBoard();
            setNewTicketModalOpen(false);
          }}
        />
        <CardModal
          modalOpen={cardModalOpen}
          onClose={(e) => {
            reloadBoard();
            setCardModalOpen(false);
          }}
          selectedCard={contact.funnelContact}
          reloadBoard={reloadBoard}
        />
        {contact.funnelContact?.cover ? (
          <img className={classes.cover} src={contact.funnelContact?.cover} />
        ) : (
          <div style={{ height: "5px" }} />
        )}
        <div
          className={classes.container}
          onClick={
            // if the user dont have clicked on a button
            (e) => {
              console.log(e.target.classList);
              if (
                e.target.classList[0]?.length > 1 &&
                e.target.classList[0] !== "MuiSvgIcon-root"
              ) {
                setCardModalOpen(true);
              }
            }
          }
        >
          <div className={classes.contactHeader}>
            <div className={classes.titleBox}>
              {contact.isGroup ? (
                <GroupIcon className={classes.infoIcon} />
              ) : (
                <PersonIcon className={classes.infoIcon} />
              )}
              <Typography className={classes.contactTitle}>
                {contact.name}
              </Typography>
            </div>
            <Typography className={classes.contactId}>
              {`#${contact.id}`}
            </Typography>
          </div>

          <div className={classes.infoBox}>
            <div className={classes.infoRow}>
              <Typography className={classes.info}>
                {["whatsapp", "whatsappbusiness"].includes(contact.channel) &&
                  !contact.isGroup && (
                    <>
                      <PhoneIcon className={classes.infoIcon} />
                      {formatNumber(contact.number)}
                    </>
                  )}
                {contact.channel === "email" && (
                  <>
                    <AlternateEmailIcon className={classes.infoIcon} />
                    <Tooltip title={contact.email}>
                      <div className={classes.email}>{contact.email}</div>
                    </Tooltip>
                  </>
                )}
              </Typography>
              <Typography className={classes.info}>
                {["whatsapp", "whatsappbusiness"].includes(contact.channel) && (
                  <WhatsAppIcon className={classes.infoIcon} />
                )}
                {contact.channel === "telegram" && (
                  <TelegramIcon className={classes.infoIcon} />
                )}
                {contact.channel === "facebook" && (
                  <FacebookIcon className={classes.infoIcon} />
                )}
                {contact.channel === "instagram" && (
                  <InstagramIcon className={classes.infoIcon} />
                )}
                {contact.channel === "email" && (
                  <EmailIcon className={classes.infoIcon} />
                )}
                {`${contact.channel || "sem conexão"}`}
              </Typography>
            </div>
            <div className={classes.infoRow}>
              <Typography className={classes.description}>
                {contact.funnelContact?.description}
              </Typography>
            </div>
            <div className={classes.infoRow}>
              {contact.funnelContact?.userId && (
                <Typography className={classes.description}>
                  <strong style={{ marginRight: 3 }}>Responsável: </strong>{" "}
                  {
                    users?.find(
                      (user) => user.id === contact.funnelContact?.userId
                    )?.name
                  }
                </Typography>
              )}
            </div>
          </div>

          <div className={classes.tagsBox}>
            {contact.tags.map((tag) => (
              <Tooltip title={tag.name} key={tag.id}>
                <Typography
                  className={classes.tag}
                  style={{ backgroundColor: tag.color }}
                >
                  {tag.name}
                </Typography>
              </Tooltip>
            ))}
          </div>

          <div className={classes.footer}>
            <Tooltip title="Data limite">
              <Typography className={classes.expiresIn}>
                {contact.funnelContact?.expiresIn &&
                  new Date(contact.funnelContact?.expiresIn).toLocaleDateString(
                    "pt-BR"
                  )}
              </Typography>
            </Tooltip>
            <div className={classes.actionsBox}>
              <Tooltip title="Editar cartão">
                <IconButton size="small" onClick={setCardModalOpen}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Dados do contato">
                <IconButton size="small" onClick={setContactModalOpen}>
                  <AssignmentIndIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Agendar mensagem">
                <IconButton size="small" onClick={setScheduleModalOpen}>
                  <ScheduleIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Abrir ticket">
                <IconButton size="small" onClick={handleNewTicketModalOpen}>
                  <MessageIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
