import React, { useState } from "react";

import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  Tooltip,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";

export default function TicketsQueueSelect({
  userQueues,
  selectedQueueIds = [],
  setSelectedQueueIds,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const darkMode = localStorage.getItem("darkMode") === "true" ? true : false;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginTop: -22 }}>
      <FormControl fullWidth margin="dense">
        <Tooltip title="Filtro por setores">
          <IconButton
            style={{
              padding: 0,
              marginTop: "14px",
              color: `${darkMode ? "#c7c7c7" : "#6e6f70"}`,
            }}
            aria-controls="select-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <AccountTreeOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="select-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {userQueues?.length > 0 &&
            userQueues.map((queue) => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: queue.color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.indexOf(queue.id) > -1}
                  onClick={() => {
                    if (selectedQueueIds.indexOf(queue.id) > -1) {
                      setSelectedQueueIds(
                        selectedQueueIds.filter((id) => id !== queue.id)
                      );
                    } else {
                      setSelectedQueueIds([...selectedQueueIds, queue.id]);
                    }
                  }}
                />
                <ListItemText primary={queue.name} />
              </MenuItem>
            ))}
        </Menu>
      </FormControl>
    </div>
  );
}
