import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  BarChart,
  CartesianGrid,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  Tooltip,
} from 'recharts';

import { i18n } from '../../translate/i18n';

import Title from './Title';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 'normal',
    [theme.breakpoints.down(650)]: {
      fontSize: '18px',
    },
  },
}));

function Chart({ dataChart: { getDataDashboard }, period }) {
  const theme = useTheme();
  const classes = useStyles();

  const [data, setData] = useState([
    {
      id: 0,
      name: 'atendente',
      amount: 0,
    },
  ]);

  useEffect(() => {
    const getDataChart = async () => {
      try {
        const { dataChartAttendants } = await getDataDashboard(period);
        setData(dataChartAttendants);
      } catch (error) {
        console.log(error);
      }
    };

    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  }

  return (
    <>
      <Title>
        <div className={classes.title}>
          {i18n.t('dashboard.charts.attendantsChart.title')}
        </div>
      </Title>
      <ResponsiveContainer>
        <BarChart
          data={data}
          barSize={40}
          width={730}
          height={250}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" stroke={theme.palette.text.secondary} />
          <YAxis
            type="number"
            allowDecimals={false}
            stroke={theme.palette.text.secondary}
          >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              {/* Atendimentos */}
              {i18n.t('dashboard.charts.attendantsChart.sideTitle')}
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="amount"
            fill={theme.palette.primary.main}
            onMouseOver={() => Tooltip}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default Chart;
