import openSocket from "socket.io-client";
import { getBackendUrl, getFrontendUrl } from "../config";

function connectToSocket({ userId, scope, component, onDisconnect }) {
  try {
    return openSocket(
      process.env.NODE_ENV === "development"
        ? getBackendUrl()
        : getFrontendUrl(),
      {
        query: { userId, scope, component },
      }
    );
  } catch (err) {
    console.error(err);
  }
}

export default connectToSocket;
