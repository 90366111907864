import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function TemplateOnlyBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#FC3333" }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography style={{ padding: "0.5em" }}>
            <span style={{ color: "#DDD" }}>
              {i18n.t("templateOnlyBar.warningBefore")}
              <a
                href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#DDD" }}
              >
                {i18n.t("templateOnlyBar.warningAfter")}
              </a>
            </span>
          </Typography>
        </Box>
      </AppBar>
    </div>
  );
}
