import React, { useContext, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles' 
import CardContent from '@material-ui/core/CardContent';
import SubjectIcon from '@material-ui/icons/Subject';
import Typography from '@material-ui/core/Typography';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { Box, Divider, Button, IconButton } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import EmailReadMessageModal from './EmailReadMessageModal';
import { ThemeContext } from '../../context/Theme/ThemeContext';
import MessageOptionsMenu from "../MessageOptionsMenu";
import AttachmentIcon from '@material-ui/icons/Attachment';

const useStyles = makeStyles((theme) => ({
	  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },}));



export default function EmailMessageCard({ message }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const messageOptionsMenuOpen = Boolean(anchorEl);
	const classes = useStyles();
	const { darkMode } = useContext(ThemeContext);
	const [messageModalOpen, setMessageModalOpen] = React.useState(false);
	const [messageMediaUrls, setMessageMediaUrls] = React.useState([]);

	useEffect(() => {
		if (message && message.mediaUrl) {
			// this sets the media urls to an array of strings that are the urls of the media - this is used to get the attachment names from the urls without disturbing the behaviour of the mediaUrl original field in the database.
			setMessageMediaUrls(JSON.parse(message.mediaUrl.split('/public/')[1]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const messageBody = JSON.parse(message.body);
	const bgColor = darkMode ? '#424242' : message.fromMe ? '#D9FDD3' : '';

	return (
		<Box>
			<MessageOptionsMenu
				message={message}
				anchorEl={messageModalOpen ? null : anchorEl}
				menuOpen={messageOptionsMenuOpen}
				handleClose={() => setAnchorEl(null)}
			/>
			<IconButton
				variant="contained"
				size="small"
				id="messageActionsButton"
				disabled={message.isDeleted}
				className={classes.messageActionsButton}
				onClick={(e) => setAnchorEl(e.currentTarget)}
			>
				<ExpandMore />
			</IconButton>
			<Card
				style={{
					width: '100%',
					height: '100%',
					backgroundColor: bgColor,
				}}
			>
				<CardContent>
					<Typography
						color="textSecondary"
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<CallReceivedIcon /> {messageBody.sentBy}
					</Typography>
					<Box
						style={{
							marginTop: 10,
							padding: 10,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'left',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							variant="h5"
							component="h5"
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<SubjectIcon fontSize="large" style={{ marginRight: 5 }} />
							{messageBody.subject
								? messageBody.subject.charAt(0).toUpperCase() +
								  messageBody.subject.slice(1)
								: 'Sem assunto'}
						</Typography>
						<Typography color="textSecondary">
							<Typography variant="caption">
								{messageBody.sentTo.length > 1
									? messageBody.sentTo.map((to, index) =>
											index === messageBody.sentTo.length - 1 ? to : to + ' '
									  )
									: messageBody.sentTo[0]}
							</Typography>
						</Typography>
					</Box>
					<Divider />
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ marginTop: 10 }}
						onClick={() => setMessageModalOpen(true)}
					>
						Ler e-mail{'   '}
						{message && messageMediaUrls.length > 0 && (
							<AttachmentIcon fontSize="medium" />
						)}
					</Button>
					<EmailReadMessageModal
						message={message}
						modalOpened={messageModalOpen}
						handleModalOpened={setMessageModalOpen}
						messageMediaUrls={messageMediaUrls}
					/>
				</CardContent>
			</Card>
		</Box>
	);
}
