import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import ModalImageChatbot from "../ModalImageChatbot";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
  templateSelected: {
    backgroundColor: "#e0e0e0",
    border: "1px solid #bdbdbd",
  },

  tableComponent: {
    "& .MuiTableCell-root": {
      fontSize: "0.7em",
    },
  },
}));

export function TemplatesTable({
  whatsappId,
  scheduleData,
  setScheduleData,
  setTemplateParamsLength,
  setTemplateParams,
}) {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const { whatsApps } = useWhatsApps();

  const countTemplateParams = (text) => {
    var regex = /\{{\d+}}/g;
    var matches = text?.match(regex);

    if (matches) {
      return matches.length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (!whatsappId) {
      console.log("No whatsappId");
      return;
    }

    async function getTemplates() {
      try {
        setLoading(true);

        const whatsapp = whatsApps.find(
          (whatsApp) => whatsApp.id === whatsappId
        );

        if (!whatsapp) {
          console.log("No whatsapp");
          return;
        }

        const { wabaAccessToken, wabaAccountId } = whatsapp;

        const response = await fetch(
          `https://graph.facebook.com/v15.0/${wabaAccountId}/message_templates`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${wabaAccessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        const filteredTemplates = data.data.filter(
          (template) =>
            template.name.includes("notificame") &&
            template.status === "APPROVED"
        );

        setTemplates(filteredTemplates);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    getTemplates();
  }, [whatsappId, whatsApps]);

  const handleTemplateSelected = (template) => {
    const templateId = template.id;

    const templateText = templates
      .find((tmpt) => tmpt.id === templateId)
      .components?.find((component) => component.type === "BODY").text;

    setTemplateParamsLength(countTemplateParams(templateText));
    // setTemplateParams(Array(countTemplateParams(templateText)).fill(""));

    setScheduleData({
      ...scheduleData,
      templateId,
      template,
    });
  };

  useEffect(() => {
    if (!scheduleData?.templateId) {
      return;
    }

    const templateText = templates
      .find((tmpt) => tmpt.id === scheduleData.templateId)
      ?.components?.find((component) => component.type === "BODY").text;

    setTemplateParamsLength(countTemplateParams(templateText));
    // setTemplateParams(Array(countTemplateParams(templateText)).fill(""));
  }, [scheduleData, templates]);

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="simple table"
        size="small"
        className={classes.tableComponent}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {i18n.t("templates.table.type")}
            </TableCell>
            <TableCell align="center">
              {i18n.t("templates.table.header")}
            </TableCell>

            <TableCell align="center">
              {i18n.t("templates.table.body")}
            </TableCell>
            <TableCell align="center">
              {i18n.t("templates.table.footer")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <CircularProgress style={{ color: "#009688" }} size={20} />
              </TableCell>
            </TableRow>
          )}
          {!loading && templates.length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                Nenhum template encontrado!
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            templates.length > 0 &&
            templates
              .filter((t) => t.status === "APPROVED")
              .map((template) => (
                <TableRow
                  className={
                    scheduleData?.templateId === template.id
                      ? classes.templateSelected
                      : null
                  }
                  key={template.id}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleTemplateSelected(template)}
                >
                  <TableCell align="center">
                    {/* {template.name.split("notificame_")[1]} */}
                    {i18n.t(`templates.table.types.${template.category}`)}
                  </TableCell>
                  <TableCell align="center">
                    {/* {template.name.split("notificame_")[1]} */}
                    <TableCell align="center">
                      {template.components[0]?.format === "TEXT" ||
                      !template.components[0]?.format
                        ? template.components[0]?.text
                        : template.components[0]?.format === "IMAGE" && (
                            <ModalImageChatbot
                              imageUrl={
                                template.components[0]?.example
                                  ?.header_handle[0]
                              }
                              style={{ width: "10px" }}
                            />
                          )}
                    </TableCell>
                  </TableCell>
                  <TableCell align="center">
                    {template.components[1]?.text}
                  </TableCell>
                  <TableCell align="center">
                    {template.components[2]?.text}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
