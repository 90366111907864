import { Typography } from '@material-ui/core';
import React from 'react';
import parse from 'html-react-parser';
import * as sanitizeHtml from 'sanitize-html';
import { Box } from "@material-ui/core";

export default function EmailReplyText({messageBody}) {
  return (
    <Box border={"1px solid #c4c4c4"} borderRadius="10px" overflow={"scroll"} maxHeight={"20%"}>
      <Typography component={"p"} style={{overflow:"scroll", maxHeight:"150px", wordBreak:"break-word", padding:"1em", textAlign:"justify" }} children={parse(sanitizeHtml(messageBody))}>
      </Typography>
    </Box>
  )
} 