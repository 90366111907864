import React, { useContext, useEffect, useReducer, useState } from "react";

import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import Card from "./Card";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import toastError from "../../errors/toastError";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete/Autocomplete";
import ColorPicker from "../../components/ColorPicker";

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  columnTitle: {
    display: "flex",
    alignItems: "center",
  },

  cardsCounter: {
    color: "#fff",
    height: 15,
    marginLeft: 5,
    fontSize: 10,
    backgroundColor: "#ccc",
    borderRadius: "40%",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },

  addCard: {
    fontSize: 12,
    width: "100%",
    border: "1px dashed #ccc",
    opacity: 0.5,
    marginBottom: 5,

    "&:hover": {
      opacity: 0.8,
    },
  },

  addColumn: {
    marginTop: 5,
    fontSize: 12,
    minWidth: "250px",
    border: "1px dashed #ccc",
    opacity: 0.5,

    "&:hover": {
      opacity: 0.8,
    },
  },

  addColumnInput: {
    marginTop: 5,
    minWidth: "250px",
  },

  moreIcon: {
    opacity: 0.5,
    cursor: "pointer",

    "&:hover": {
      color: "#FD1C74",
      opacity: 0.8,
    },
  },

  select: {
    // remove bottom border
    "&:before": {
      borderBottom: "none",
    },

    // remove outline on hover

    "&:after": {
      borderBottom: "none",
    },

    opacity: 0.5,
    "&:hover": {
      color: "#FD1C74",
      opacity: 0.8,

      "&:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
    },

    "& .MuiList-root": {
      backgroundColor: "#fff",
    },
  },
}));

const SalesFunnel = () => {
  const classes = useStyles();
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [board, setBoard] = useState({
    columns: [],
  });
  const [inputOpen, setInputOpen] = useState({
    open: false,
    columnId: null,
  });
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [newColumnOpen, setNewColumnOpen] = useState(false);
  const [editingColumn, setEditingColumn] = useState({
    id: null,
    title: null,
  });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { users },
        } = await api.get("/users/");
        setUsers(users);
      } catch (error) {
        toastError(error);
      }
    })();
  }, []);

  const reloadBoard = async () => {
    try {
      const { data } = await api.get("/sales-funnel", {
        params: {
          workspace: "contacts",
        },
      });

      setBoard({
        columns: data.map((column) => ({
          id: column.id,
          title: column.name,
          color: column.color,
          cards: [...column.contacts],
        })),
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/sales-funnel", {
          params: {
            workspace: "contacts",
          },
        });

        setBoard({
          columns: data.map((column) => ({
            id: column.id,
            title: column.name,
            color: column.color,
            cards: [...column.contacts],
          })),
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleCardMove = async (_card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);

    await api.post("/sales-funnel/pin-contact", {
      contactId: _card.id,
      salesFunnelId: destination.toColumnId,
    });
  };

  const removeCard = async (contactId) => {
    setBoard({
      columns: board.columns.map((column) => ({
        ...column,
        cards: column.cards.filter((card) => card.id !== contactId),
      })),
    });
  };

  const addColumn = async (e) => {
    const { data } = await api.post("/sales-funnel", {
      name: e.target.value,
      workspace: "contacts",
    });

    setBoard({
      columns: [
        ...board.columns,
        {
          id: data.id,
          title: data.name,
          cards: [],
        },
      ],
    });
  };

  const removeColumn = async (columnId) => {
    const { data } = await api.delete(`/sales-funnel/${columnId}`);

    reloadBoard(data);
  };

  const updateColumn = async ({ columnId, name, color }) => {
    try {
      const { data } = await api.put(`/sales-funnel/${columnId}`, {
        name,
        color,
      });

      reloadBoard();
    } catch (error) {
      toastError(error);
    }

    // const updatedBoard = board.columns.map((column) => {
    //   if (column.id === columnId) {
    //     return { ...column, title: name };
    //   }
    //   return column;
    // });
    // setBoard({ columns: updatedBoard });
  };

  useEffect(() => {
    if (searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  const renderOption = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    }
    return `adicionar ${option.name}`;
  };

  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    }
    return `${option.name}`;
  };

  const filter = createFilterOptions({
    trim: true,
  });

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);

    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }

    return filtered;
  };

  const handleSelectOption = async (e, newValue) => {
    await api.post("/sales-funnel/pin-contact", {
      contactId: newValue.id,
      salesFunnelId: inputOpen.columnId,
    });

    reloadBoard();
  };

  const handleCoumnPositionChange = async (column, source, destination) => {
    const { data } = await api.put(`/sales-funnel/${column.id}`, {
      position: destination.toPosition,
    });

    reloadBoard(data);
  };

  return (
    <>
      <ColorPicker
        open={colorPickerModalOpen}
        handleClose={() => setColorPickerModalOpen(false)}
        onChange={(color) => {
          updateColumn({
            columnId: editingColumn.id,
            name: editingColumn.title,
            color,
          });
          setColorPickerModalOpen(false);
        }}
      />
      <MainHeader>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          style={{
            paddingTop: 7,
          }}
        >
          Funil
        </Typography>
      </MainHeader>
      <Board
        allowAddColumn
        onCardDragEnd={handleCardMove}
        onColumnDragEnd={handleCoumnPositionChange}
        renderCard={(card) => (
          <Card
            contact={card}
            removeCard={removeCard}
            users={users}
            reloadBoard={reloadBoard}
          />
        )}
        renderColumnHeader={({ title, id, color }) => (
          <div>
            <div className={classes.columnHeader}>
              <div className={classes.columnTitle}>
                {editingColumn.id === id ? (
                  <ClickAwayListener
                    onClickAway={() =>
                      setEditingColumn({ id: null, title: null })
                    }
                  >
                    <TextField
                      autoFocus
                      size="small"
                      label="Nome da coluna"
                      variant="outlined"
                      value={editingColumn.title}
                      onChange={(e) =>
                        setEditingColumn({
                          id: editingColumn.id,
                          title: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          updateColumn({
                            columnId: editingColumn.id,
                            name: editingColumn.title,
                          });
                          setEditingColumn({ id: null, title: null });
                        }
                      }}
                    />
                  </ClickAwayListener>
                ) : (
                  <Typography
                    cursor="pointer"
                    onClick={() => setEditingColumn({ id, title })}
                    style={{
                      color: color || "#1f1f1f",
                    }}
                  >
                    {title}
                  </Typography>
                )}
                <Typography
                  className={classes.cardsCounter}
                  style={{
                    // background color with colunm color but with 50% opacity
                    backgroundColor: color ? `${color}80` : "#ccc",
                  }}
                >
                  {
                    board.columns.find((column) => column.title === title).cards
                      .length
                  }
                </Typography>
              </div>
              <div>
                <Select
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className={classes.select}
                  // IconComponent={() => (
                  // <MoreHorizIcon
                  // onClick={() => setMoreIconOpen(!moreIconOpen)}
                  // className={classes.moreIcon}
                  // />
                  // )}
                  IconComponent={MoreHorizIcon}
                >
                  <MenuItem onClick={() => removeColumn(id)}>Remover</MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setEditingColumn({
                        id,
                      });
                      setColorPickerModalOpen(true);
                    }}
                  >
                    Alterar cor
                  </MenuItem>
                </Select>
              </div>
            </div>
            {inputOpen.open &&
            inputOpen.columnId ===
              board.columns.find((column) => column.title === title).id ? (
              <ClickAwayListener
                onClickAway={() =>
                  setInputOpen({ open: false, columnId: null })
                }
              >
                <Autocomplete
                  options={options}
                  loading={loading}
                  style={{ width: "100%", marginBottom: 5 }}
                  size="small"
                  clearOnBlur
                  autoHighlight
                  freeSolo
                  clearOnEscape
                  getOptionLabel={renderOptionLabel}
                  renderOption={renderOption}
                  filterOptions={createAddContactOption}
                  onChange={(e, newValue) => handleSelectOption(e, newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"contato"}
                      variant="outlined"
                      autoFocus
                      required
                      onChange={(e) => setSearchParam(e.target.value)}
                      onKeyPress={(e) => {
                        if (loading) return;
                        if (e.key === "Enter") {
                          // handleSaveTicket(selectedContact.id);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </ClickAwayListener>
            ) : (
              <Button
                className={classes.addCard}
                onClick={() =>
                  setInputOpen({
                    open: true,
                    columnId: board.columns.find(
                      (column) => column.title === title
                    ).id,
                  })
                }
              >
                Adicionar cartão
              </Button>
            )}
          </div>
        )}
        renderColumnAdder={() => {
          if (newColumnOpen) {
            return (
              <ClickAwayListener onClickAway={() => setNewColumnOpen(false)}>
                <TextField
                  className={classes.addColumnInput}
                  autoFocus
                  size="small"
                  label="Nome da coluna"
                  variant="outlined"
                  onChange={(e) => setNewColumnOpen(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      addColumn(e);
                      setNewColumnOpen(false);
                    }
                  }}
                />
              </ClickAwayListener>
            );
          } else {
            return (
              <Button
                className={classes.addColumn}
                onClick={() => setNewColumnOpen(true)}
              >
                Adicionar coluna
              </Button>
            );
          }
        }}
      >
        {board}
      </Board>
    </>
  );
};

export default SalesFunnel;
