import { MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export const FunnelSelect = ({ contact }) => {
  const [salesFunnels, setSalesFunnels] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/sales-funnel", {
          params: {
            workspace: "contacts",
          },
        });
        setSalesFunnels(data);
      } catch (error) {
        toastError(error);
      }
    })();
  }, []);

  const handleCardMove = async (toSalesFunnelId) => {
    await api.post("/sales-funnel/pin-contact", {
      contactId: contact.id,
      salesFunnelId: toSalesFunnelId,
    });
  };

  return (
    <>
      <TextField
        select
        label="Funil de vendas"
        defaultValue={contact?.funnels[0]?.FunnelContact?.salesFunnelId}
        onChange={(e) => handleCardMove(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      >
        {salesFunnels.map((funnel) => (
          <MenuItem key={funnel.id} value={funnel.id}>
            {funnel.name}
          </MenuItem>
        ))}
        {/* <MenuItem value="1">1 - Não qualificado</MenuItem> */}
      </TextField>
    </>
  );
};
