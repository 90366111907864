import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Paper,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ActionChips from "./ActionChips";
import ModalImageChatbot from "../ModalImageChatbot";
import ConfirmationModal from "../ConfirmationModal";
import MediasModal from "./MediasModal";

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "1000px" },

  root: {
    display: "flex",
    flexWrap: "wrap",
    // width: "90vw",
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  colorAdorment: {
    width: 20,
    height: 20,
  },

  nodeOption: {
    display: "flex",
    // set fixed width and retiscence to text inside menu item
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function ChatbotModal({
  open,
  onClose,
  selectedChatbot,
  setSelectedChatbot,
  chatbots,
  setChatbots,
}) {
  const classes = useStyles();
  const [creatingLoading, setCreatingLoading] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [mediasModalOpen, setMediasModalOpen] = React.useState(false);
  const [selectedNode, setSelectedNode] = React.useState(null);

  const createNode = async () => {
    const { data } = await api.post("/chatbot/node", {
      header: `Nó ${selectedChatbot.nodes?.length + 1 || 1}`,
      chatbotId: selectedChatbot.id,
    });

    return data;
  };

  useEffect(() => {
    if (!confirmationOpen) {
      setConfirmationFunction(null);
      setConfirmationTitle("");
    }
  }, [confirmationOpen]);

  const createOption = async (nodeId) => {
    if (creatingLoading) return;
    setCreatingLoading(true);
    try {
      const { data } = await api.post("/chatbot/option", {
        text: `Opção ${
          selectedChatbot.nodes.find((n) => n.id === nodeId).options?.length +
            1 || 1
        }`,
        nodeId,
        toNodeId: null,
        actionId: null,
      });

      setTimeout(() => {
        setCreatingLoading(false);
      }, 3000);
      return data;
    } catch (err) {
      setCreatingLoading(false);
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleDeleteNode = async (nodeId) => {
    try {
      await api.delete(`/chatbot/node/${nodeId}`);
      setSelectedChatbot({
        ...selectedChatbot,
        nodes: selectedChatbot.nodes.filter((node) => node.id !== nodeId),
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteOption = async (optionId) => {
    try {
      await api.delete(`/chatbot/option/${optionId}`);

      setSelectedChatbot({
        ...selectedChatbot,
        nodes: selectedChatbot.nodes.map((node) => ({
          ...node,
          options: node.options?.filter((option) => option.id !== optionId),
        })),
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleUpdateNode = async (nodeId, data) => {
    try {
      const { data: node } = await api.put(`/chatbot/node/${nodeId}`, data);

      const newNodes = selectedChatbot.nodes.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            header: data.header,
          };
        }
        return n;
      });
      setSelectedChatbot({
        ...selectedChatbot,
        nodes: newNodes,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleAddNodeUrl = async (e, nodeId) => {
    if (!e.target.files) {
      return;
    }

    try {
      const img = e.target.files[0];
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", img);
      const response = await api.post("/chatbot/node-upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data.file.path}`;

      const { data: nodeMedia } = await api.post(
        `/chatbot/node-media/${nodeId}`,
        {
          url,
        }
      );

      const newNodes = selectedChatbot.nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            nodeMedias: [...node.nodeMedias, nodeMedia],
          };
        }
        return node;
      });

      setSelectedChatbot({
        ...selectedChatbot,
        nodes: newNodes,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteNodeUrl = async (nodeId, mediaId) => {
    try {
      await api.delete(`/chatbot/node-media/${mediaId}`);

      const newNodes = selectedChatbot.nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            nodeMedias: [
              ...node.nodeMedias.filter((media) => media.id !== mediaId),
            ],
          };
        }
        return node;
      });

      setSelectedChatbot({
        ...selectedChatbot,
        nodes: newNodes,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const removeNodeUrl = async (nodeId) => {
    try {
      await api.put(`/chatbot/node/${nodeId}`, { url: null });

      const newNodes = selectedChatbot.nodes.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            url: null,
          };
        }
        return n;
      });
      setSelectedChatbot({
        ...selectedChatbot,
        nodes: newNodes,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleUpdateChatbot = async (data) => {
    try {
      const { data: chatbot } = await api.put(
        `/chatbot/${selectedChatbot.id}`,
        data
      );

      setSelectedChatbot({
        ...selectedChatbot,
        name: chatbot.name,
      });

      setChatbots(
        chatbots.map((c) => {
          if (c.id === chatbot.id) {
            return { ...c, name: chatbot.name };
          }
          return c;
        })
      );
    } catch (err) {
      toastError(err);
    }
  };

  const updateOption = async (data) => {
    const { text, optionId, toNodeId, disabled } = data;

    const body = {};

    if (toNodeId) {
      body.toNodeId = toNodeId;
    }

    if (toNodeId === "empty") {
      body.toNodeId = null;
    }

    if (text) {
      body.text = text;
    }

    if (disabled !== undefined) {
      body.disabled = disabled;
    }

    const { data: option } = await api.put(`/chatbot/option/${optionId}`, {
      ...body,
    });

    return option;
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={confirmationTitle}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={confirmationFunction}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        style={{ width: "90vw" }}
        classes={{ paper: classes.paper }}
      >
        <MediasModal
          medias={
            selectedChatbot?.nodes?.find((n) => n.id === selectedNode)
              ?.nodeMedias
          }
          open={mediasModalOpen}
          onClose={() => setMediasModalOpen(false)}
          handleAddNodeUrl={handleAddNodeUrl}
          handleDeleteNodeUrl={handleDeleteNodeUrl}
          selectedNode={selectedNode}
        />
        <DialogTitle>
          {selectedChatbot ? "Editar chatbot" : "Adicionar chatbot"}
        </DialogTitle>
        <DialogContent dividers style={{ width: "100%" }}>
          <TextField
            label="Nome do chatbot"
            defaultValue={selectedChatbot ? selectedChatbot.name : ""}
            // value={selectedChatbot?.name}
            onBlur={(e) => {
              handleUpdateChatbot({
                name: e.target.value,
              });
            }}
            name="name"
            variant="outlined"
            margin="dense"
            className={classes.textField}
          />

          <List style={{ width: "100%" }}>
            {selectedChatbot?.nodes?.map((node, index) => (
              <ListItem key={index} style={{ width: "100%" }}>
                <Paper style={{ width: "100%" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "1em",
                    }}
                  >
                    <Box
                      style={{
                        background: "#009688",
                        borderRadius: "50%",
                        width: "2em",
                        height: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        color: "#fff",
                        filter: "drop-shadow(0 0 0.15rem #009688)",
                        marginRight: "1em",
                      }}
                    >
                      {node.id}
                    </Box>

                    <TextField
                      label="Cabeçalho"
                      multiline
                      maxRows={4}
                      defaultValue={node.header}
                      onBlur={(e) => {
                        handleUpdateNode(node.id, {
                          header: e.target.value,
                        });
                      }}
                      name="name"
                      margin="dense"
                      className={classes.textField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              marginBottom: "0.8em",
                            }}
                          >
                            {console.log(node.url)}
                            {node.url ? (
                              <>
                                <Tooltip title={node.url}>
                                  <ModalImageChatbot
                                    imageUrl={node.url}
                                    style={{ width: "10px" }}
                                  />
                                </Tooltip>

                                <IconButton component="span">
                                  <Tooltip title="Remover mídia">
                                    <CancelIcon
                                      onClick={() => removeNodeUrl(node.id)}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </>
                            ) : (
                              <Tooltip title="Anexar mídias">
                                <Button component="label">
                                  <AttachFileIcon
                                    onClick={() => {
                                      setMediasModalOpen(true);
                                      setSelectedNode(node.id);
                                    }}
                                  />

                                  {/* <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      handleUpdateNodeUrl(e, node.id);
                                    }}
                                  /> */}
                                </Button>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <IconButton onClick={() => handleDeleteNode(node.id)}> */}
                    <Tooltip title="Excluir nó">
                      <IconButton
                        onClick={() => {
                          setConfirmationFunction(
                            () => () => handleDeleteNode(node.id)
                          );
                          setConfirmationOpen(true);
                          setConfirmationTitle("Excluir nó?");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box style={{ width: "100%" }}>
                    {node.options?.map((option, index) => (
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "1em",
                          width: "100%",
                        }}
                      >
                        <Box
                          style={{
                            borderRadius: "50%",
                            fontSize: "0.7em",
                            marginLeft: "5em",
                            width: "2em",
                            height: "2em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #009688",
                            color: "#009688",
                            filter: "drop-shadow(0 0 0.15rem #009688)",
                            marginRight: "1em",
                          }}
                        >
                          {index + 1}
                        </Box>
                        <Tooltip title="Desabilitar/Habilitar opção">
                          <Switch
                            defaultChecked={!option.disabled}
                            onChange={async (e) => {
                              const updatedOption = await updateOption({
                                optionId: option?.id,
                                disabled: !e.target.checked,
                              });
                              const newNodes = selectedChatbot.nodes.map(
                                (n) => {
                                  if (n.id === node.id) {
                                    return {
                                      ...n,
                                      options: n.options.map((o) => {
                                        if (o.id === option.id) {
                                          return {
                                            ...updatedOption,
                                            ...o,
                                            // text: e.target.value,
                                          };
                                        }
                                        return o;
                                      }),
                                    };
                                  }
                                  return n;
                                }
                              );
                              setSelectedChatbot({
                                ...selectedChatbot,
                                nodes: newNodes,
                              });
                            }}
                          />
                        </Tooltip>
                        <TextField
                          label="Opção"
                          // defaultValue={option.text}
                          defaultValue={option?.text}
                          onBlur={async (e) => {
                            const updatedOption = await updateOption({
                              text: e.target.value,
                              optionId: option?.id,
                            });
                            const newNodes = selectedChatbot.nodes.map((n) => {
                              if (n.id === node.id) {
                                return {
                                  ...n,
                                  options: n.options.map((o) => {
                                    if (o.id === option.id) {
                                      return {
                                        ...updatedOption,
                                        ...o,
                                        // text: e.target.value,
                                      };
                                    }
                                    return o;
                                  }),
                                };
                              }
                              return n;
                            });
                            setSelectedChatbot({
                              ...selectedChatbot,
                              nodes: newNodes,
                            });
                          }}
                          name="name"
                          margin="dense"
                          className={classes.textField}
                          style={{ width: "300px" }}
                        />
                        <ArrowRight />
                        <ActionChips
                          optionActions={option.action}
                          nodeId={node.id}
                          optionId={option.id}
                          selectedChatbot={selectedChatbot}
                          setSelectedChatbot={setSelectedChatbot}
                        />
                        <ArrowRight />
                        <TextField
                          select
                          label="Próximo node"
                          defaultValue={option.toNodeId}
                          onChange={async (e) => {
                            const updatedOption = await updateOption({
                              toNodeId: e.target.value,
                              optionId: option?.id,
                            });

                            const newNodes = selectedChatbot.nodes.map((n) => {
                              if (n.id === node.id) {
                                return {
                                  ...n,
                                  options: n.options.map((o) => {
                                    if (o.id === option.id) {
                                      return {
                                        ...o,
                                        ...updatedOption.toNodeId,
                                      };
                                    }
                                    return o;
                                  }),
                                };
                              }
                              return n;
                            });
                            setSelectedChatbot({
                              ...selectedChatbot,
                              nodes: newNodes,
                            });
                          }}
                          variant="outlined"
                          style={{ width: 300 }}
                        >
                          <MenuItem key={index} value={"empty"}>
                            Nenhum nó
                          </MenuItem>
                          {selectedChatbot?.nodes
                            ?.filter((node) => node.id !== selectedChatbot.id)
                            .map((node, index) => (
                              <MenuItem
                                key={index}
                                value={node.id}
                                style={{ maxWidth: "600px" }}
                              >
                                <div
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {`Node ${node.id} - ${node.header}`}
                                </div>
                              </MenuItem>
                            ))}
                        </TextField>
                        <Tooltip title="Excluir opção">
                          <IconButton
                            // onClick={() => handleDeleteOption(option.id)}
                            onClick={() => {
                              setConfirmationFunction(
                                () => () => handleDeleteOption(option.id)
                              );
                              setConfirmationOpen(true);
                              setConfirmationTitle("Excluir opção?");
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "0.7em" }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))}
                    <Box
                      key="add-option"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1em",
                      }}
                    >
                      {creatingLoading ? (
                        <CircularProgress
                          style={{ color: "#009688" }}
                          size={20}
                        />
                      ) : (
                        <Tooltip title="Adicionar opção">
                          <Box
                            style={{
                              borderRadius: "50%",
                              fontSize: "0.7em",
                              marginLeft: "5em",
                              width: "2em",
                              height: "2em",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #009688",
                              color: "#009688",
                              filter: "drop-shadow(0 0 0.15rem #009688)",
                              marginRight: "1em",
                              fointWeight: "bold",
                              cursor: "pointer",
                            }}
                            disabled={creatingLoading}
                            onClick={async () => {
                              if (creatingLoading) return;
                              const newOption = await createOption(node.id);
                              const newNodes = selectedChatbot.nodes.map(
                                (n) => {
                                  if (n.id === node.id && n.options) {
                                    return {
                                      ...n,
                                      options: [...n.options, newOption],
                                    };
                                  }
                                  if (n.id === node.id && !n.options) {
                                    return {
                                      ...n,
                                      options: [newOption],
                                    };
                                  }
                                  return n;
                                }
                              );
                              setSelectedChatbot({
                                ...selectedChatbot,
                                nodes: newNodes,
                              });
                            }}
                          >
                            +
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </ListItem>
            ))}
            <ListItem style={{ width: "100%" }}>
              <Paper style={{ width: "100%" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1em",
                  }}
                >
                  <Tooltip title="Adicionar nó">
                    <Box
                      style={{
                        background: "#009688",
                        borderRadius: "50%",
                        width: "2em",
                        height: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        color: "#fff",
                        filter: "drop-shadow(0 0 0.15rem #009688)",
                        marginRight: "1em",
                        cursor: "pointer",
                        // "&:hover": {
                        //   background: "#efefef",
                        // },
                      }}
                      onClick={async () => {
                        const newNode = await createNode();

                        if (selectedChatbot.nodes) {
                          setSelectedChatbot({
                            ...selectedChatbot,
                            nodes: [...selectedChatbot.nodes, newNode],
                          });
                        } else if (!selectedChatbot.nodes) {
                          setSelectedChatbot({
                            ...selectedChatbot,
                            nodes: [newNode],
                          });
                        }
                      }}
                    >
                      +
                    </Box>
                  </Tooltip>
                  <TextField
                    label="Cabeçalho"
                    defaultValue=""
                    name="name"
                    margin="dense"
                    disabled
                    className={classes.textField}
                  />
                </Box>
              </Paper>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {i18n.t("queueModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleClose}
            className={classes.btnWrapper}
          >
            {selectedChatbot ? "Salvar" : "Adicionar"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChatbotModal;
