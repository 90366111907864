import { Box, IconButton, makeStyles } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CloseIcon from "@material-ui/icons/Close";
import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  webcam: {
    margin: "10px",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",
  },

  actionsBox: {
    display: "flex",
    height: "10px",
    gap: "10px",
  },

  captureButton: {
    color: "white",
    backgroundColor: "#00A884",
    marginTop: "-40px",

    "&:hover": {
      backgroundColor: "#00796B",
    },
  },

  cancelButton: {
    color: "white",
    backgroundColor: "#FF3162",
    marginTop: "-40px",

    "&:hover": {
      backgroundColor: "#c4274b",
    },
  },
}));

const CustomWebcam = ({ setMedias, cancel }) => {
  const classes = useStyles();
  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null); // initialize it

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    // create a filename with the current date and time
    const fileName = `${new Date().getTime()}.png`;

    const file = await dataUrlToFile(imageSrc, fileName);
    setMedias([file]);

    setImgSrc(file);
  }, [webcamRef]);

  return (
    <Box className={classes.container}>
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam
          // height={600}
          width={600}
          ref={webcamRef}
          className={classes.webcam}
        />
      )}

      <Box className={classes.actionsBox}>
        <IconButton className={classes.cancelButton} onClick={cancel}>
          <CloseIcon />
        </IconButton>
        <IconButton className={classes.captureButton} onClick={capture}>
          <PhotoCameraIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomWebcam;
