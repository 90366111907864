import { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const connection = action.payload;

    return [...connection];
  }

  if (action.type === "UPDATE_WHATSAPPS") {
    const connection = action.payload;
    const whatsAppIndex = state.findIndex((s) => s.id === connection.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = connection;
      return [...state];
    }
    return [connection, ...state];
  }

  if (action.type === "UPDATE_SESSION") {
    const connection = action.payload;
    const connectionIndex = state.findIndex((s) => s.id === connection.id);

    if (connectionIndex !== -1) {
      state[connectionIndex].status = connection.status;
      state[connectionIndex].updatedAt = connection.updatedAt;
      state[connectionIndex].qrcode = connection.qrcode;
      state[connectionIndex].retries = connection.retries;
      return [...state];
    }
    return [...state];
  }

  if (action.type === "DELETE_WHATSAPPS") {
    const connectionId = action.payload;

    const connectionIndex = state.findIndex((s) => s.id === connectionId);
    if (connectionIndex !== -1) {
      state.splice(connectionIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useWhatsApps = () => {
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/whatsapp/");
        dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket({
      scope: "usewhatsapp",
      userId: user.id,
      component: "useWhatsApps",
    });

    socket.on("whatsapp", (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_WHATSAPPS",
          payload: data.session || data.connection,
        });
      }
    });

    socket.on("whatsapp", (data) => {
      if (data.action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.connectionId });
      }
    });

    socket.on("whatsappSession", (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return { whatsApps, loading };
};

export default useWhatsApps;
