import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import api from "../../services/api";

function ModalImageCors({ imageUrl, widthProp, heightProp }) {
  const [fetching, setFetching] = useState(true);
  const [blobUrl, setBlobUrl] = useState("");

  const useStyles = makeStyles((theme) => ({
    messageMedia: {
      objectFit: "cover",
      // objectFit: "contain",
      width: widthProp || 250,
      height: heightProp || 200,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingBottom: 6,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!imageUrl) return;

    try {
      const fetchImage = async () => {
        const { data, headers } = await api.get(imageUrl, {
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(
          new Blob([data], { type: headers["content-type"] })
        );
        setBlobUrl(url);
        setFetching(false);
      };
      fetchImage();
    } catch (err) {
      console.log(err);
    }
  }, [imageUrl]);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={fetching ? imageUrl : blobUrl}
      medium={fetching ? imageUrl : blobUrl}
      large={fetching ? imageUrl : blobUrl}
      showRotate="true"
      alt="image"
    />
  );
}

export default ModalImageCors;
