import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  stepBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    minHeight: "30vh",
    width: "100%",
  },

  simpleTextField: {
    width: "20%",
    marginBottom: "20px",
    overflow: "hidden",
  },

  nextButton: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
  },

  prevButton: {
    position: "absolute",
    left: "20px",
    bottom: "20px",
  },

  listItem: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const ModalStepThree = ({
  columns,
  contacts,
  handleSaveContacts,
  loading,
  setActiveStep,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.stepBox}>
      <Typography
        variant="body1"
        color="textSecondary"
        component="p"
        gutterBottom
      >
        Confirme o mapeamento dos campos com a amostra a seguir.
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Número</TableCell>
            {columns.email !== "" && <TableCell>Email</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell>{contact[columns.name]}</TableCell>
              <TableCell>{contact[columns.number]}</TableCell>
              {columns.email !== "" && (
                <TableCell>{contact[columns.email]}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        className={classes.prevButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setActiveStep(1);
        }}
      >
        Voltar
      </Button>

      <ButtonWithSpinner
        className={classes.nextButton}
        variant="contained"
        color="primary"
        disabled={columns.name === "" || columns.number === ""}
        onClick={() => {
          handleSaveContacts();
        }}
        loading={loading}
      >
        Continuar
      </ButtonWithSpinner>
    </Box>
  );
};

export default ModalStepThree;
