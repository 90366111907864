import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge, Box, withStyles } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import { AlternateEmail } from "@material-ui/icons";
import NewTicketModal from "../NewTicketModal";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  contactAvatar: {
    width: 40,
    height: 40,
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      color: "#fff",
      fontSize: "2em",
    },
  },

  contactCard: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
}));

const ContactCard = ({ contact }) => {
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <NewTicketModal
        initialContact={contact}
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
      <div
        className={classes.contactCard}
        onClick={() => {
          setNewTicketModalOpen(true);
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <>
              {contact?.channel === "whatsapp" && (
                <SmallAvatar
                  src={require("../../assets/icons/whatsapp.svg")}
                  style={{ backgroundColor: "#fff" }}
                />
              )}
              {contact?.channel === "telegram" && (
                <SmallAvatar
                  src={require("../../assets/icons/telegram.svg")}
                  style={{ backgroundColor: "#fff" }}
                />
              )}
              {contact?.channel === "instagram" && (
                <SmallAvatar
                  src={require("../../assets/icons/instagram.svg")}
                  style={{ backgroundColor: "#fff" }}
                />
              )}
              {contact?.channel === "facebook" && (
                <SmallAvatar
                  src={require("../../assets/icons/facebook.svg")}
                  style={{ backgroundColor: "#fff" }}
                />
              )}
              {contact?.channel === "whatsappbusiness" && (
                <SmallAvatar
                  src={require("../../assets/icons/wppbusiness.svg")}
                  style={{ backgroundColor: "#fff" }}
                />
              )}
              {contact?.channel === "email" && (
                <AlternateEmail
                  fontSize="small"
                  style={{ backgroundColor: "#fff", borderRadius: "50%" }}
                  color="primary"
                />
              )}
            </>
          }
        >
          {/* <Avatar
              src={
                ticket?.contact?.profilePicUrl ||
                (ticket?.contact?.isGroup?.toString() === "true" ? (
                  <PersonIcon />
                ) : (
                  <GroupIcon />
                ))
              }
            /> */}

          {contact?.profilePicUrl ? (
            <Avatar src={contact?.profilePicUrl} />
          ) : contact?.isGroup ? (
            <Box className={classes.contactAvatar}>
              <GroupIcon />
            </Box>
          ) : (
            <Box className={classes.contactAvatar}>
              <PersonIcon />
            </Box>
          )}
        </Badge>
        <div className="contact-card__name">{contact?.name}</div>
      </div>
    </>
  );
};

export default ContactCard;
