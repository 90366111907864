import React, { useContext, useEffect, useReducer, useState } from "react";

import { Box, Menu, MenuItem, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ChatIcon from "@material-ui/icons/Chat";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal";
import ContactModal from "../../components/ContactModal";
import ContactsImportModal from "../../components/ContactsImportModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import NewTicketModal from "../../components/NewTicketModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import { i18n } from "../../translate/i18n";
import ExportingModal from "./ExportingModal";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
  },
}))(Avatar);

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    }
    return [contact, ...state];
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

function Contacts() {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [exportCount, setExportCount] = useState(0);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [importContactsModal, setImportContactsModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket({
      scope: "contacts",
      userId: user.id,
      component: "Contacts",
    });

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleSaveTicket = async (contact) => {
    if (!contact) return;
    setSelectedContact(contact);
    setLoading(true);

    setNewTicketModalOpen(true);
    setLoading(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const exportContacts = async () => {
    setExportLoading(true);
    setExportModalOpen(true);

    try {
      const {
        data: { contacts, count },
      } = await api.get("/contacts-export");

      const csv = contacts.map((contact) => {
        if (contact.name) {
          let row = "";
          row += `${contact.name},${contact.number},${contact.email || ""}`;

          if (contact.tags.length > 0) {
            contact.tags.forEach((tag) => {
              row += `,${tag.name}`;
            });
          }

          return row;
        }
      });

      const csvString = `name,number,email\n${csv.join("\n")}`;

      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        `contatos-${new Date().toLocaleDateString("pt-BR")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportCount(count);
      setExportLoading(false);
    } catch (err) {
      toastError(err);
      setExportLoading(false);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ExportingModal
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        onConfirm={() => setExportModalOpen(false)}
        exportingLoading={exportLoading}
        exportCount={exportCount}
      />

      <ContactsImportModal
        open={importContactsModal}
        onClose={() => setImportContactsModal(false)}
      />
      <NewTicketModal
        initialContact={selectedContact}
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
              }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          {/* <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  user.profile === "admin" && setConfirmOpen(true);
                }}
              >
                {i18n.t("contacts.buttons.import")}
              </Button>
            )}
          /> */}

          {
            // open a menu with a button to import contacts from a file
          }

          <Button
            variant="contained"
            color="primary"
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          >
            {i18n.t("contacts.buttons.import")}
          </Button>

          <Button variant="contained" color="primary" onClick={exportContacts}>
            {i18n.t("contacts.buttons.export")}
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{
              marginTop: "40px",
            }}
          >
            <MenuItem
              onClick={() => {
                setMenuAnchorEl(null);
                setImportContactsModal(true);
              }}
            >
              Importar via CSV
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setMenuAnchorEl(null);
                user.profile === "admin" && setConfirmOpen(true);
              }}
            >
              Importar via whatsapp
            </MenuItem>
          </Menu>

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.company")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <>
                          {contact.channel === "whatsapp" && (
                            <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                          )}
                          {contact.channel === "telegram" && (
                            <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                          )}
                          {contact.channel === "instagram" && (
                            <SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
                          )}
                          {contact.channel === "facebook" && (
                            <SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
                          )}
                          {contact.channel === "whatsappbusiness" && (
                            <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                          )}
                        </>
                      }
                    >
                      <Avatar src={contact.profilePicUrl} />
                    </Badge>
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.company}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <IconButton
                        size="small"
                        onClick={() => handleSaveTicket(contact)}
                      >
                        <ChatIcon color="secondary" />
                        {/* <ContactCreateChatBtn
                          contact={contact}
                          Component={ChatIcon}
                        /> */}
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => hadleEditContact(contact.id)}
                      >
                        <EditIcon color="secondary" />
                      </IconButton>
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              user.profile === "admin" &&
                                (() => {
                                  setConfirmOpen(true);
                                  setDeletingContact(contact);
                                })();
                            }}
                          >
                            <DeleteOutlineIcon color="secondary" />
                          </IconButton>
                        )}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}

export default Contacts;
