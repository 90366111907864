import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { i18n } from '../../translate/i18n';

function PermissionModal({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{i18n.t('permissionModal.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography>{i18n.t('permissionModal.content')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClose(false)}
          color="default"
        >
          {i18n.t('confirmationModal.buttons.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PermissionModal;
