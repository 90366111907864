const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Welcome!",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
          recover: "I forgot my password",
        },
      },
      recoverPassword: {
        title: "Forgot your password?",
        subTitle:
          "No problems! Just enter your email and we'll send you login instructions.",
        form: {
          email: "Enter email",
        },
        buttons: {
          submit: "Recover Password",
          return: "Go back",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        epoch: {
          buttons: {
            today: "Today",
            week: "Week",
            month: "Month",
            year: "Year",
          },
        },
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
          attendantsChart: {
            title: "Active calls by attendants",
            sideTitle: "Calls",
          },
          progressBar: {
            callsByDepartment: "Calls by department",
            callsByAttendant: "Attendant Calls",
            ratingsByAttendant: "Ratings by Attendant",
          },
        },
        departments: {
          title: "Departments",
        },
        messages: {
          inAttendance: {
            title: "In Service",
          },
          waiting: {
            title: "Waiting",
          },
          closed: {
            title: "Closed",
          },
          connection: {
            title: "Connections",
          },
          waitingTime: {
            title: "Average waiting time",
          },
          serviceTime: {
            title: "Average service time",
          },
          averageRating: {
            title: "Average rating",
          },
          contacts: {
            title: "Contacts",
          },
          messages: {
            title: "Messages",
          },
          reviews: {
            title: "Reviews",
          },
        },
        infoCard: {
          ticketsToday: "Number of Tickets being handled.",
          awaitingService: "Number of Tickets awaiting service.",
          ticketsSolved: "Number of Tickets resolved.",
          connections: "Number of connected channels.",
          waitingTime: "Average time Tickets wait to be served.",
          serviceTime: "Average time in which operators perform the service.",
          averageRating: "Average rating of service evaluation.",
          contactNumber: "Total number of Contacts.",
          messagesNumber: "Total number of messages exchanged.",
          reviews: "Total number of reviews received.",
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add Connection",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
          restart: "Restart Backend",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
          channel: "Channel",
        },
      },
      permissionModal: {
        title: "Action unauthorized",
        content: "You don't have permission to perform this action.",
      },
      whatsappModal: {
        title: {
          add: "Add Connection",
          edit: "Edit Connection",
        },
        form: {
          name: "Connection name",
          default: "Default",
          channel: "Channel",
          farewellMessage: "Farewell message",
          whatsapp: "WhatsApp",
          facebook: "Facebook",
          telegram: "Telegram",
          instagram: "Instagram",
          whatsappcloud: "WhatsApp API",
          connectionInfo: "Connection Info",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        title: "To use Notificame:",
        firstPoint: "Open WhatsApp on your mobile.",
        secondPoint: {
          partOne: "Tap on ",
          moreOptions: "More Options ",
          partTwo: " or ",
          configurations: "Settings ",
          partThree: "and select Connected Devices.",
        },
        thirdPoint: "Tap Connect a device.",
        fourthPoint:
          "Point your cell phone at this screen to capture the code.",
        message: "Read QRCode to start the sessions",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          export: "Export Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          company: "Company",
          actions: "Actions",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          company: "Company",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        notification: {
          title: "Sector saved successfully!",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
          startWork: "Opening",
          endWork: "Close",
          absenceMessage: "Absence message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          whatsapp: "Default Connection",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
        noChatDescription:
          "Send and receive messages without having to keep your phone connected to the internet",
        noChatDescriptionText:
          "Use NotificaMe on as many computers as you want and a cell phone at the same time.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      templateOnlyBar: {
        warningBefore:
          "You can only respond to this conversation using a message template due to ",
        warningAfter: "24-hour message window restrictions",
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        connectionTitle: "Connection that is currently being used.",
        noTicketsMessage: "No tickets found with this status or search term.",
        items: {
          queueless: "Without Sector",
          accept: "Accept",
          spy: "Spy",
          close: "Close",
          reopen: "Reopen",
        },
        buttons: {
          accept: "Reply",
          acceptBeforeBot: "Accept",
          start: "start",
          cancel: "Cancel",
        },
        acceptModal: {
          title: "Accept Chat",
          queue: "Select sector",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Conversations",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          tags: "Tags",
          schedules: "Schedules",
          administration: "Administration",
          users: "Users",
          settings: "Settings",
          api: "API usage",
          apidocs: "Documentation",
          apititle: "API",
          apikey: "API Key",
          token: "Token",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        notifications: {
          queueDeleted: "The sector has been deleted.",
        },
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
          startWork: "Opening",
          endWork: "Close",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      templates: {
        title: "Templates",
        table: {
          type: "Type",
          status: "Status",
          name: "Name",
          email: "Email",
          category: "Category",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
          header: "Cabezazo",
          body: "Cuerpo",
          footer: "Pie de página",
          statuses: {
            APPROVED: "Approved",
            PENDING: "Pending",
            REJECTED: "Rejected",
          },
          types: {
            UTILITY: "Utility",
            MARKETING: "Marketing",
            AUTHENTICATION: "Authentication",
          },
        },
        buttons: {
          add: "Add template",
        },
        toasts: {
          deleted: "Template deleted sucessfully.",
        },
        templateModal: {
          title: {
            create: "Add template",
            update: "Update template",
          },
          connection: "Connection",
          name: "Name",
          header: "Header",
          body: "Body",
          footer: "Footer",
          buttons: {
            create: "Create",
            update: "Update",
            cancel: "Cancel",
          },
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "All template data will be lost.",
        },
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this tag?",
          deleteMessage: "This action cannot be reversed.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Tag Records",
          actions: "Actions",
          contacts: "Contacts",
        },
        buttons: {
          add: "Add Tag",
        },
        toasts: {
          deleted: "Tag deleted successfully.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      timeCreateNewTicket: {
        name: "Create new ticket after",
        note: "Select the time it will take to open a new ticket if the customer contacts you again",
        options: {
          10: "10 seconds",
          30: "30 seconds",
          60: "1 minute",
          300: "5 minutes",
          1800: "30 minutes",
          3600: "1 hour",
          7200: "2 hours",
          21600: "6 hours",
          43200: "12 hours",
          86400: "24 hours",
          604800: "7 days",
          1296000: "15 days",
          2592000: "30 days",
        },
      },
      CheckMsgIsGroup: {
        name: "Ignore Groups Msgs",
        options: {
          enabled: "Enabled",
          disabled: "Disabled",
        },
      },
      sideMenu: {
        name: "Home Side Menu",
        note: "If enabled, the side menu will start closed",
        options: {
          enabled: "Open",
          disabled: "Closed",
        },
      },
      closeTicketApi: {
        name: "Close Ticket sent API",
        note: "Automatically closes ticket when submitted via API",
        options: {
          enabled: "Enabled",
          disabled: "Disabled",
        },
      },
      darkMode: {
        name: "Enable Dark Mode",
        note: "Switch between light mode and dark mode",
        options: {
          enabled: "Enabled",
          disabled: "Disabled",
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
            solverWithoutGoodbye: "Solve without goodbye",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      searchDrawer: {
        header: "Search message",
        searchLabel: "Search",
        yesterday: "yesterday",
        locale: "enUS",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      languages: {
        undefined: "Language",
        "pt-BR": "Portuguese",
        es: "Spanish",
        en: "English",
      },
      tagModal: {
        title: {
          add: "Create Tag",
          edit: "Edit Tag",
        },
        form: {
          name: "Name",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Tag saved successfully.",
      },
      backendErrors: {
        TICKET_NOT_FOUND: "Ticket not found.",
        ERR_TAG_NAME_ALREADY_EXISTS: "A tag with that name already exists.",
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_BULK_INVALID_CONTACT: "All contacts are not valid Whatsapp numbers or there is a problem with the connection.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG: "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED: "Greeting message is required if there is more than one queue.",
        ERR_USER_CREATION_COUNT: "User limit reached, to change please contact support.",
        ERR_CONNECTION_CREATION_COUNT: "Connection limit reached, to change contact support.",
        BULK_HAS_NO_CONTACTS_TO_SEND: "There are no contacts to send bulk message."
      },
    },
  },
};

export { messages };
