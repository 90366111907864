import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { CircularProgress, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    backgroundColor: '#009688',
    color: '#FFF',
    padding: '0.5em',
    // on hover this should not change color
    '&:hover': {
      backgroundColor: '#009688',
      color: '#FFF',
    },
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ButtonWithSpinner({ loading, children, ...rest }) {
  const classes = useStyles();

  return (
    <Button className={classes.button} disabled={loading} {...rest}>
      {children}
      {loading && (
      <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
}

export default ButtonWithSpinner;
