import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Emoji4 from "./Emoji4";
import Emoji5 from "./Emoji5";
import Emoji3 from "./Emoji3";
import Emoji2 from "./Emoji2";
import Emoji1 from "./Emoji1";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  mainBoxContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F5F8",
  },

  raitingPaper: {
    width: "400px",
    maxWidth: "95%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.10)",
    gap: "15px",
  },

  emojiBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%",
    gap: "10px",

    "& svg": {
      transform: "scale(1.5)",
    },
  },

  emojiContent: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },

  emojiBtn: {
    borderRadius: "50%",
    filter: "grayscale(100%)",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",

    "&:hover": {
      filter: "grayscale(0%)",
      transition: "all 0.2s ease-in-out",
      transform: "scale(1.2)",
    },
  },

  emojiBtnActive: {
    filter: "grayscale(0%)",
    transition: "all 0.2s ease-in-out",
    transform: "scale(1.2)",
  },

  feedbackInput: {
    outline: "none",
    // on focus outline none
    "&:focus": {
      outline: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00A884",
      },
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #00A884",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#00A884",
    },
  },

  submitBtn: {
    backgroundColor: "#00A884",
    color: "#fff",

    "&:hover": {
      backgroundColor: "#007f63",
    },

    marginLeft: "auto",
  },

  copyright: {
    marginTop: "20px",
    color: "#999",
    opacity: "0.8",
    fontSize: "12px",
  },
}));

const emojis = [
  {
    emoji: <Emoji1 />,
    caption: "Muito insatisfeito",
  },
  { emoji: <Emoji2 />, caption: "Insatisfeito" },
  { emoji: <Emoji3 />, caption: "Neutro" },
  { emoji: <Emoji4 />, caption: "Satisfeito" },
  {
    emoji: <Emoji5 />,
    caption: "Muito satisfeito",
  },
];
const Rating = () => {
  const classes = useStyles();
  const [alreadyAvaliated, setAlreadyAvaliated] = useState(false);
  const [emojiSelected, setEmojiSelected] = useState(-1);
  const [ratingDescription, setRatingDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { uuid } = useParams();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const { data } = await api.get(`/rating/${uuid}`);

        setAlreadyAvaliated(data);
        setIsLoading(false);
      } catch (error) {
        setAlreadyAvaliated(true);
        toastError(error);
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await api.post(`/rating`, {
        uuid,
        rating: emojiSelected,
        ratingDescription,
      });

      setAlreadyAvaliated(true);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Grid container component="main">
      <Box className={classes.mainBoxContainer}>
        <Paper className={classes.raitingPaper}>
          {isLoading && (
            <>
              <CircularProgress style={{ margin: "auto", color: "#00A884" }} />
            </>
          )}

          {!isLoading && alreadyAvaliated && (
            <>
              <Typography variant="h6" align="center">
                Atendimento avaliado!
              </Typography>
              <Typography variant="body2" align="center">
                Nossa equipe agradece o seu feedback.
              </Typography>
            </>
          )}

          {!isLoading && !alreadyAvaliated && (
            <>
              <Typography variant="body2" align="left">
                Como você avalia o atendimento?
              </Typography>
              <Box className={classes.emojiBox}>
                {emojis.map((emoji, index) => (
                  <Box className={classes.emojiContent}>
                    <span
                      key={index}
                      className={
                        emojiSelected === index + 1
                          ? classes.emojiBtnActive
                          : classes.emojiBtn
                      }
                      onClick={() => {
                        !alreadyAvaliated && setEmojiSelected(index + 1);
                      }}
                    >
                      {emoji.emoji}
                    </span>
                    <Typography
                      variant="caption"
                      style={{ textAlign: "center" }}
                    >
                      {emoji.caption}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Typography variant="body2">
                Você tem alguma sugestão para compartilhar?
              </Typography>

              <TextField
                id="outlined-multiline-static"
                label="Seu feedback (opcional)"
                multiline
                minRows={4}
                maxRows={4}
                variant="outlined"
                disabled={alreadyAvaliated}
                className={classes.feedbackInput}
                value={ratingDescription}
                onChange={(e) => setRatingDescription(e.target.value)}
              />

              <Button
                variant="contained"
                size="small"
                className={classes.submitBtn}
                disabled={emojiSelected === -1 || alreadyAvaliated}
                onClick={handleSubmit}
              >
                Enviar feedback
              </Button>

              <Typography
                variant="body2"
                align="center"
                className={classes.copyright}
              >
                Desenvolvido por Gold Software Informática Ltda.
              </Typography>
            </>
          )}
        </Paper>
      </Box>
    </Grid>
  );
};

export default Rating;
