import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { Box, Button, Divider } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { TicketModalUserQueueCon } from "../TicketModalUserQueueCon";
import { MultiVcardContacts } from "../MultiVcardContacts";

function VcardPreview({ contacts, contact, numbers, mediaType }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [multiVcardModalOpen, setMultiVcardModalOpen] = useState(false);

  const modalClose = () => {
    setModalOpen(false);
  };

  const multiVcardModalClose = () => {
    setMultiVcardModalOpen(false);
  };

  const [selectedContact, setContact] = useState({
    contacts: [],
    name: "",
    number: 0,
    profilePicUrl: "",
  });

  useEffect(() => {
    if (mediaType !== "vcard") return;

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const contactObj = {
            name: contact,
            number: numbers?.replace(/\D/g, ""),
            email: "",
          };
          const { data } = await api.post("/contact", contactObj);
          setContact(data);
        } catch (err) {
          console.log(err);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers, mediaType]);

  const handleNewChat = async () => {
    setModalOpen(true);
  };

  const vcardBody = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Grid item xs={2}>
            <Avatar
              style={{ width: "45px", height: "45px", margin: "12px" }}
              src={selectedContact.profilePicUrl}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{ marginTop: "12px", marginLeft: "10px" }}
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {selectedContact.name}
            </Typography>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Divider />
          <Button
            fullWidth
            color="primary"
            onClick={handleNewChat}
            disabled={!selectedContact.number}
          >
            Conversar
          </Button>
        </Grid>
      </>
    );
  };

  const multiVcardBody = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Grid item xs={2}>
            <Box
              style={{
                display: "flex",
                margin: "12px",
              }}
            >
              {contacts.map((contact, index) => {
                if (index > 2) return;
                return (
                  <Avatar
                    style={{
                      width: "45px",
                      height: "45px",
                      marginRight: "-38px",
                      border: "1px solid #fff",
                      zIndex: `${contacts.length - index}`,
                    }}
                    src={contact.profilePicUrl || ""}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{ marginTop: "12px", marginLeft: "10px" }}
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {`${contacts[0].name} e outros ${contacts.length - 1} contatos`}
            </Typography>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Divider />
          <Button
            fullWidth
            color="primary"
            onClick={() => setMultiVcardModalOpen(true)}
          >
            Ver todos
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <>
      <TicketModalUserQueueCon
        selectedContact={selectedContact}
        modalOpen={modalOpen}
        onClose={modalClose}
      />

      <MultiVcardContacts
        contacts={contacts}
        modalOpen={multiVcardModalOpen}
        onClose={multiVcardModalClose}
      />

      <div
        style={{
          minWidth: "400px",
        }}
      >
        {mediaType === "vcard" && vcardBody()}
        {mediaType === "multi_vcard" && multiVcardBody()}
        <Grid container spacing={1}></Grid>
      </div>
    </>
  );
}

export default VcardPreview;
