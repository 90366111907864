import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Link,
} from '@material-ui/core';

import { makeStyles, styled } from '@material-ui/core/styles';
import ForgotPassword from '../../components/lottieAnimation/recoverPassword';
import { i18n } from '../../translate/i18n';

import { system } from '../../../package.json';
import logo from '../../assets/logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      ©
      {' '}
      {new Date().getFullYear()}
      {' - '}
      <Link color="inherit" href="https://notificame.com.br">
        {system.name}
        {' '}
        - v
        {system.version}
      </Link>
      .
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
    color: '#333333',
    [theme.breakpoints.down(510)]: {
      width: '275px',
    },
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-start',
    [theme.breakpoints.down(510)]: {
      width: '275px',
    },
  },
  titleRecover: {
    fontSize: '25px',
    marginBottom: '35px',
    color: '#333333',
    [theme.breakpoints.down(510)]: {
      fontSize: '18px',
    },
  },
  subTitleRecover: {
    fontSize: '25px',
    marginBottom: '35px',
    color: '#333333',
    width: '',
    [theme.breakpoints.down(510)]: {
      fontSize: '25px',
    },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    flexWrap: 'wrap',
    borderRadius: '5px',
  },
  mainBoxContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f6f7',
  },
  separatorDiv: {
    height: '550px',
    width: '1px',
    backgroundColor: 'lightgray',
    marginLeft: '50px',
    marginRight: '25px',
    [theme.breakpoints.down(1026)]: {
      marginLeft: '0px',
    },
    [theme.breakpoints.down(1175)]: {
      marginLeft: '0px',
      marginRight: '0px',
    },
    [theme.breakpoints.down(951)]: {
      display: 'none',
    },
  },
  formContainer: {
    margin: '50px',
  },
  inputEmail: {
    [theme.breakpoints.down(510)]: {
      width: '280px',
    },
  },
  inputPassword: {
    width: '350px',
    [theme.breakpoints.down(510)]: {
      width: '280px',
    },
  },
  logo: {
    width: '200px',
    [theme.breakpoints.down(510)]: {
      width: '180px',
    },
  },
  imageContainer: {
    marginLeft: '60px',
    marginRight: '30px',
    [theme.breakpoints.down(1266)]: {
      marginLeft: '0px',
      marginRight: '0px',
    },
    [theme.breakpoints.down(951)]: {
      display: 'none',
    },
  },
  animationRecoverContainer: {
    width: '550px',
    [theme.breakpoints.down(1175)]: {
      width: '450px',
    },
  },
  containerButtons: {
    width: '100%',
    [theme.breakpoints.down(510)]: {
      width: '275px',
      marginLeft: '-1px',
    },
  },
}));

const InputEmail = styled(TextField)({
  '& label.Mui-focused': {
    color: '#9e9e9e',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#9e9e9e',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'lightgray',
    },
    '&:hover fieldset': {
      borderColor: '#9e9e9e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#9e9e9e',
    },
  },
});

function RecoverPassword() {
  const classes = useStyles();

  const [user, setUser] = useState({ email: '', password: '' });

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Grid container component="main">
      <Box className={classes.mainBoxContainer}>
        <CssBaseline />
        <div className={classes.mainContainer}>
          <Box className={classes.imageContainer}>
            <div className={classes.animationRecoverContainer}>
              <ForgotPassword />
            </div>
          </Box>
          <div className={classes.separatorDiv} />
          <Grid className={classes.formContainer}>
            <div className={classes.paper} data-testid="101">
              <img alt="logo" src={logo} className={classes.logo} />
              <div className={classes.containerTitle}>
                <div style={{ marginBottom: '15px' }}>
                  <span className={classes.titleRecover}>
                    {i18n.t('recoverPassword.title')}
                  </span>
                </div>
                <div style={{ marginBottom: '25px' }}>
                  <span>{i18n.t('recoverPassword.subTitle')}</span>
                </div>
              </div>
              <form className={classes.form} noValidate onSubmit={handlSubmit}>
                <InputEmail
                  variant="outlined"
                  label={i18n.t('recoverPassword.form.email')}
                  id="email"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  value={user.email}
                  onChange={handleChangeInput}
                  autoComplete="email"
                  autoFocus
                  className={classes.inputEmail}
                />
                <div className={classes.containerButtons}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                  >
                    {i18n.t('recoverPassword.buttons.submit')}
                  </Button>
                  <Grid>
                    <Link
                      href="#"
                      variant="body2"
                      component={RouterLink}
                      to="/login"
                    >
                      {i18n.t('recoverPassword.buttons.return')}
                    </Link>
                  </Grid>
                </div>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Grid>
        </div>
      </Box>
    </Grid>
  );
}

export default RecoverPassword;
