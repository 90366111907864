import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { Box } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

function HasTicketModal({ open, onClose, hasTicketId, onConfirm }) {
  const [hasTicket, setHasTicket] = useState(null);
  const { user } = useContext(AuthContext);

  const userHasQueue = () => {
    if (!hasTicket) {
      return false;
    }

    if (hasTicket?.isGroup) {
      return true;
    }

    const { queueId } = hasTicket;

    if (!user) {
      return false;
    }

    if (user.queues.length === 0) {
      return false;
    }

    if (user.queues.find((queue) => Number(queue.id) === Number(queueId))) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!hasTicketId) return;

    (async () => {
      try {
        const { data } = await api.get(`/tickets/${hasTicketId}`);
        setHasTicket(data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [hasTicketId]);

  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        O contato já possui ticket aberto
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Protocolo:
          </Typography>
          <Typography>{`${hasTicket?.id}`}</Typography>
        </Box>

        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Contato:
          </Typography>
          <Typography>{`${hasTicket?.contact?.name || ""} - ${
            hasTicket?.contact?.number
          }`}</Typography>
        </Box>

        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Status:
          </Typography>
          {hasTicket?.status === "open" && <Typography>Aberto</Typography>}
          {hasTicket?.status === "pending" && (
            <Typography>Aguardando</Typography>
          )}
          {hasTicket?.status === "closed" && (
            <Typography>Finalizado</Typography>
          )}
        </Box>

        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Setor:
          </Typography>
          <Typography>{`${hasTicket?.queue?.name || ""}`}</Typography>
        </Box>

        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Atendente:
          </Typography>
          <Typography>{`${hasTicket?.user?.name || ""}`}</Typography>
        </Box>

        <Box
          display="flex"
          style={{
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Criado em:
          </Typography>
          {hasTicket?.createdAt && (
            <Typography>
              {`${format(parseISO(hasTicket?.createdAt), "dd/MM/yyyy")}`}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClose(false)}
          color="default"
        >
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose(false);
            // push to /tickets/:id
            history.push(`/tickets/${hasTicket?.id}`);
            onConfirm();
          }}
          color="secondary"
          disabled={!userHasQueue()}
        >
          Entrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HasTicketModal;
