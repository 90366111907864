import React, { useContext, useEffect, useState } from "react";

import {
  Avatar,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import HasTicketModal from "../HasTicketModal";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: "100%",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  maxWidth: {
    width: "100%",
  },
  buttonColorError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const filter = createFilterOptions({
  trim: true,
});

function NewTicketModal({ modalOpen, onClose, initialContact }) {
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [newContact, setNewContact] = useState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const classes = useStyles();
  const [hasTicketOpen, setHasTicketOpen] = useState(false);
  const [hasTicketId, setHasTicketId] = useState(null);

  useEffect(() => {
    if (whatsApps.length > 0 && user.whatsapp) {
      whatsApps.forEach((whatsapp) => {
        if (whatsapp.id === user.whatsapp.id) {
          setSelectedWhatsapp(whatsapp);
        }
      });
    }
  }, [modalOpen, user.whatsapp, whatsApps]);

  useEffect(() => {
    if (initialContact) {
      setSelectedContact(initialContact);
    }
  }, [initialContact]);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedContact(null);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId,
        userId: user.id,
        status: "open",
        queueId: selectedQueue,
        whatsappId: selectedWhatsapp.id,
        channel:
          selectedWhatsapp.channel === "whatsappcloud"
            ? "whatsappbusiness"
            : selectedWhatsapp.channel,
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      const errorMsg =
        (await err.response?.data?.message) ||
        (await err.response?.data?.error);
      const ticketId = await errorMsg?.match(/\d+/g)[0];

      if (ticketId) {
        setHasTicketOpen(true);
        setHasTicketId(await ticketId);
        setLoading(false);
        return;
      }
      setLoading(false);
      toastError(err);
    }
    handleClose();
  };

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    } else if (newValue?.name) {
      setNewContact({ name: newValue.name });
      setContactModalOpen(true);
    }
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  const handleAddNewContactTicket = (contact) => {
    handleSaveTicket(contact.id);
  };

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);

    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }

    return filtered;
  };

  const getOptionSerialized = (option) => {
    let optionLabel = option.name;

    if (option.number) {
      optionLabel += ` - ${option.number}`;
    }

    if (option.company) {
      optionLabel += ` (${option.company})`;
    }

    return optionLabel;
  }

  const renderOption = (option) => {
    if (!option.number && !option.company) {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }

    return getOptionSerialized(option);
  };

  const renderOptionLabel = (option) => {
    return getOptionSerialized(option);
  };

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
      />
      <HasTicketModal
        open={hasTicketOpen}
        onClose={setHasTicketOpen}
        hasTicketId={hasTicketId}
        onConfirm={() => {
          onClose();
        }}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </HasTicketModal>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("newTicketModal.title")}
        </DialogTitle>
        <FormControl>
          <DialogContent dividers style={{ width: 350 }}>
            <TextField
              as={Select}
              select
              // label={i18n.t("contactModal.form.name")}
              label="Conexão"
              name="channel"
              autoFocus
              defaultValue={selectedWhatsapp || ""}
              onChange={(e) => {
                setSelectedWhatsapp(e.target.value);
              }}
              // SelectProps={{
              //   renderValue: (value) => (
              //     <>
              //       {value === "whatsapp" && (
              //         <div style={{ display: "flex" }}>
              //           <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
              //           {/* {value} */}
              //         </div>
              //       )}
              //       {value === "telegram" && (
              //         <>
              //           <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
              //           {/* {value} */}
              //         </>
              //       )}
              //       {/* {value === "instagram" && (
              //         <SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
              //       )}
              //       {value === "facebook" && (
              //         <SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
              //       )} */}
              //       {value === "whatsappcloud" && (
              //         <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
              //         // {value}
              //       )}
              //     </>
              //   ),
              // }}
              // error={touched.name && Boolean(errors.name)}
              // helperText={touched.name && errors.name}
              variant="outlined"
              margin="dense"
              className={classes.textField}
            >
              {whatsApps
                .filter((wpp) =>
                  user.profile !== "admin" && user.whatsapp
                    ? wpp?.id === user?.whatsapp?.id
                    : wpp
                )
                .filter(
                  (whatsApp) =>
                    // whatsApp.channel !== "email" &&
                    whatsApp.channel !== "facebook" &&
                    whatsApp.channel !== "instagram"
                )
                .map((whatsApp) => (
                  <MenuItem key={whatsApp.id} value={whatsApp}>
                    {whatsApp.channel === "whatsapp" && (
                      <>
                        <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                        {whatsApp.name}
                      </>
                    )}
                    {whatsApp.channel === "telegram" && (
                      <>
                        <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                        {whatsApp.name}
                      </>
                    )}
                    {/* {whatsApp.channel === "instagram" && (
											<SmallAvatar src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
											)}
											{whatsApp.channel === "facebook" && (
												<SmallAvatar src="https://img.icons8.com/color/48/000000/facebook-new.png" />
											)} */}
                    {whatsApp.channel === "whatsappcloud" && (
                      <>
                        <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                        {whatsApp.name}
                      </>
                    )}
                    {whatsApp.channel === "email" && (
                      <>
                        {/* <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" /> */}
                        {`email: ${whatsApp.name}`}
                      </>
                    )}

                    {/* {whatsApp.name} */}
                  </MenuItem>
                ))}
            </TextField>

            <FormControl variant="outlined" className={classes.maxWidth}>
              <InputLabel>{i18n.t("ticketsList.acceptModal.queue")}</InputLabel>
              <Select
                autoHighlight
                required
                value={selectedQueue}
                className={classes.autoComplete}
                onChange={(e) => setSelectedQueue(e.target.value)}
                label={i18n.t("ticketsList.acceptModal.queue")}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {user.queues?.map((queue) => (
                  <MenuItem key={queue.id} value={queue.id}>
                    {queue.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!initialContact && (
              <Autocomplete
                options={options}
                loading={loading}
                style={{ width: 300 }}
                clearOnBlur
                autoHighlight
                freeSolo
                clearOnEscape
                getOptionLabel={renderOptionLabel}
                renderOption={renderOption}
                filterOptions={createAddContactOption}
                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("newTicketModal.fieldLabel")}
                    variant="outlined"
                    autoFocus
                    required
                    onChange={(e) => setSearchParam(e.target.value)}
                    onKeyPress={(e) => {
                      if (loading || !selectedContact) return;
                      if (e.key === "Enter") {
                        handleSaveTicket(selectedContact.id);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            <DialogContent />
          </DialogContent>
        </FormControl>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            // disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact || !selectedQueue || !selectedWhatsapp}
            onClick={() => handleSaveTicket(selectedContact.id)}
            color="primary"
            loading={loading}
          >
            {i18n.t("newTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewTicketModal;
