import React from "react";

function Emoji1() {
  return (
    <img
      src="/rating-1.png"
      alt="emoji1"
      style={{
        width: "30px",
      }}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="24"
    //   height="24"
    // >
    //   <defs>
    //     <style>{`.b { fill: #b72100; } .c { fill: #864e20; }`}</style>
    //   </defs>
    //   <rect
    //     x="1"
    //     y="1"
    //     width="22"
    //     height="22"
    //     rx="7.656"
    //     style={{ fill: "#ff3d3d" }}
    //   />
    //   <path
    //     className="b"
    //     d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344"
    //   />
    //   <path
    //     className="c"
    //     d="M9.3 7.173a3.027 3.027 0 0 0-.447.006 4.392 4.392 0 0 0-.873.163c-.142.043-.285.086-.425.138s-.271.123-.407.185a3.642 3.642 0 0 0-.391.218c-.125.084-.256.158-.373.251a4.837 4.837 0 0 0-1.217 1.31 3.714 3.714 0 0 0-.512 1.245.173.173 0 0 0 .286.157c.324-.283.626-.545.933-.8.406-.336.8-.66 1.218-.962.1-.083.209-.149.312-.226l.155-.116c.051-.04.108-.07.16-.108.1-.074.212-.144.315-.224l.003-.01c.108-.069.217-.14.325-.215.215-.162.449-.291.675-.46.115-.081.234-.159.353-.242a.173.173 0 0 0-.09-.31zM14.744 7.173h.005a3.029 3.029 0 0 1 .447.006 4.405 4.405 0 0 1 .873.163c.142.043.285.086.424.138s.272.123.408.185a3.642 3.642 0 0 1 .391.218c.124.084.255.158.373.251a4.837 4.837 0 0 1 1.217 1.31 3.735 3.735 0 0 1 .512 1.245.173.173 0 0 1-.286.157 49.394 49.394 0 0 0-.933-.8c-.406-.336-.8-.66-1.218-.962-.1-.083-.209-.149-.312-.226l-.155-.116c-.051-.04-.108-.07-.161-.108-.1-.074-.211-.144-.314-.224l-.009-.01a9.111 9.111 0 0 1-.325-.215c-.215-.162-.449-.291-.675-.46-.115-.081-.234-.159-.353-.242a.173.173 0 0 1 .091-.31zM14.512 13.366a5.943 5.943 0 0 0-5.024 0c-.592.369-.557-.694.753-.974A7.35 7.35 0 0 1 12 12.078a7.35 7.35 0 0 1 1.759.314c1.31.28 1.341 1.343.753.974z"
    //   />
    //   <path
    //     className="b"
    //     d="M13.074 14.269a2.542 2.542 0 0 0-2.148 0c-.253.158-.238-.3.322-.416a3.144 3.144 0 0 1 .752-.134 3.144 3.144 0 0 1 .752.134c.56.12.575.574.322.416z"
    //   />
    // </svg>
  );
}

export default Emoji1;
