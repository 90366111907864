import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  endpointOption: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
}));

function HubConnect() {
  const classes = useStyles();
  const [endPoints, setEndpoints] = useState();

  useEffect(() => {
    (async () => {
      const response = await fetch(
        "https://hub.notificame.com.br/pages/?account=80ad394a-7032-11ee-a8c5-02e635721ec1"
      );
      const { data } = await response.json();
      setEndpoints(data);
    })();
  }, []);

  console.log({ endPoints });

  return (
    <div className={classes.root}>
      <h1>HubConnect</h1>

      <Container>
        {endPoints &&
          endPoints?.map((endpoint, index) => (
            <Box className={classes.endpointOption} key={index}>
              <Button size="small" variant="contained" color="primary">
                INTEGRAR
              </Button>
              <Typography>{endpoint.category}</Typography>
            </Box>
          ))}
      </Container>
    </div>
  );
}

export default HubConnect;
