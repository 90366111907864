import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Backdrop,
  Fade,
  Typography,
  Input,
  Divider,
  Button,
  CircularProgress,
  Select,
  ListItem,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ModalActionBtns from "../ModalActionBtns";
import ModalTitle from "../ModalTitle";
import api from "../../services/api";
import TemplateRadio from "./TemplateRadio";
import SearchIcon from "@material-ui/icons/Search";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import ModalImageChatbot from "../ModalImageChatbot";

const boxStyle = {
  boxSizing: "border-box",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  minHeight: "300px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxWidth: "80%",
  padding: "2% 2% 0.5% 2%",
  borderRadius: "10px",
  overflow: "hidden",
};

export default function WabaSendTemplateModal({
  open,
  closeModalFn,
  ticketId,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateRaw, setSelectedTemplateRaw] = useState(null);
  const [errorHappened, setErrorHappened] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [customTemplates, setCustomTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templateParams, setTemplateParams] = useState([]);
  const [templateParamsLength, setTemplateParamsLength] = useState(0);
  const [customTemplateId, setCustomTemplateId] = useState("");
  const [mediaPath, setMediaPath] = useState(null);

  const handleUpdateMedia = async (e) => {
    if (!e.target.files) {
      return;
    }

    const img = e.target.files[0];
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", img);
    await api.post("/chatbot/node-upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const fileName = img.name;

    const url = `${process.env.REACT_APP_BACKEND_URL}/public/${fileName}`;

    setMediaPath(url);
    // setCategory("MARKETING");
  };

  useEffect(() => {
    setMediaPath(null);
    setTemplateParams([]);
  }, [selectedTemplate]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/template/`);
        setCustomTemplates(data);
      } catch (error) {
        console.log(error);
      }
    })();

    const getTemplates = async () => {
      try {
        const response = await api.get(`/WhatsAppMeta/template/${ticketId}`);
        setTemplates(
          response.data.data.filter((template) =>
            template.name.includes("notificame")
          )
        );
      } catch (error) {
        setTemplates([]);
      }
    };
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const getFilteredTemplates = () => {
    const filteredTemplates = templates
      .filter((t) => t.status === "APPROVED")
      .filter(
        (template) =>
          template.name.includes(searchValue) ||
          template.components.some(
            (component) =>
              component.text &&
              component.text.toLowerCase().includes(searchValue.toLowerCase())
          )
      );

    const combinedTemplates = [...filteredTemplates, ...customTemplates];

    return combinedTemplates;
  };

  const handleClose = () => {
    setErrorHappened(false);
    closeModalFn();
  };

  const sendTemplate = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(
        `/WhatsAppMeta/template/${ticketId}/${selectedTemplate}`,
        {
          templateParams,
          customTemplateId,
          mediaUrl: mediaPath,
        }
        // {
        //   ticketId,
        // },
      );
      if (response.status === 201) {
        closeModalFn();
      } else {
        throw new Error(`Erro ${response.status} ao enviar template`);
      }
    } catch (error) {
      setErrorHappened(true);
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log({ selectedTemplate });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={boxStyle}>
          <ModalTitle
            title="Enviar template whatsapp business"
            closeModalFn={handleClose}
          />
          <Box
            align="center"
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ margin: "10px", padding: "10px", height: "100%" }}
            display="flex"
            justifyContent={"space-evenly"}
            maxHeight="60vh"
          >
            <Box
              sx={{ width: "100%" }}
              display="flex"
              flexDirection={"row"}
              alignItems="center"
            >
              <SearchIcon style={{ marginRight: "0.5em" }} />
              <Input
                placeholder="Procurar por título ou texto do template"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                fullWidth
                autoFocus
                style={{ margin: "1em 0", borderRadius: "10px" }}
              />
              <Button
                variant="primary"
                color="secondary"
                onClick={() => setSearchValue("")}
                style={{ marginLeft: "0.5em" }}
              >
                {" "}
                Limpar{" "}
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                overflow: "scroll",
              }}
            >
              <Divider />
              <TemplateRadio
                templates={getFilteredTemplates()}
                value={selectedTemplate}
                setValue={setSelectedTemplate}
                setSelectedTemplateRaw={setSelectedTemplateRaw}
                setCustomValue={setCustomTemplateId}
                setTemplateParamsLength={setTemplateParamsLength}
                setTemplateParams={setTemplateParams}
              />
            </Box>
          </Box>

          {console.log({ selectedTemplateRaw })}

          {selectedTemplateRaw && (
            <>
              <Typography variant="subtitle1" style={{ alignSelf: "center" }}>
                Parâmetros do template: {templateParamsLength}
              </Typography>
            </>
          )}

          {selectedTemplateRaw?.components[0].format === "VIDEO" && (
            <p>video</p>
          )}
          {selectedTemplateRaw?.components[0].format === "LOCATION" && (
            <p>localização</p>
          )}
          {selectedTemplateRaw?.components[0].format === "IMAGE" && (
            <p>imagem</p>
          )}
          {selectedTemplateRaw?.components[0].format === "DOCUMENT" && (
            <p>documento</p>
          )}

          {["VIDEO", "DOCUMENT", "IMAGE"].includes(
            selectedTemplateRaw?.components[0].format
          ) && (
            <TextField
              value={mediaPath}
              // disabled
              name="name"
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      marginBottom: "0.8em",
                    }}
                  >
                    {mediaPath ? (
                      <>
                        <Tooltip title={mediaPath}>
                          <ModalImageChatbot
                            imageUrl={mediaPath}
                            style={{ width: "10px" }}
                          />
                        </Tooltip>

                        <IconButton component="span">
                          <Tooltip title="Remover mídia">
                            <CancelIcon onClick={() => setMediaPath(null)} />
                          </Tooltip>
                        </IconButton>
                      </>
                    ) : (
                      <Tooltip title="Anexar imagem">
                        <Button component="label">
                          <AttachFileIcon />

                          <input
                            accept={
                              selectedTemplateRaw?.components[0].format ===
                              "IMAGE"
                                ? "image/png, image/jpeg, image/jpg"
                                : selectedTemplateRaw?.components[0].format ===
                                  "VIDEO"
                                ? "video/mp4"
                                : selectedTemplateRaw?.components[0].format ===
                                  "DOCUMENT"
                                ? "application/pdf"
                                : ""
                            }
                            type="file"
                            hidden
                            onChange={(e) => {
                              handleUpdateMedia(e);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}

          {
            // create a select for each template param
            selectedTemplate &&
              [...Array(templateParamsLength)].map((_, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ alignSelf: "center" }}
                    >{`{{${index + 1}}}-`}</Typography>
                    <Select
                      defaultValue={"text"}
                      style={{ margin: "1em 0", borderRadius: "10px" }}
                    >
                      <ListItem
                        value="text"
                        onClick={() => {
                          const newTemplateParams = [...templateParams];
                          newTemplateParams[index] = "";
                          setTemplateParams(newTemplateParams);
                        }}
                      >
                        Texto Livre
                      </ListItem>

                      <ListItem
                        value="contactName"
                        onClick={() => {
                          const newTemplateParams = [...templateParams];
                          newTemplateParams[index] = "contactName";
                          setTemplateParams(newTemplateParams);
                        }}
                      >
                        Nome do contato
                      </ListItem>
                    </Select>
                    {templateParams[index] !== "contactName" && (
                      <Input
                        placeholder={`Parâmetro ${index + 1}`}
                        value={templateParams[index]}
                        onChange={(e) => {
                          const newTemplateParams = [...templateParams];
                          newTemplateParams[index] = e.target.value;
                          setTemplateParams(newTemplateParams);
                        }}
                        fullWidth
                        autoFocus
                        style={{ margin: "1em 0", borderRadius: "10px" }}
                      />
                    )}
                  </Box>
                );
              })
          }

          {errorHappened && (
            <Typography color="error" style={{ alignSelf: "center" }}>
              Erro ao enviar template
            </Typography>
          )}
          <Box textAlign="center" sx={{ width: "100%", mt: 1 }}>
            <ModalActionBtns
              cancelBtnText={"Cancelar"}
              cancelBtnFn={handleClose}
              confirmBtnText={isLoading ? <CircularProgress /> : "Enviar"}
              confirmBtnFn={sendTemplate}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
