import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

function TicketInfo({ contact, ticket, onClick }) {
  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
      title={`${
        ticket.channel === "email"
          ? `${contact.name ? contact.name : "Sem nome"} - (${contact.email})`
          : contact.name
      } #${ticket.id}`}
      subheader={
        ticket.user &&
        `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
      }
    />
  );
}

export default TicketInfo;
