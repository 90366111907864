import React, { useState, useContext, useEffect, createRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import {
 Button,
 CssBaseline,
 TextField,
 Grid,
 Box,
 Typography,
 InputAdornment,
 IconButton,
 Link,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles, styled } from "@material-ui/core/styles";
import LoginAnimation from "../../components/lottieAnimation/loginAnimation";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import { system } from "../../../package.json";
import logo from "../../assets/logo.png";

function Copyright() {
 return (
  <Typography variant="body2" color="textSecondary" align="center">
   © {new Date().getFullYear()}
   {" - "}
   <Link color="inherit" href="https://notificame.com.br">
    {system.name} - v{system.version}
   </Link>
   .
  </Typography>
 );
}

const useStyles = makeStyles((theme) => ({
 paper: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "400px",
 },
 form: {
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
 },
 submit: {
  margin: theme.spacing(3, 0, 2),
  width: "350px",
  backgroundImage: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
  color: "#333333",
  [theme.breakpoints.down(510)]: {
   width: "280px",
  },
 },
 titleLogin: {
  fontSize: "35px",
  marginBottom: "35px",
  color: "#333333",
  [theme.breakpoints.down(510)]: {
   fontSize: "25px",
  },
 },
 mainContainer: {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  flexWrap: "wrap",
  borderRadius: "5px",
 },
 mainBoxContainer: {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f5f6f7",
 },
 separatorDiv: {
  height: "550px",
  width: "1px",
  backgroundColor: "lightgray",
  marginLeft: "50px",
  marginRight: "25px",
  [theme.breakpoints.down(1026)]: {
   marginLeft: "0px",
  },
  [theme.breakpoints.down(1175)]: {
   marginLeft: "0px",
   marginRight: "0px",
  },
  [theme.breakpoints.down(951)]: {
   display: "none",
  },
 },
 formContainer: {
  margin: "50px",
 },
 inputEmail: {
  width: "350px",
  [theme.breakpoints.down(510)]: {
   width: "280px",
  },
 },
 inputPassword: {
  width: "350px",
  [theme.breakpoints.down(510)]: {
   width: "280px",
  },
 },
 logo: {
  width: "200px",
  [theme.breakpoints.down(510)]: {
   width: "180px",
  },
 },
 imageContainer: {
  marginLeft: "60px",
  marginRight: "30px",
  [theme.breakpoints.down(1266)]: {
   marginLeft: "0px",
   marginRight: "0px",
  },
  [theme.breakpoints.down(951)]: {
   display: "none",
  },
 },
 animationLoginContainer: {
  [theme.breakpoints.down(1175)]: {
   width: "450px",
  },
 },
}));

const InputEmail = styled(TextField)({
 "& label.Mui-focused": {
  color: "#9e9e9e",
 },
 "& .MuiInput-underline:after": {
  borderBottomColor: "#9e9e9e",
 },
 "& .MuiOutlinedInput-root": {
  "& fieldset": {
   borderColor: "lightgray",
  },
  "&:hover fieldset": {
   borderColor: "#9e9e9e",
  },
  "&.Mui-focused fieldset": {
   borderColor: "#9e9e9e",
  },
 },
});

const InputPassword = styled(TextField)({
 "& label.Mui-focused": {
  color: "#9e9e9e",
 },
 "& .MuiInput-underline:after": {
  borderBottomColor: "#9e9e9e",
 },
 "& .MuiOutlinedInput-root": {
  "& fieldset": {
   borderColor: "lightgray",
  },
  "&:hover fieldset": {
   borderColor: "#9e9e9e",
  },
  "&.Mui-focused fieldset": {
   borderColor: "#9e9e9e",
  },
 },
});

function Login() {
 const classes = useStyles();

 const [user, setUser] = useState({ email: "", password: "" });
 const [showPassword, setShowPassword] = useState(false);
 const [captcha, setCaptcha] = useState("");

 const recaptchaRef = createRef();

 useEffect(() => {
  if (recaptchaRef?.current) recaptchaRef.current.reset();
 }, []);

 const { handleLogin } = useContext(AuthContext);

 const handleChangeInput = (e) => {
  setUser({ ...user, [e.target.name]: e.target.value });
 };

 const handlSubmit = (e) => {
  e.preventDefault();
  handleLogin(user);
 };

 return (
  <Grid container component="main">
   <Box className={classes.mainBoxContainer}>
    <CssBaseline />
    <div className={classes.mainContainer}>
     <Box className={classes.imageContainer} data-testid="1">
      <div className={classes.animationLoginContainer} data-testid="2">
       <LoginAnimation />
      </div>
     </Box>
     <div className={classes.separatorDiv} />
     <Grid className={classes.formContainer}>
      <div className={classes.paper} data-testid="101">
       <img alt="logo" src={logo} className={classes.logo} />
       <span className={classes.titleLogin}>{i18n.t("login.title")}</span>
       <form className={classes.form} noValidate onSubmit={handlSubmit}>
        <InputEmail
         variant="outlined"
         label={i18n.t("login.form.email")}
         id="email"
         margin="normal"
         required
         fullWidth
         name="email"
         value={user.email}
         onChange={handleChangeInput}
         autoComplete="email"
         autoFocus
         className={classes.inputEmail}
        />
        <InputPassword
         variant="outlined"
         margin="normal"
         required
         fullWidth
         name="password"
         label={i18n.t("login.form.password")}
         id="password"
         value={user.password}
         onChange={handleChangeInput}
         autoComplete="current-password"
         type={showPassword ? "text" : "password"}
         className={classes.inputPassword}
         InputProps={{
          endAdornment: (
           <InputAdornment position="end">
            <IconButton
             aria-label="toggle password visibility"
             onClick={() => setShowPassword((e) => !e)}
            >
             {showPassword ? (
              <VisibilityOff color="inherit" />
             ) : (
              <Visibility color="inherit" />
             )}
            </IconButton>
           </InputAdornment>
          ),
         }}
        />
        {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
         <ReCAPTCHA
          style={{ transform: "scale(0.8)" }}
          ref={recaptchaRef}
          name="recaptcha"
          id="recaptcha"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          secretkey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
          onChange={() => {
           setCaptcha(true);
          }}
          onExpired={() => {
           recaptchaRef.current.reset(); // here
          }}
          theme="light"
         />
        )}
        <div>
         <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
          disabled={process.env.REACT_APP_RECAPTCHA_SITE_KEY && !captcha}
         >
          {i18n.t("login.buttons.submit")}
         </Button>
         <Grid>
          <Link
           href="#"
           variant="body2"
           component={RouterLink}
           to="/recoverPassword"
          >
           {i18n.t("login.buttons.recover")}
          </Link>
         </Grid>
        </div>
       </form>
      </div>
      <Box mt={8}>
       <Copyright />
      </Box>
     </Grid>
    </div>
   </Box>
  </Grid>
 );
}

export default Login;
