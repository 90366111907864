import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textBulkMessageContainer: {
    width: "100%",
  },

  stepper: {
    padding: "0 20px 20px 20px",
    height: "80%",
  },

  completed: {
    display: "inline-block",
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  dialogContainer: {
    margin: "auto",
    marginTop: "8vh",

    "& .MuiDialog-container": {
      margin: "auto",
      width: "1000px",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  containerBulkMessageContainer: {
    width: "93%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    padding: "20px",
    overflow: "scroll",
  },

  formBulkMessageContainer: {
    display: "flex",
    flexDirection: "column",
  },

  temporalBulkForm: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  dividedContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5em",
  },

  groupButton: {
    // change button fontSize

    "& .MuiButton-label": {
      fontSize: "0.8em",
      textTransform: "Capitalize",
    },
  },

  temporalFields: {
    width: "70%",
    // change input fontSize
    "& .MuiInputBase-input": {
      fontSize: "0.9em",
    },
  },

  firstSlicedTemporalFields: {
    width: "30%",
    // change input fontSize
    "& .MuiInputBase-input": {
      fontSize: "0.9em",
      borderRadius: "0px",
    },
  },

  secondSlicedTemporalFields: {
    width: "30%",
    marginLeft: "-2%",
    // change input fontSize
    "& .MuiInputBase-input": {
      fontSize: "0.9em",
      borderRadius: "0px",
    },
  },

  slicedTemporalFieldsX: {
    backgroundColor: "#d9d9d9",
    marginLeft: "-2%",
    width: "14%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px",
    zIndex: 1,
    fontWeight: "1000",
    textShadow: "1px 0 #888888",
    // change button border radius

    "& .MuiButtonBase-root": {
      borderRadius: "0px",
    },
  },

  slicedTemporalFieldsIcon: {
    fontSize: "1em",
    fontWeight: "1000",
    textShadow: "0px 1px, 1px 0px, 1px 1px",
  },

  contactsBulkMessageContainer: {
    display: "flex",
    gap: "1.5em",
  },

  optionCard: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "20px 0",
    marginBottom: "20px",
  },

  cardTitle: {
    padding: "0 10px",
    marginBottom: "10px",
  },

  cardDescription: {
    fontSize: "0.8em",
    padding: "0 10px",
  },

  contactsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5em",
  },

  tableStyle: {
    zIndex: 5,

    // remove table cell dividers before header
    "& .MuiTableBody-root": {
      "& .MuiTableCell-root": {
        borderBottom: "none",
        fontSize: "0.8em",
        padding: "0 17px",
        margin: 0,
        zIndex: 5,
      },
    },

    // change tableHeader fontSize
    "& .MuiTableCell-head": {
      fontSize: "0.9em",
      padding: "0px 17px",
      margin: 0,
    },
  },

  tableContainer: {
    // scroll table body
    maxHeight: "40vh",
    margin: 0,
    padding: 0,
    border: "1px solid #d9d9d9",
    borderRadius: "3px",
    zIndex: 5,
  },

  tableContainerOnLeads: {
    // scroll table body
    minHeight: "20vh",
    maxHeight: "20vh",
    width: "100%",
    margin: 0,
    padding: 0,
    border: "1px solid #d9d9d9",
    borderRadius: "3px",
    zIndex: 5,
  },

  countContacts: {
    fontSize: "0.8em",
    margin: "-30px 0 0 0",
    padding: 0,
  },

  countContactsOnLead: {
    fontSize: "0.8em",
    padding: 0,
    marginTop: "-20px",
  },

  actionButton: {
    padding: 5,
  },

  actionIcon: {
    fontSize: "0.7em",
  },

  deleteSelectedButton: {
    fontSize: "0.8em",
    padding: 0,
    marginTop: "-2px",
    zIndex: 0,
    backgroundColor: "#dc004e",
    color: "#fff",
    borderRadius: "0px 0px 3px 3px",
    border: "1px solid #dc004e",

    "&.Mui-disabled": {
      border: "1px solid #e0e0e0",
    },

    "&:hover": {
      border: "1px solid #9a0036",
      backgroundColor: "#9a0036",
    },
  },

  transferSelectedButton: {
    fontSize: "0.8em",
    padding: 0,
    marginTop: "-2px",
    zIndex: 0,
    backgroundColor: "#009688",
    color: "#fff",
    borderRadius: "0px 0px 3px 3px",
    border: "1px solid #009688",

    "&.Mui-disabled": {
      border: "1px solid #e0e0e0",
    },

    "&:hover": {
      border: "1px solid #006d63",
      backgroundColor: "#006d63",
    },
  },

  inputLabel: {
    fontSize: "0.9em",
    margin: 0,
    padding: 0,
    marginBottom: "5px",
    color: "#181818",
  },

  messageLength: {
    fontSize: "0.8em",
    margin: 0,
    padding: 0,
    paddinRight: "5px",
    color: "#939393",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
    zIndex: 5,
  },

  observationText: {
    fontSize: "0.8em",
    margin: "-20px 0 15px 0",
    padding: 0,
    color: "#939393",
    // set text to italic
    fontStyle: "italic",
  },

  valueTitle: {
    fontSize: "0.9em",
    margin: 0,
    padding: 0,
    marginBottom: "1px",
    color: "#181818",
    fontWeight: 900,
  },

  valueText: {
    fontSize: "0.85em",
    margin: 0,
    padding: 0,
    marginBottom: "10px",
    maxHeight: "100px",
    overflow: "scroll",
  },

  simpleTextField: {
    width: "100%",
    marginBottom: "20px",
    overflow: "hidden",
  },

  contactSimpleTextField: {
    width: "90%",

    "& .MuiInputLabel-root": {
      marginTop: "2px",
      fontSize: 12,
    },
  },

  simpleSelectField: {
    width: "100%",
    padding: 0,
    // change height
    "& .MuiSelect-root": {
      padding: "10px",
      maxHeight: "20px",
      display: "flex",
    },
  },

  contactSearchTextField: {
    width: "40%",

    "& .MuiInputLabel-root": {
      marginTop: "2px",
      fontSize: 12,
    },
  },

  filtersBox: {
    width: "100%",
    display: "flex",
    marginBottom: "25px",
    justifyContent: "space-between",
  },

  tagsInput: {
    width: "40%",
  },

  tagsBox: {
    width: "40%",
    borderRadius: "3px",
    border: "1px solid #d9d9d9",
    padding: "3px 10px",
  },

  addContactBtn: {
    height: "39px",
    fontWeight: 900,
    fontSize: "1.5em",
  },

  actionsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  csvActionsContainer: {
    width: "100%",
    display: "flex",
    gap: '8px',
  },

  downloadButton: {
    maxHeight: '40px',
    width: '50%',
  },

  searchButton: {
    fontWeight: 400,
    letterSpacing: "0.03em",
    marginRight: "-13px",
    borderRadius: 0,
    boxShadow: "none",
    padding: "10px 30px",
    fontSize: "0.8em",
    textTransform: "Capitalize",
  },

  removeUrl: {
    color: "#e0e0e0",
  },

  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },

  checkedIcon: {
    backgroundColor: "#009688",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#008477",
    },
  },
}));
