import Excel from "exceljs";
import { saveAs } from "file-saver";

export const ExcelPrint = (data) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Relatório");

  worksheet.columns = [
    { header: "Id", key: "id", width: 10 },
    { header: "Setor", key: "queue", width: 20 },
    { header: "Atendente", key: "user", width: 20 },
    { header: "Contato", key: "contact", width: 20 },
    { header: "Etiquetas", key: "tags", width: 80 },
    { header: "Mensagem", key: "messages", width: 200 },
  ];

  Object.keys(data).forEach((key) => {
    data[key].forEach((ticket) => {
      worksheet.addRow({
        id: ticket?.id,
        queue: ticket?.queue?.name || "Sem Setor",
        user: ticket?.user?.name || "Sem Atendente",
        contact: ticket?.contact?.name,
        tags: ticket?.contact?.tags
          ?.map((tag) => {
            return tag?.name;
          })
          .join(", "),
        messages: ticket?.messages
          ?.map((message) => {
            return message?.body
              ?.replace("<p>", "")
              .replace("</p>", "")
              .replace("<p></p>", "");
          })
          .join(" | "),
      });
    });
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Relatório_de_tickets_${new Date().toLocaleString()}.xlsx`);
  });
};
