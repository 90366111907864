import React, { useContext, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: "0px",
  },
  modalQR: {
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "350px",
    width: "750px",
    padding: "0px",
  },
  textContainer: {
    width: "350px",
  },
  titleText: {
    color: "#009688",
  },
  instructionText: {
    color: "black",
  },
  listItem: {
    marginBottom: "10px",
  },
}));

function QrcodeModal({ open, onClose, whatsAppId }) {
  const classes = useStyles();
  const [qrCode, setQrCode] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const socket = openSocket({
      scope: "qrcode",
      userId: user.id,
      component: "QrcodeModal",
    });

    socket.on("whatsappSession", (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      className={classes.modalContainer}
    >
      <DialogContent className={classes.modalQR} style={{ paddingTop: "0px" }}>
        <Paper elevation={0} className={classes.modalQR}>
          <Typography
            color="primary"
            gutterBottom
            className={classes.textContainer}
          >
            <h2 className={classes.titleText}>{i18n.t("qrCode.title")}</h2>
            <ol className={classes.instructionText}>
              <li className={classes.listItem}>
                {i18n.t("qrCode.firstPoint")}
              </li>
              <li className={classes.listItem}>
                {i18n.t("qrCode.secondPoint.partOne")}
                <b>{i18n.t("qrCode.secondPoint.moreOptions")}</b>
                <img
                  src="https://img.icons8.com/material-rounded/24/737373/menu-2.png"
                  alt="menu-burguer-options"
                />
                {i18n.t("qrCode.secondPoint.partTwo")}
                <b>{i18n.t("qrCode.secondPoint.configurations")}</b>
                <img
                  src="https://img.icons8.com/material-outlined/24/737373/gear--v1.png"
                  alt="icont-settings"
                />{" "}
                {i18n.t("qrCode.secondPoint.partThree")}
              </li>
              <li className={classes.listItem}>
                {i18n.t("qrCode.thirdPoint")}
              </li>
              <li className={classes.listItem}>
                {i18n.t("qrCode.fourthPoint")}
              </li>
            </ol>
          </Typography>
          {qrCode ? (
            <QRCode value={qrCode} size={256} />
          ) : (
            <span>Waiting for QR Code</span>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(QrcodeModal);
