import React, { useContext, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Box, Tooltip, Divider, TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import useTickets from "../../hooks/useTickets";

import { AuthContext } from "../../context/Auth/AuthContext";
import { ThemeContext } from "../../context/Theme/ThemeContext";
import { ChartDonut } from "../../components/ChartDonut";

import { i18n } from "../../translate/i18n";

import Chart from "./Chart";
import ChartServiceBar from "../../components/ChartProgressBar/ChartServiceBar";
import ChartRatingBar from "../../components/ChartProgressBar/ChartRatingBar";
import ChartDepartmentBar from "../../components/ChartProgressBar/ChartDepartmentBar";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ChartBar from "../../components/ChartBar";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
    borderRadius: "5px",
    boxShadow: "1px 1px 5px 1px lightgrey",
  },

  customFixedHeightPaper: {
    padding: "10px",
    marginBottom: "10px",
    width: "373px",
    height: "100px",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px 1px lightgrey",
    [theme.breakpoints.down(650)]: {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  customFixedHeightPaperDark: {
    padding: "10px",
    marginBottom: "10px",
    width: "373px",
    height: "100px",
    borderRadius: "5px",
    backgroundColor: "#424242",
    [theme.breakpoints.down(650)]: {
      width: "350px",
    },
  },
  chartBar: {
    height: "324px",
    width: "95.5%",
    maxWidth: "1151px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflow: "auto",
    boxShadow: "1px 1px 5px 1px lightgrey",
    borderRadius: "5px",
    [theme.breakpoints.down(827)]: {
      width: "550px",
    },
    [theme.breakpoints.down(650)]: {
      width: "350px",
    },
  },

  chartBarDark: {
    height: "324px",
    width: "93.5%",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflow: "auto",
    borderRadius: "5px",
    backgroundColor: "#424242",
    [theme.breakpoints.down(827)]: {
      width: "550px",
    },
    [theme.breakpoints.down(650)]: {
      width: "350px",
    },
  },

  fixedHeightPaperDark: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
    borderRadius: "5px",
    backgroundColor: "#424242",
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  containerDonutChart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    paddingBottom: "30px",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px 1px lightgrey",
    width: "373px",
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      width: "350px",
      marginLeft: "0px",
    },
  },
  containerDonutChartDark: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    borderRadius: "5px",
    paddingBottom: "30px",
    width: "373px",
    backgroundColor: "#424242",
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      width: "350px",
      marginLeft: "0px",
    },
  },
  boxCharts: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  progressBarChart: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "373px",
    borderRadius: "5px",
    height: "280px",
    overflow: "scroll",
    boxShadow: "1px 1px 5px 1px lightgrey",
    [theme.breakpoints.down("650")]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  progressBarChartDark: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "373px",
    borderRadius: "5px",
    height: "280px",
    overflow: "scroll",
    backgroundColor: "#424242",
    [theme.breakpoints.down("650")]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  containerChartTickets: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px",
    },
    [theme.breakpoints.down("650")]: {
      marginRight: "0px",
    },
  },
  chartTickets: {
    height: "324px",
    width: "761px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflow: "hidden",
    boxShadow: "1px 1px 5px 1px lightgrey",
    borderRadius: "5px",
    [theme.breakpoints.down(827)]: {
      width: "550px",
    },
    [theme.breakpoints.down(650)]: {
      width: "350px",
    },
  },
  chartTicketsDark: {
    height: "324px",
    width: "761px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflow: "hidden",
    borderRadius: "5px",
    backgroundColor: "#424242",
    [theme.breakpoints.down(827)]: {
      width: "550px",
    },
    [theme.breakpoints.down(650)]: {
      width: "350px",
    },
  },
  containerChartBar: {
    width: "93%",
    [theme.breakpoints.down(500)]: {
      width: "350px",
    },
  },
  cardIconsInfo: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  containerTicketsCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "5px",
    gap: "1em",
  },
  ticketCards: {
    padding: "10px",
    marginBottom: "10px",
    marginRight: "5px",
    marginLeft: "5px",
    width: "22.4%",
    maxWidth: "269px",
    height: "100px",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px 1px lightgrey",
    [theme.breakpoints.down(636)]: {
      width: "350px",
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  ticketCardsDark: {
    padding: "10px",
    marginBottom: "10px",
    marginRight: "5px",
    marginLeft: "5px",
    width: "280px",
    height: "100px",
    borderRadius: "5px",
    backgroundColor: "#424242",
    [theme.breakpoints.down(636)]: {
      width: "350px",
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  customFixedHeightPaperCenter: {
    padding: "10px",
    marginBottom: "10px",
    width: "373px",
    height: "100px",
    borderRadius: "5px",
    marginLeft: "15px",
    marginRight: "15px",
    boxShadow: "1px 1px 5px 1px lightgrey",
    [theme.breakpoints.down(650)]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  customFixedHeightPaperDarkCenter: {
    padding: "10px",
    marginBottom: "10px",
    width: "373px",
    height: "100px",
    borderRadius: "5px",
    marginLeft: "15px",
    marginRight: "15px",
    backgroundColor: "#424242",
    [theme.breakpoints.down(650)]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  progressBarChartCenter: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "373px",
    borderRadius: "5px",
    height: "280px",
    overflow: "scroll",
    boxShadow: "1px 1px 5px 1px lightgrey",
    marginLeft: "15px",
    marginRight: "15px",
    [theme.breakpoints.down("650")]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  progressBarChartDarkCenter: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "373px",
    borderRadius: "5px",
    height: "280px",
    overflow: "scroll",
    marginLeft: "15px",
    marginRight: "15px",
    backgroundColor: "#424242",
    [theme.breakpoints.down("650")]: {
      width: "350px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
}));

function Dashboard() {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { darkMode } = useContext(ThemeContext);
  const [dataDashboard, setDataDashboard] = useState([]);
  // const [period, setPeriod] = useState("today");
  const [period, setPeriod] = useState({
    start: new Date().toISOString().slice(0, 10),
    end: new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .slice(0, 10),
  });
  let userQueueIds = [];

  if (user.queues && user.queues?.length > 0) {
    userQueueIds = user.queues?.map((q) => q.id);
  }

  const GetTickets = (status, showAll, withUnreadMessages) => {
    const { count } = useTickets({
      status,
      showAll,
      withUnreadMessages,
      queueIds: JSON.stringify(userQueueIds),
      period,
    });
    return count;
  };

  const getDataDashboard = async (period) => {
    const func = (array) => {
      if (array[0].length === 0) return "00h 00m";

      const data = array[0]
        .map((el) => el.averageTime)
        .reduce((prev, curr) => Number(prev) + Number(curr));

      const hours = ((data / array[0].length) * 60).toFixed(2) / 60;
      const rhours = Math.floor(hours);
      const minutes = Math.round((hours - rhours) * 60);

      return `${rhours === 0 ? "00" : rhours < 10 ? `0${rhours}` : rhours}h ${
        minutes === 0 ? "00" : minutes < 10 ? `0${minutes}` : minutes
      }m`;
    };
    try {
      const {
        data: {
          connectionsCount,
          contactsCount,
          messageCount,
          allTicketsClosed,
          averageTimeOpen,
          averageTimePending,
          averageRating,
          dataChartAttendants,
          dataDonutChart,
          ratingCount,
          dataProgressDepartmentBar,
          dataProgressServiceBar,
          dataBarRating,
          cloudMessages,
        },
      } = await api.get(
        "/dashboard/",
        // send period as query
        {
          params: {
            period,
          },
        }
      );

      return {
        connectionsCount,
        contactsCount,
        messageCount,
        allTicketsClosed,
        averageTimeTicketOpen: func(averageTimeOpen),
        averageTimeTicketPending: func(averageTimePending),
        averageRating: averageRating[0].avgRating,
        dataChartAttendants,
        dataDonutChart,
        ratingCount,
        dataProgressDepartmentBar,
        dataProgressServiceBar,
        dataBarRating,
        cloudMessages,
      };
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const {
        connectionsCount,
        contactsCount,
        messageCount,
        averageTimeTicketOpen,
        averageTimeTicketPending,
        averageRating,
        ratingCount,
        cloudMessages,
      } = await getDataDashboard(period);

      setDataDashboard({
        connectionsCount,
        contactsCount,
        messageCount,
        averageTimeTicketOpen,
        averageTimeTicketPending,
        averageRating,
        ratingCount,
        cloudMessages,
      });
    };

    fetch();
  }, [period]);

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h3" variant="h6" color="primary" paragraph>
          DASHBOARD
        </Typography>
        <Divider />
        <Box className={classes.containerTicketsCards}>
          {/* <Button
            variant={period === "today" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPeriod("today")}
          >
            {i18n.t("dashboard.epoch.buttons.today")}
          </Button>
          <Button
            variant={period === "week" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPeriod("week")}
          >
            {i18n.t("dashboard.epoch.buttons.week")}
          </Button>
          <Button
            variant={period === "month" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPeriod("month")}
          >
            {i18n.t("dashboard.epoch.buttons.month")}
          </Button>
          <Button
            variant={period === "year" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPeriod("year")}
          >
            {i18n.t("dashboard.epoch.buttons.year")}
          </Button> */}
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <TextField
              id="date"
              label="Data Inicial"
              type="date"
              // set default value to today as yyyy-mm-dd
              defaultValue={new Date().toISOString().slice(0, 10)}
              onChange={(e) => setPeriod({ ...period, start: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="Data Final"
              type="date"
              defaultValue={new Date(
                new Date().setDate(new Date().getDate() + 1)
              )
                .toISOString()
                .slice(0, 10)}
              onChange={(e) => setPeriod({ ...period, end: e.target.value })}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.containerTicketsCards}>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.ticketsToday")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/50/54a9d1/messaging-.png"
                  alt="icon-mensage"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.inAttendance.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {GetTickets("open", "true", "false")}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title={i18n.t("dashboard.infoCard.awaitingService")}
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/55/f27b07/queue.png"
                  alt="icon-waiting-mensage"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.waiting.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {GetTickets("pending", "true", "false")}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.ticketsSolved")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/45/25b947/chat-message-sent.png"
                  alt="icon-chat-message"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.closed.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {GetTickets("closed", "true", "false")}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.connections")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/glyph-neue/50/8b7fa8/wifi--v1.png"
                  alt="icon-connection"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.connection.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard.connectionsCount}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
        </Box>
        <Box className={classes.containerTicketsCards}>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title="Quantidade de tickets gerados a partir da API oficial do whatsapp."
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              {/* <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/50/54a9d1/messaging-.png"
                  alt="icon-mensage"
                />
              </div> */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>Cloud: Tickets</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard?.cloudMessages?.tickets}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title="Quantidade de sessões geradas a partir da API oficial do whatsapp."
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              {/* <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/55/f27b07/queue.png"
                  alt="icon-waiting-mensage"
                />
              </div> */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>Cloud: Sessões</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard?.cloudMessages?.sessions}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title="Quantidade de mensagens enviadas pela API oficial do whatsapp."
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              {/* <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/45/25b947/chat-message-sent.png"
                  alt="icon-chat-message"
                />
              </div> */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>Cloud: Mensagens Enviadas</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard?.cloudMessages?.messagesFromMe}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={darkMode ? classes.ticketCardsDark : classes.ticketCards}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title="Quantidade de mensagens recebidas pela API oficial do whatsapp."
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              {/* <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/glyph-neue/50/8b7fa8/wifi--v1.png"
                  alt="icon-connection"
                />
              </div> */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>Cloud: Mensages Recebidas</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard?.cloudMessages?.messagesFromClient}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          className={classes.boxCharts}
        >
          <div className={classes.containerChartTickets} data-testid="div pai">
            <div
              className={
                darkMode ? classes.chartTicketsDark : classes.chartTickets
              }
              data-testid="div do grafico"
            >
              <ChartBar dataChart={{ getDataDashboard }} period={period} />
            </div>
          </div>
          <Box
            className={
              darkMode
                ? classes.containerDonutChartDark
                : classes.containerDonutChart
            }
          >
            <div>
              <Typography component="h4" variant="h6" color="primary" paragraph>
                {i18n.t("dashboard.departments.title")}
              </Typography>
              <ChartDonut dataChart={{ getDataDashboard }} period={period} />
            </div>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "15px" }}
        >
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDark
                : classes.customFixedHeightPaper
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.waitingTime")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div>
                <img
                  src="https://img.icons8.com/windows/45/72705d/user-clock.png"
                  alt="icon-clock-user"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.waitingTime.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard.averageTimeTicketPending}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDarkCenter
                : classes.customFixedHeightPaperCenter
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.serviceTime")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div>
                <img
                  src="https://img.icons8.com/ios/45/54babf/clock--v3.png"
                  alt="icon-clock"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.serviceTime.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    {dataDashboard.averageTimeTicketOpen}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDark
                : classes.customFixedHeightPaper
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.averageRating")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/ios/45/f1e849/star--v1.png"
                  alt="icon-clock-user"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.averageRating.title")}</span>
                <div>
                  {/* <span style={{ fontSize: "25px" }}>
                    {dataDashboard.averageRating === undefined
                      ? "0.0"
                      : Number(dataDashboard.averageRating).toFixed(1)}
                  </span> */}
                  <Rating
                    name="size-large"
                    value={Math.floor(dataDashboard.averageRating)}
                    size="large"
                    readOnly
                  />
                </div>
              </Box>
            </div>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDark
                : classes.customFixedHeightPaper
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.contactNumber")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/windows/50/b683ff/phone-book.png"
                  alt="icon-contact-list"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.contacts.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    Total: {dataDashboard.contactsCount}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDarkCenter
                : classes.customFixedHeightPaperCenter
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip
                title={i18n.t("dashboard.infoCard.messagesNumber")}
                arrow
              >
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/fluency-systems-regular/45/c9c9c9/filled-chat.png"
                  alt="icon-mensage"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.messages.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    Total: {dataDashboard.messageCount}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
          <Box
            className={
              darkMode
                ? classes.customFixedHeightPaperDark
                : classes.customFixedHeightPaper
            }
            style={{ overflow: "hidden" }}
          >
            <div className={classes.cardIconsInfo}>
              <Tooltip title={i18n.t("dashboard.infoCard.reviews")} arrow>
                <img
                  src="https://img.icons8.com/pastel-glyph/17/50eb74/information--v1.png"
                  alt="icon-information-button"
                />
              </Tooltip>
            </div>
            <div className={classes.cardIcons}>
              <div style={{ marginRight: "30px" }}>
                <img
                  src="https://img.icons8.com/external-parzival-1997-detailed-outline-parzival-1997/64/eeb1f7/external-feedback-shopstreaming-parzival-1997-detailed-outline-parzival-1997.png"
                  alt="icon-feedbacks"
                />
              </div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                <span>{i18n.t("dashboard.messages.reviews.title")}</span>
                <div>
                  <span style={{ fontSize: "25px" }}>
                    Total: {dataDashboard.ratingCount}
                  </span>
                </div>
              </Box>
            </div>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <div
            className={
              darkMode ? classes.progressBarChartDark : classes.progressBarChart
            }
          >
            <div>
              <ChartServiceBar
                dataChart={{ getDataDashboard }}
                period={period}
              />
            </div>
          </div>
          <div
            className={
              darkMode
                ? classes.progressBarChartDarkCenter
                : classes.progressBarChartCenter
            }
          >
            <div>
              <ChartDepartmentBar
                dataChart={{ getDataDashboard }}
                period={period}
              />
            </div>
          </div>
          <div
            className={
              darkMode ? classes.progressBarChartDark : classes.progressBarChart
            }
          >
            <div>
              <ChartRatingBar
                dataChart={{ getDataDashboard }}
                period={period}
              />
            </div>
          </div>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <div className={darkMode ? classes.chartBarDark : classes.chartBar}>
            <Chart />
          </div>
        </Box>
      </Container>
    </div>
  );
}

export default Dashboard;
