import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { FormLabel } from "@material-ui/core";
import api from "../../services/api";
import { ActionModal } from "./ActionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    width: "200px",
  },
  userChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#00fa9a",
  },
  queueChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#ff00ff",
  },
  statusChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#00bfff",
  },
  tagChip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#ff4500",
  },
  connectionChip: {
    margin: theme.spacing(0.5),
    // backgroundColor: "#ff0000",
  },
}));

export default function ActionChips({
  optionActions,
  selectedChatbot,
  setSelectedChatbot,
  nodeId,
  optionId,
}) {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [queues, setQueues] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [connections, setConnections] = React.useState([]);
  const [actionModalOpen, setActionModalOpen] = React.useState(false);

  useEffect(() => {
    (async () => {
      const {
        data: { users },
      } = await api.get("/users");
      setUsers(users);
    })();

    (async () => {
      const { data } = await api.get("/queue");
      setQueues(data);
    })();

    (async () => {
      const { data } = await api.get("/tags/list");
      setTags(data);
    })();

    (async () => {
      const { data } = await api.get("/whatsapp");
      setConnections(data);
    })();
  }, [optionActions]);

  const handleDelete = async (chipToDelete) => {
    const deletedAction = await api.delete(
      `/chatbot/action/${chipToDelete.id}`
    );

    setSelectedChatbot({
      ...selectedChatbot,
      nodes: selectedChatbot.nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            options: node.options.map((option) => {
              if (option.id === optionId) {
                return {
                  ...option,
                  action: option.action.filter(
                    (action) => action.id !== chipToDelete.id
                  ),
                };
              }
              return option;
            }),
          };
        }
        return node;
      }),
    });
  };

  return (
    <Paper
      component="ul"
      className={classes.root}
      style={{
        minHeight: "40px",
      }}
    >
      <ActionModal
        data={{ users, queues, tags, connections }}
        open={actionModalOpen}
        handleClose={() => setActionModalOpen(false)}
        setActionModalOpen={setActionModalOpen}
        selectedChatbot={selectedChatbot}
        setSelectedChatbot={setSelectedChatbot}
        nodeId={nodeId}
        optionId={optionId}
      />
      <FormLabel
        style={{
          position: "absolute",
          fontSize: "12px",
          color: "#a0a0a0",
          marginTop: "-10px",
          marginLeft: "-150px",
        }}
      >
        Ações:
      </FormLabel>
      {optionActions?.map((action) => {
        let icon;

        return (
          <li key={action.id}>
            <Chip
              size="small"
              icon={icon}
              label={
                action.name.includes("Setor")
                  ? `${action.name} -> ${
                      queues?.find(
                        (queue) => queue.id === Number(action.should)
                      )?.name
                    }`
                  : action.name.includes("Etiqueta")
                  ? `${action.name} -> ${
                      tags?.find((tag) => tag.id === Number(action.should))
                        ?.name
                    }`
                  : action.name.includes("Atendente")
                  ? `${action.name} -> ${
                      users?.find((user) => user.id === Number(action.should))
                        ?.name
                    }`
                  : action.name.includes("Conexão")
                  ? `${action.name} -> ${
                      connections?.find(
                        (con) => con.id === Number(action.should)
                      )?.name
                    }`
                  : action.name.includes("Status")
                  ? `${action.name} -> ${action.should}`
                  : null
              }
              onDelete={() => handleDelete(action)}
              className={
                action.name.includes("Setor")
                  ? classes.queueChip
                  : action.name.includes("Etiqueta")
                  ? classes.tagChip
                  : action.name.includes("Atendente")
                  ? classes.userChip
                  : action.name.includes("Conexão")
                  ? classes.connectionChip
                  : action.name.includes("Status")
                  ? classes.statusChip
                  : null
              }
            />
          </li>
        );
      })}
      <li
        key={"add-chip"}
        style={{
          border: "1px solid #a6a6a6",
          color: "#a6a6a6",
          fontWeight: "bold",
          width: "1.3em",
          height: "1.3em",
          cursor: "pointer",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
          margin: "auto",
          marginRight: "0px",
          marginLeft: "0px",
        }}
        onClick={() => setActionModalOpen(true)}
      >
        +
      </li>
    </Paper>
  );
}
