import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ModalImageChatbot from "../ModalImageChatbot";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import VideocamIcon from "@material-ui/icons/Videocam";
import MicIcon from "@material-ui/icons/Mic";

const useStyles = makeStyles((theme) => ({
  paper: { width: "800px", height: "500px", margin: "auto" },

  content: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: "1em",
    justifyContent: "space-between",
    alignItems: "center",
  },

  deleteButton: {
    marginTop: "-50px",
    marginLeft: "-7px",
    // width: "20px",
    // height: "20px",
    padding: "2px",
    color: "white",
    backgroundColor: "red",

    "&:hover": {
      backgroundColor: "#b52100",
    },
  },

  addMedia: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    width: "80px",
    marginRight: "0.6em",
    border: "1px dashed #ccc",
    borderRadius: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    overflow: "hidden",
  },

  otherMedias: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    width: "80px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    overflow: "hidden",
  },
  otherMediaType: {
    marginTop: "1em",
  },

  otherMediaName: {
    fontSize: "0.7em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    textAlign: "center",
  },

  mediaName: {
    fontSize: "0.7em",
    marginTop: "3em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    textAlign: "center",
  },

  addIcon: {
    position: "absolute",
    margin: "0 auto",
  },
}));

const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
const videoTypes = ["mp4", "webm"];
const audioTypes = ["mp3", "wav", "ogg"];

const MediasModal = ({
  open,
  onClose,
  medias,
  selectedNode,
  handleAddNodeUrl,
  handleDeleteNodeUrl,
}) => {
  const classes = useStyles();

  const renderMedia = (url) => {
    if (imageTypes.includes(url.split(".").pop())) {
      return <ModalImageChatbot imageUrl={url} style={{ width: "10px" }} />;
    } else if (videoTypes.includes(url.split(".").pop())) {
      return (
        <Link href={url} target="_blank" rel="noopener">
          <Box className={classes.otherMedias}>
            <VideocamIcon className={classes.addIcon} />
            <Typography variant="caption" className={classes.mediaName}>
              {url.split("/").pop()}
            </Typography>
          </Box>
        </Link>
      );
    } else if (audioTypes.includes(url.split(".").pop())) {
      return (
        <Link href={url} target="_blank" rel="noopener">
          <Box className={classes.otherMedias} onClick={() => {}}>
            <MicIcon className={classes.addIcon} />
            <Typography variant="caption" className={classes.mediaName}>
              {url.split("/").pop()}
            </Typography>
          </Box>
        </Link>
      );
    } else {
      return (
        <Link href={url} target="_blank" rel="noopener">
          <Box className={classes.otherMedias}>
            <Typography
              variant="body"
              className={classes.otherMediaType}
            >{`.${url.split(".").pop()}`}</Typography>
            <Typography variant="caption" className={classes.otherMediaName}>
              {url.split("/").pop()}
            </Typography>
          </Box>
        </Link>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      className={classes.paper}
    >
      <DialogTitle>{`Editando mídias do nó ${selectedNode}`} </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {medias?.map((media, index) => {
          return (
            <Tooltip title={media.url} placement="top">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {renderMedia(media.url)}

                <IconButton
                  className={classes.deleteButton}
                  onClick={() => {
                    handleDeleteNodeUrl(selectedNode, media.id);
                  }}
                >
                  <CloseIcon
                    style={{
                      fontSize: "0.5em",
                    }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          );
        })}
        <Box className={classes.addMedia}>
          <input
            type="file"
            // hidden
            onChange={(e) => {
              handleAddNodeUrl(e, selectedNode);
            }}
            style={{
              width: "100%",
              height: "100%",
              zIndex: "10",
              // marginTop: "-80px",
              padding: "300px",
              opacity: "0",
            }}
          />
          <AddIcon className={classes.addIcon} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onClose} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediasModal;
