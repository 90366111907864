import React from 'react';

import { Container, makeStyles, Typography } from '@material-ui/core';
import Error from '../../assets/error.png';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  image: {
    backgroundImage: `url(${Error})`,
    backgroundRepeat: 'no-repeat',
    height: 351,
    width: 200,
  },
}));

function ErrorFallback({ error }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Container component="main" className={classes.main} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom>
            {i18n.t('errorFallback.error')}
          </Typography>
          <div className={classes.image} />
          <Typography variant="h6" component="h2" gutterBottom>
            {i18n.t('errorFallback.instructions')}
          </Typography>
        </Container>

        <div role="alert">
          <p>Error:</p>
          <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
