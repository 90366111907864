import React, { useEffect, useState } from 'react';
import {
  PieChart, Pie, Tooltip, ResponsiveContainer,
} from 'recharts';

export function ChartDonut({ dataChart: { getDataDashboard }, period }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getDataChart = async () => {
      try {
        const { dataDonutChart } = await getDataDashboard(period);

        setData(dataDonutChart);
      } catch (error) {
        console.log(error);
      }
    };

    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <div style={{ width: 300, height: 230 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie dataKey="value" data={data} label />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
