import React, { useContext } from "react";

import {
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";

import { ThemeContext } from '../../../../context/Theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f5f6f6",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    minHeight: "50px",
  },

  quotedContainerLeftDarkMode: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#1d282f",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    minHeight: "50px",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#d1f4cc",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerRightDarkMode: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#025144",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loadingRight: {
    backgroundColor: '#FFFFFF99',
  },

  loadingLeft: {
    backgroundColor: '#d9fdd399',
  },
}));

export default function QuotedMessage({ message, getAroundMessages, searchLoading, searchedMessage }) {
  const classes = useStyles();
  const { darkMode } = useContext(ThemeContext);

  const isFromMe = message.fromMe;

  return (
    <div
      onClick={() => getAroundMessages(message.quotedMsg)}
      style={{
        cursor: "pointer",
      }}
      className={
        darkMode
          ? clsx(classes.quotedContainerLeftDarkMode, {
              [classes.quotedContainerRightDarkMode]: message.fromMe,
            })
          : clsx(classes.quotedContainerLeft, {
              [classes.quotedContainerRight]: message.fromMe,
            })
      }
    >
      <span
        className={clsx(classes.quotedSideColorLeft, {
          [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
        })}
      />

      {(searchLoading && searchedMessage?.id === message.quotedMsg.id) && (
        <div
          className={clsx(
            classes.loading,
            { [classes.loadingRight]: isFromMe },
            { [classes.loadingLeft]: !isFromMe }
          )}
        >
          <CircularProgress />
        </div>
      )}

      <div className={classes.quotedMsg}>
        {!message.quotedMsg?.fromMe && (
          <span className={classes.messageContactName}>
            {message.quotedMsg?.contact?.name}
          </span>
        )}
        {message.quotedMsg?.body}
      </div>
    </div>
  )
}
