import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../../errors/toastError";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import ModalImageChatbot from "../../../components/ModalImageChatbot";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },

  textField: {
    width: "100%",
  },
}));

function CardModal({ modalOpen, onClose, selectedCard, reloadBoard }) {
  const [card, setCard] = useState(selectedCard);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (selectedCard) {
      setCard(selectedCard);
    }
  }, [selectedCard]);

  useEffect(() => {
    if (!modalOpen) return;

    (async () => {
      try {
        const {
          data: { users },
        } = await api.get("/users");
        setUsers(users);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [modalOpen]);

  const handleClose = () => {
    onClose();
    // setCard(null);
  };

  const handleSaveCard = async (cardId) => {
    const { description, date, cover, userId, expiresIn } = card;
    setLoading(true);
    if (!cardId) return;
    try {
      await api.post("/sales-funnel/pin-contact", {
        contactId: selectedCard.contactId,
        description,
        date,
        cover,
        userId,
        expiresIn,
      });

      reloadBoard();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }

    handleClose();
  };

  const handleUpdateMedia = async (e) => {
    if (!e.target.files) {
      return;
    }

    const img = e.target.files[0];
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", img);
    await api.post("/bulk-messaging/upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const url = `${process.env.REACT_APP_BACKEND_URL}/public/${img.name}`;

    setCard((prevState) => ({
      ...prevState,
      cover: url,
    }));
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Editar cartão</DialogTitle>
        <FormControl>
          <DialogContent
            dividers
            style={{ width: 350 }}
            className={classes.dialogContent}
          >
            <TextField
              size="small"
              multiline
              maxRows={4}
              minRows={4}
              label="Descrição"
              name="description"
              autoFocus
              value={card?.description}
              onChange={(e) => {
                setCard({ ...card, description: e.target.value });
              }}
              variant="outlined"
              margin="dense"
              className={classes.textField}
            />

            <TextField
              size="small"
              select
              label="Responsável"
              name="user"
              value={card?.userId}
              onChange={(e) => {
                setCard({ ...card, userId: e.target.value });
              }}
              variant="outlined"
              margin="dense"
              className={classes.textField}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              size="small"
              label="Data"
              type="date"
              name="expiresIn"
              InputLabelProps={{
                shrink: true,
              }}
              value={card?.expiresIn?.split("T")[0]}
              onChange={(e) => {
                // set expiresIn on format 2023-07-15T05:02:58.000Z
                setCard({
                  ...card,
                  expiresIn: `${e.target.value}T04:00:00.000Z`,
                });
              }}
              variant="outlined"
              fullWidth
              className={classes.textField}
            />

            <TextField
              label="Capa"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={card?.cover}
              style={{
                marginTop: "3em",
                overflow: "hidden",
              }}
              // disabled
              name="name"
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      marginBottom: "0.8em",
                    }}
                  >
                    {card?.cover ? (
                      <>
                        <Tooltip title={card?.cover}>
                          <ModalImageChatbot
                            imageUrl={card?.cover}
                            style={{ width: "10px" }}
                          />
                        </Tooltip>

                        <IconButton component="span">
                          <Tooltip title="Remover mídia">
                            <CancelIcon
                              onClick={() =>
                                setCard((prevState) => ({
                                  ...prevState,
                                  cover: null,
                                }))
                              }
                            />
                          </Tooltip>
                        </IconButton>
                      </>
                    ) : (
                      <Tooltip title="Anexar imagem">
                        <Button component="label">
                          <AttachFileIcon />

                          <input
                            accept={"image/png, image/jpeg, image/jpg"}
                            type="file"
                            hidden
                            onChange={(e) => {
                              handleUpdateMedia(e);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <DialogContent />
          </DialogContent>
        </FormControl>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            // disabled={loading}
            variant="outlined"
          >
            Cancelar
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            // disabled={!selectedContact || !selectedQueue || !selectedWhatsapp}
            onClick={() => handleSaveCard(selectedCard.id)}
            color="primary"
            loading={loading}
          >
            Salvar
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CardModal;
