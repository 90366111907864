import React from "react";

function Emoji3() {
  return (
    <img
      src="/rating-3.png"
      alt="emoji3"
      style={{
        width: "30px",
      }}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="24"
    //   height="24"
    // >
    //   <defs>
    //     <style>{`.b { fill: #864e20; } .c { fill: #e7c930; }`}</style>
    //   </defs>
    //   <rect
    //     x="1"
    //     y="1"
    //     width="22"
    //     height="22"
    //     rx="7.656"
    //     style={{ fill: "#f8de40" }}
    //   />
    //   <path
    //     className="b"
    //     d="M7.055 7.313A1.747 1.747 0 1 0 8.8 9.059a1.747 1.747 0 0 0-1.745-1.746zM16.958 7.313A1.747 1.747 0 1 0 18.7 9.059a1.747 1.747 0 0 0-1.742-1.746z"
    //   />
    //   <path
    //     className="c"
    //     d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z"
    //   />
    //   <ellipse className="b" cx="12" cy="13.375" rx="5.479" ry="0.297" />
    //   <ellipse className="c" cx="12" cy="14.646" rx="1.969" ry="0.229" />
    // </svg>
  );
}

export default Emoji3;
