import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  firstDivMap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  progressBar: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  bgProgressBar: {
    backgroundColor: '#34BF57!important',
  },
}));

function ChartServiceBar({ dataChart: { getDataDashboard }, period }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getDataChart = async () => {
      try {
        const { dataProgressServiceBar } = await getDataDashboard(period);

        setData(dataProgressServiceBar);
      } catch (error) {
        console.log(error);
      }
    };

    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <div>
      <div>
        <span>{i18n.t('dashboard.charts.progressBar.callsByAttendant')}</span>
      </div>
      <hr />
      {data.map((element, index) => (
        <div className={classes.firstDivMap} key={index}>
          <span style={{ width: 100 }}>{element.name}</span>
          <div className="progress" style={{ height: 15, width: 150 }}>
            <div
              className={`progress-bar progress-bar-striped progress-bar-animated ${classes.bgProgressBar}`}
              role="progressbar"
              style={{ width: `${element.value}%` }}
            />
          </div>
          <span>{element.value}</span>
        </div>
      ))}
    </div>
  );
}

export default ChartServiceBar;
