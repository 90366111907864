import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";

import { Box, MenuItem, Typography, withStyles } from "@material-ui/core";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import Emoji1 from "../Rating/Emoji1";
import Emoji2 from "../Rating/Emoji2";
import Emoji3 from "../Rating/Emoji3";
import Emoji4 from "../Rating/Emoji4";
import Emoji5 from "../Rating/Emoji5";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },

  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  emoji: {
    // "& svg": {
    //   dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //   fontSize: "300px",
    // },
    // // change rect fill color
    // "& svg rect": {
    //   fill: "blue",
    // },
  },

  statisticsPaper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  statisticsTitle: {
    fontSize: "1.1rem",
    fontWeight: "500",
  },

  statisticsValue: {
    display: "flex",
    fontSize: "1.8rem",
    fontWeight: "100",
    gap: theme.spacing(5),
  },

  emojiBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(1),
  },

  emojiPercents: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(3),
    gap: theme.spacing(1),
  },

  emojiPercent: {
    fontSize: "0.8rem",
    fontWeight: "900",
  },

  selectInput: {
    width: "19%",
  },
}));

function Avaliations() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [ratings, setAvaliations] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [ratingsCount, setRatingsCount] = useState(0);
  const [filters, setFilters] = useState({
    searchParam: "",
    period: "3",
    user: "",
    queue: "",
    connection: "",
  });
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [connections, setConnections] = useState([]);

  const emojis = [
    {
      emoji: <Emoji1 className={classes.emoji} />,
      caption: "Muito insatisfeito",
    },
    { emoji: <Emoji2 className={classes.emoji} />, caption: "Insatisfeito" },
    { emoji: <Emoji3 className={classes.emoji} />, caption: "Neutro" },
    { emoji: <Emoji4 className={classes.emoji} />, caption: "Satisfeito" },
    {
      emoji: <Emoji5 className={classes.emoji} />,
      caption: "Muito satisfeito",
    },
  ];

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { users },
        } = await api.get("/users/");
        setUsers(users);
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const { data } = await api.get("/queue/");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const { data } = await api.get("/whatsapp/");
        setConnections(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        try {
          const { data } = await api.get("/rating/", {
            params: { filters, pageNumber },
          });

          setAvaliations(data.tickets);
          setHasMore(data.hasMore);
          setRatingsCount(data.count);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber, filters]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const averageRating = () => {
    const detractors = ratings.filter((rating) => rating.rating < 3).length;
    const promoters = ratings.filter((rating) => rating.rating > 3).length;
    const neutrals = ratings.filter((rating) => rating.rating === 3).length;

    const total = promoters + detractors + neutrals;

    const detractorsPercentage = (detractors * 100) / total;
    const promotersPercentage = (promoters * 100) / total;

    const average = (promotersPercentage - detractorsPercentage).toFixed(1);

    return `${isNaN(average) ? 0 : average}%`;
  };

  const mapEmojiPercentages = () => {
    const emojiPercentages = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    ratings.forEach((rating) => {
      emojiPercentages[rating.rating] += 1;
    });

    for (let key in emojiPercentages) {
      emojiPercentages[key] = (
        (emojiPercentages[key] / ratings.length) *
        100
      ).toFixed(1);
    }

    return Object.values(emojiPercentages);
  };

  return (
    <Box>
      <MainHeader>
        <Title>Avaliações</Title>
      </MainHeader>

      <Box className={classes.mainContainer}>
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <TextField
            className={classes.selectInput}
            size="small"
            variant="outlined"
            label="Pesquisar"
            onChange={handleFilterChange}
            name="searchParam"
            value={filters.searchParam}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Período"
            onChange={handleFilterChange}
            name="period"
            value={filters.period}
          >
            <MenuItem value="0">Todos</MenuItem>
            <MenuItem value="1">Hoje</MenuItem>
            <MenuItem value="2">Ontem</MenuItem>
            <MenuItem value="3">Últimos 7 dias</MenuItem>
            <MenuItem value="4">Últimos 30 dias</MenuItem>
            <MenuItem value="5">Este mês</MenuItem>
            <MenuItem value="6">Últimos 6 meses</MenuItem>
            <MenuItem value="7">Este ano</MenuItem>
          </TextField>

          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Atendentes"
            onChange={handleFilterChange}
            name="user"
            value={filters.user}
          >
            <MenuItem value={""}>Todos</MenuItem>
            {users?.map((user) => (
              <MenuItem value={user.id}>{user.name}</MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Setores"
            onChange={handleFilterChange}
            name="queue"
            value={filters.queue}
          >
            <MenuItem value={""}>Todos</MenuItem>
            {queues?.map((queue) => (
              <MenuItem value={queue.id}>{queue.name}</MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.selectInput}
            size="small"
            select
            variant="outlined"
            label="Conexões"
            onChange={handleFilterChange}
            name="connection"
            value={filters.connection}
          >
            <MenuItem value="">Todos</MenuItem>
            {connections?.map((connection) => (
              <MenuItem value={connection.id}>{connection.name}</MenuItem>
            ))}
          </TextField>
        </Box>

        <Paper
          className={classes.statisticsPaper}
          variant="outlined"
          elevation={3}
        >
          <Box>
            <Typography className={classes.statisticsTitle}>
              Total de respostas
            </Typography>
            <Typography className={classes.statisticsValue}>
              {ratingsCount}
            </Typography>
          </Box>

          <Box>
            <Typography className={classes.statisticsTitle}>
              Pontuação de satisfação
            </Typography>
            <Typography className={classes.statisticsValue}>
              {averageRating()}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.statisticsValue}>
              {mapEmojiPercentages().map((percentage, index) => (
                <Box className={classes.emojiBox}>
                  <Box className={classes.emojiPercents} key={index}>
                    {emojis[index].emoji}
                    <Typography
                      variant="caption"
                      className={classes.emojiPercent}
                    >{`${isNaN(percentage) ? 0 : percentage}%`}</Typography>
                  </Box>
                  <Typography variant="caption">
                    {emojis[index].caption}
                  </Typography>
                </Box>
              ))}
            </Typography>
          </Box>
        </Paper>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell align="center">Contato</TableCell>
                <TableCell align="center">Ticket</TableCell>
                <TableCell align="center">Avaliação</TableCell>
                <TableCell align="center">Feedback</TableCell>
                <TableCell align="center">Atendente</TableCell>
                <TableCell align="center">Setor</TableCell>
                <TableCell align="center">Conexão</TableCell>
                <TableCell align="center">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {ratings.map((avaliation) => (
                  <TableRow key={avaliation.id}>
                    <TableCell>
                      <Avatar src={avaliation.contact?.profilePicUrl} />
                    </TableCell>
                    <TableCell>{avaliation.contact?.name}</TableCell>
                    <TableCell align="center">{avaliation.id}</TableCell>
                    <TableCell align="center">
                      {
                        // avaliation.rating
                        emojis[avaliation.rating - 1].emoji
                      }
                    </TableCell>
                    <TableCell align="center">
                      {avaliation.ratingDescription}
                    </TableCell>
                    <TableCell align="center">
                      {avaliation.user?.name}
                    </TableCell>
                    <TableCell align="center">
                      {avaliation.queue?.name}
                    </TableCell>
                    <TableCell align="center">
                      {avaliation.whatsapp.name}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8em" }}>
                      {new Date(avaliation.updatedAt).toLocaleString("pt-BR")}
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton avatar columns={3} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
}

export default Avaliations;
