import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import Hidden from "@material-ui/core/Hidden";
import TicketsManager from "../../components/TicketsManager";
import Ticket from "../../components/Ticket";

import { i18n } from "../../translate/i18n";

import noTickets from "../../assets/noTickets.png";
import LegacyTicketsManager from "../../components/LegacyTicketsManager";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: "calc(100% - 48px)",
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleNotificame: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "lightgray",
  },
  infoNotificame: {
    color: "#999999",
  },
}));

function Chat() {
  const classes = useStyles();
  const { ticketId } = useParams();

  const [legacyTicketsManager, setLegacyTicketsManager] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");

        const legacyTicketsManagerSetting = data.find(
          (s) => s.key === "legacyTicketsManager"
        );

        if (
          legacyTicketsManagerSetting &&
          legacyTicketsManagerSetting.value === "enabled"
        ) {
          setLegacyTicketsManager(true);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            {legacyTicketsManager ? (
              <LegacyTicketsManager />
            ) : (
              <TicketsManager />
            )}
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {ticketId ? (
              <Ticket />
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  <span>
                    <center>
                      <div>
                        <img src={noTickets} alt="illustration-no-tickets" />
                      </div>
                      <div className={classes.titleNotificame}>
                        <h1>NOTIFICA</h1>
                        <h1 style={{ color: "#5f0f94" }}>ME</h1>
                      </div>
                    </center>
                    <span className={classes.infoNotificame}>
                      <center>{i18n.t("chat.noChatDescription")}</center>
                    </span>
                    <span className={classes.infoNotificame}>
                      <center>{i18n.t("chat.noChatDescriptionText")}</center>
                    </span>
                  </span>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Chat;
