import {
  Box,
  Button,
  ListItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  stepBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    minHeight: "30vh",
    width: "100%",
  },

  simpleTextField: {
    width: "20%",
    marginBottom: "20px",
    overflow: "hidden",
  },

  nextButton: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
  },

  prevButton: {
    position: "absolute",
    left: "20px",
    bottom: "20px",
  },

  listItem: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const ModalStepTwo = ({ headers, setActiveStep, columns, setColumns }) => {
  const classes = useStyles();

  const handleColumns = (field, selectedColumn) => {
    setColumns((prevState) => ({
      ...prevState,
      [field]: selectedColumn,
    }));
  };

  return (
    <Box className={classes.stepBox}>
      <Typography
        variant="body1"
        color="textSecondary"
        component="p"
        gutterBottom
      >
        Selecione as variáveis correspondentes aos campos do arquivo CSV.
      </Typography>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        Nome do contato (obrigatório)
        <TextField
          select
          variant="outlined"
          size="small"
          className={classes.simpleTextField}
          onChange={(e) => handleColumns("name", e.target.value)}
          value={columns.name}
        >
          {headers.map((header) => (
            <ListItem className={classes.listItem} key={header} value={header}>
              {header}
            </ListItem>
          ))}
        </TextField>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        Número do contato (obrigatório)
        <TextField
          select
          variant="outlined"
          size="small"
          className={classes.simpleTextField}
          onChange={(e) => handleColumns("number", e.target.value)}
          value={columns.number}
        >
          {headers.map((header) => (
            <ListItem className={classes.listItem} key={header} value={header}>
              {header}
            </ListItem>
          ))}
        </TextField>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        Email do contato
        <TextField
          select
          variant="outlined"
          size="small"
          className={classes.simpleTextField}
          onChange={(e) => handleColumns("email", e.target.value)}
          value={columns.email}
        >
          <ListItem className={classes.listItem} value={""}>
            {"Nenhum"}
          </ListItem>
          {headers.map((header) => (
            <ListItem className={classes.listItem} key={header} value={header}>
              {header}
            </ListItem>
          ))}
        </TextField>
      </Box>

      <Button
        className={classes.prevButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setActiveStep(0);
        }}
      >
        Voltar
      </Button>

      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        disabled={columns.name === "" || columns.number === ""}
        onClick={() => {
          setActiveStep(2);
        }}
      >
        Continuar
      </Button>
    </Box>
  );
};

export default ModalStepTwo;
