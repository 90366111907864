import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CssBaseline } from "@material-ui/core";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard";
import Tickets from "../pages/Tickets";
import Signup from "../pages/Signup";
import Login from "../pages/Login";
import Connections from "../pages/Connections";
import Tags from "../pages/Tags";
import Schedules from "../pages/Schedules";
import Settings from "../pages/Settings";
import Users from "../pages/Users";
import WpTemplates from "../pages/WpTemplates";
import Contacts from "../pages/Contacts";
import QuickAnswers from "../pages/QuickAnswers";
import Queues from "../pages/Queues";
import Api from "../pages/Api";
import ApiDocs from "../pages/ApiDocs";
import ApiKey from "../pages/ApiKey";

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

import { ThemeProvider } from "../context/Theme/ThemeContext";
import RecoverPassword from "../pages/RecoverPassword";
import Presentation from "../pages/Presentation";
import BulkMessaging from "../pages/BulkMessaging";
import SalesFunnel from "../pages/SalesFunnel";
import Rating from "../pages/Rating";
import Avaliations from "../pages/Avaliations";
import HubConnect from "../pages/HubConnect";
import Report from "../pages/Report";

function Routes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/rating/:uuid" component={Rating} />
          <Route exact path="/recoverPassword" component={RecoverPassword} />
          <ThemeProvider>
            <CssBaseline />
            <WhatsAppsProvider>
              <LoggedInLayout>
                <Route exact path="/" component={Dashboard} isPrivate />
                <Route
                  exact
                  path="/sales-funnel"
                  component={SalesFunnel}
                  isPrivate
                />

                <Route
                  exact
                  path="/avaliations"
                  component={Avaliations}
                  isPrivate
                />

                <Route exact path="/reports" component={Report} isPrivate />

                <Route
                  exact
                  path="/tickets/:ticketId?"
                  component={Tickets}
                  isPrivate
                />
                <Route
                  exact
                  path="/connections"
                  component={Connections}
                  isPrivate
                />
                <Route exact path="/contacts" component={Contacts} isPrivate />
                <Route exact path="/users" component={Users} isPrivate />
                <Route
                  exact
                  path="/quickAnswers"
                  component={QuickAnswers}
                  isPrivate
                />
                <Route exact path="/tags" component={Tags} isPrivate />
                <Route
                  exact
                  path="/schedules"
                  component={Schedules}
                  isPrivate
                />
                <Route
                  exact
                  path="/Templates"
                  component={WpTemplates}
                  isPrivate
                />
                <Route exact path="/Settings" component={Settings} isPrivate />
                {/* <Route exact path="/api" component={Api} isPrivate /> */}
                {/* <Route exact path="/apidocs" component={ApiDocs} isPrivate /> */}
                {/* <Route exact path="/apikey" component={ApiKey} isPrivate /> */}
                <Route exact path="/Queues" component={Queues} isPrivate />
                <Route
                  exact
                  path="/presentation"
                  component={Presentation}
                  isPrivate
                />
                <Route
                  exact
                  path="/bulk-messaging"
                  component={BulkMessaging}
                  isPrivate
                />
                <Route
                  exact
                  path="/hub-connect"
                  component={HubConnect}
                  isPrivate
                />
              </LoggedInLayout>
            </WhatsAppsProvider>
          </ThemeProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Routes;
