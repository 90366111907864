import React from 'react';
import Lottie from 'react-lottie';
import forgotPassword from '../../assets/lottieJson/forgotPassword.json';

export default function ForgotPassword({ wid, hei }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: forgotPassword,
  };
  return <Lottie options={defaultOptions} />;
}
