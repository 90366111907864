import React, { useState, useEffect, useRef, memo } from "react";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Step, StepButton, Stepper } from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ModalStepOne from "./ModalStepOne";
import ModalStepTwo from "./ModalStepTwo";
import ModalStepThree from "./ModalStepThree";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  dialogContent: {
    minHeight: "60vh",
    minWidth: "80vh",
  },

  simpleTextField: {
    width: "100%",
    marginBottom: "20px",
    overflow: "hidden",
  },

  nextButton: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
  },
}));

const steps = ["Upload do arquivo", "Mapeamento dos campos", "Importar"];

const ModalStepOneMemoized = memo(ModalStepOne);
const ModalStepTwoMemoized = memo(ModalStepTwo);

function ContactsImportModal({ open, onClose, onSave }) {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [contacts, setContacts] = useState([]);
  const [columns, setColumns] = useState({
    name: "",
    number: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const handleClose = () => {
    onClose();
    setContacts([]);
    setSelectedFile();
    setColumns({
      name: "",
      number: "",
      email: "",
    });
    setActiveStep(0);
  };

  const handleSaveContacts = async () => {
    setLoading(true);
    const sanitizedContacts = contacts.map((contact) => ({
      name: contact[columns.name],
      number: `+55${contact[columns.number].trim().replace(/\D/g, "")}`,
      email: contact[columns.email],
    }));

    try {
      await api.post("/contacts/bulk-create", { contacts: sanitizedContacts });
      setLoading(false);
      onClose();
      setContacts([]);
      setSelectedFile();
      setColumns({
        name: "",
        number: "",
        email: "",
      });
      setActiveStep(0);
    } catch (error) {
      console.log({ error });
      toastError(error);
      setLoading(false);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSelectedFile = (file) => {
    setLoading(true);
    setSelectedFile(file);

    const reader = new FileReader();

    try {
      // setLoadingContacts(true);
      reader.onload = (e) => {
        const csvData = e.target.result;
        let normalizedCsv = csvData.replace(/\r/g, "");

        normalizedCsv += "\n";
        const lines = normalizedCsv.split("\n");
        const headers = lines[0].split(",");

        const result = [];
        for (let i = 1; i < lines.length; i++) {
          const object = {};
          const currentLine = lines[i].split(",");
          for (let j = 0; j < headers.length; j++) {
            object[headers[j]] = currentLine[j];
          }
          result.push(object);
        }

        // pop the last elements because it's empty
        result.pop();
        result.pop();

        setContacts(result);
      };

      reader.readAsText(file);
      setLoading(false);
    } catch (error) {
      toastError(error);
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">Importar Contatos</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={completed[index]}
                  disabled
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <ModalStepOneMemoized
              selectedFile={selectedFile}
              contactsLength={contacts.length}
              handleSelectedFile={handleSelectedFile}
              setActiveStep={setActiveStep}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {activeStep === 1 && (
            <ModalStepTwoMemoized
              headers={Object.keys(contacts[0])}
              setActiveStep={setActiveStep}
              columns={columns}
              setColumns={setColumns}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {activeStep === 2 && (
            <ModalStepThree
              setActiveStep={setActiveStep}
              columns={columns}
              contacts={contacts.slice(0, 4)}
              handleSaveContacts={handleSaveContacts}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ContactsImportModal;
