import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  stepBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    minHeight: "30vh",
    width: "100%",
  },

  searchButton: {
    fontWeight: 400,
    letterSpacing: "0.03em",
    marginRight: "-13px",
    borderRadius: 0,
    boxShadow: "none",
    padding: "10px 30px",
    fontSize: "0.8em",
    textTransform: "Capitalize",
  },

  simpleTextField: {
    width: "100%",
    marginBottom: "20px",
    overflow: "hidden",
  },

  nextButton: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
  },

  link: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "none",

    "& > p": {
      fontSize: "0.7rem",
      fontWeight: "bold",
    },

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ModalStepOne = ({
  selectedFile,
  handleSelectedFile,
  setActiveStep,
  contactsLength,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.stepBox}>
      <Typography
        variant="body1"
        color="textSecondary"
        component="p"
        gutterBottom
      >
        Importe o arquivo em formato CSV com os contatos que deseja adicionar.
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        gutterBottom
      >
        Caso seu arquivo esteja em outro formato, abra-o no Excel e exporte em
        formato CSV.
      </Typography>

      <a className={classes.link} href={`example-csv.csv`}>
        <GetAppIcon fontSize="small" style={{ marginTop: "-3px" }} />

        <Typography variant="body2" component="p" gutterBottom display="flex">
          Baixe um exemplo de arquivo CSV
        </Typography>
      </a>

      <TextField
        className={classes.simpleTextField}
        value={selectedFile?.name}
        variant="outlined"
        placeholder="Inserir arquivo CSV"
        size="small"
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                component="label"
                variant="contained"
                className={classes.searchButton}
              >
                Procurar
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(e) => {
                    handleSelectedFile(e.target.files[0]);
                  }}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <Typography
        variant="body1"
        color="textSecondary"
        component="p"
        gutterBottom
      >
        {contactsLength} contatos selecionados
      </Typography>

      <ButtonWithSpinner
        className={classes.nextButton}
        variant="contained"
        color="primary"
        disabled={!selectedFile}
        onClick={() => {
          setActiveStep(1);
        }}
        loading={loading}
      >
        Continuar
      </ButtonWithSpinner>
    </Box>
  );
};

export default ModalStepOne;
