import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge, Box, Typography, withStyles } from "@material-ui/core";
import { AlternateEmail } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  contactAvatar: {
    width: 40,
    height: 40,
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      color: "#fff",
      fontSize: "2em",
    },
  },

  messageBox: {
    width: "100%",
  },

  messageCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },

  messageBody: {
    // backgroundColor: "#fff",
    // padding: "0.2rem",
    // borderRadius: "1rem",
    // maxWidth: "80%",
    // wordBreak: "break-word",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  },

  messageHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  messageSender: {
    fontWeight: "bold",
  },

  messageDate: {
    fontSize: "0.8rem",
  },
}));

const MessageCard = ({ message, searchParam }) => {
  const classes = useStyles();
  const history = useHistory();

  const highlightBody = (messageBody) => {
    let generalSearchParam = searchParam.toLowerCase();

    const htmlStringfied = messageBody
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replace(
        generalSearchParam,
        `<strong style="color:#088069; front-weight: 900">${generalSearchParam}</strong>`
      );

    return ReactHtmlParser(`<p style="width: 90%">${htmlStringfied}</p>`);
  };

  return (
    <div
      className={classes.messageCard}
      onClick={() => history.push(`/tickets/${message.ticketId}`)}
    >
      {message?.channel === "whatsapp" && (
        <SmallAvatar
          src={require("../../assets/icons/whatsapp.svg")}
          style={{ backgroundColor: "#fff" }}
        />
      )}
      {message?.channel === "telegram" && (
        <SmallAvatar
          src={require("../../assets/icons/telegram.svg")}
          style={{ backgroundColor: "#fff" }}
        />
      )}
      {message?.channel === "instagram" && (
        <SmallAvatar
          src={require("../../assets/icons/instagram.svg")}
          style={{ backgroundColor: "#fff" }}
        />
      )}
      {message?.channel === "facebook" && (
        <SmallAvatar
          src={require("../../assets/icons/facebook.svg")}
          style={{ backgroundColor: "#fff" }}
        />
      )}
      {message?.channel === "whatsappbusiness" && (
        <SmallAvatar
          src={require("../../assets/icons/wppbusiness.svg")}
          style={{ backgroundColor: "#fff" }}
        />
      )}
      {message?.channel === "email" && (
        <AlternateEmail
          fontSize="small"
          style={{ backgroundColor: "#fff", borderRadius: "50%" }}
          color="primary"
        />
      )}

      <div className={classes.messageBox}>
        <div className={classes.messageHeader}>
          <Typography variant="p" className={classes.messageSender}>
            {message?.name}
          </Typography>

          <Typography variant="p" className={classes.messageDate}>
            {/* {message?.createdAt} */}

            {/* created at from 2023-01-15T23:29:28.097Z format to brazil format */}
            {new Date(message?.createdAt).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </div>

        {/* <div className={classes.messageBody}>{message?.body}</div> */}
        {highlightBody(message.body)}
      </div>
    </div>
  );
};

export default MessageCard;
