import React, { useState, useContext, useEffect } from "react";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TemplateModal from "../../components/TemplateModal";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfirmationModal from "../../components/ConfirmationModal";
import ModalImageChatbot from "../../components/ModalImageChatbot";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

export default function WpTemplates() {
  const classes = useStyles();

  const { whatsApps } = useContext(WhatsAppsContext);
  const [templates, setTemplates] = useState([]);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationFunction, setConfirmationFunction] = useState(null);

  useEffect(() => {
    if (!confirmationOpen) {
      setConfirmationFunction(null);
    }
  }, [confirmationOpen]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/template/");

        setCustomTemplates(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [whatsApps, templateModalOpen]);

  useEffect(() => {
    whatsApps.forEach((whatsApp) => {
      if (whatsApp.wabaAccountId) {
        try {
          (async () => {
            const response = await fetch(
              `https://graph.facebook.com/v15.0/${whatsApp.wabaAccountId}/message_templates`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${whatsApp.wabaAccessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );
            const { data } = await response.json();

            let notificameTemplates = data?.filter((template) =>
              template.name.includes("notificame")
            );

            if (notificameTemplates) {
              notificameTemplates.forEach(
                (template) => (template.whatsApp = whatsApp)
              );
              setTemplates([...notificameTemplates]);
            }
          })();
        } catch (error) {
          console.log(error);
        }
      }
    });
  }, [whatsApps, templateModalOpen]);

  const handleClose = () => {
    setSelectedTemplate(null);
    setTemplateModalOpen(false);
  };

  const handleDeleteTemplate = async (template) => {
    try {
      const { wabaAccessToken, wabaAccountId } = template.whatsApp;

      const response = await fetch(
        `https://graph.facebook.com/v15.0/${wabaAccountId}/message_templates?name=${template.name}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${wabaAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      await response.json();
      setTemplates(templates.filter((t) => t.id !== template.id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box id="templates-header-comp" display="flex" flexDirection={"column"}>
      <ConfirmationModal
        title={"Deletar template"}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={confirmationFunction}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("templates.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTemplateModalOpen(true)}
          >
            {i18n.t("templates.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {templateModalOpen && (
        <TemplateModal
          open={templateModalOpen}
          onClose={handleClose}
          whatsApps={whatsApps}
          selectedTemplate={{
            ...selectedTemplate,
            header: selectedTemplate?.components[0]?.text,
            body: selectedTemplate?.components[1]?.text,
            footer: selectedTemplate?.components[2]?.text,
          }}
        />
      )}

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        // onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("templates.table.type")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("templates.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("templates.table.header")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("templates.table.body")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("templates.table.footer")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("templates.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {customTemplates.map((template) => (
                <TableRow
                  // className={
                  //   bulkMessage?.bulkMessagingData?.customTemplateId === template.id
                  //     ? classes.templateSelected
                  //     : null
                  // }
                  key={template.id}
                  style={{
                    cursor: "pointer",
                  }}
                  // onClick={() => handleTemplateSelected(template)}
                >
                  <TableCell align="center">modelo</TableCell>
                  <TableCell align="center">{template.name}</TableCell>
                  <TableCell align="center">{template?.type}</TableCell>
                  <TableCell align="center">{""}</TableCell>
                </TableRow>
              ))}
              {templates.map((template) => (
                <TableRow
                  key={template.id}
                  style={
                    template.status === "REJECTED"
                      ? { backgroundColor: "#FFCDD2" }
                      : template.status === "PENDING"
                      ? {
                          backgroundColor: "#ffffa2",
                        }
                      : {}
                  }
                >
                  <TableCell align="center">
                    {i18n.t(`templates.table.types.${template.category}`)}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t(`templates.table.statuses.${template.status}`)}
                  </TableCell>
                  <TableCell align="center">
                    {template.components[0]?.format === "TEXT" ||
                    !template.components[0]?.format
                      ? template.components[0]?.text
                      : template.components[0]?.format === "IMAGE" && (
                          <ModalImageChatbot
                            imageUrl={
                              template.components[0]?.example?.header_handle[0]
                            }
                            style={{ width: "10px" }}
                          />
                        )}
                  </TableCell>
                  <TableCell align="center">
                    {template.components[1]?.text}
                  </TableCell>
                  <TableCell align="center">
                    {template.components[2]?.text}
                  </TableCell>
                  <TableCell align="center">
                    {/* <IconButton
                      size="small"
                      onClick={() => handleEditTemplate(template)}
                    >
                      <EditIcon color="secondary" />
                    </IconButton> */}

                    <IconButton
                      size="small"
                      // onClick={(e) => {
                      //   handleDeleteTemplate(template);
                      // }}
                      onClick={() => {
                        setConfirmationFunction(
                          () => () => handleDeleteTemplate(template)
                        );
                        setConfirmationOpen(true);
                      }}
                    >
                      <DeleteOutlineIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {/* {loading && <TableRowSkeleton columns={4} />} */}
            </>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
}
