import React, { useState, useEffect, useContext } from "react";

// import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import EmailIcon from "@material-ui/icons/Email";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import {
  Avatar,
  Box,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Typography,
  withStyles,
  ListItem,
  Select,
  Input,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { isArray, capitalize } from "lodash";
import { Picker } from "emoji-mart";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { TemplatesTable } from "./TemplatesTable";
import ModalImageChatbot from "../ModalImageChatbot";
import { VariablesModal } from "./VariablesModal";

/* eslint-disable no-template-curly-in-string */
const defaultVariables = ["contactName"];

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1),
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  dialog: {
    "& .MuiDialog-container": {
      margin: "auto",
      width: "1000px",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  // set gap on DialogContent
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },

  multFieldLine: {
    width: "100%",
    display: "flex",
    // gap: "1em",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  templatesTableBox: {
    overflowY: "scroll",
    height: "300px",
    width: "100%",
  },

  simpleSelectField: {
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  searchButton: {
    fontWeight: 400,
    letterSpacing: "0.03em",
    marginRight: "-13px",
    borderRadius: 0,
    boxShadow: "none",
    padding: "10px 30px",
    fontSize: "0.8em",
    textTransform: "Capitalize",
  },
  removeUrl: {
    color: "#e0e0e0",
  },
  inputLabel: {
    fontSize: "0.9em",
    margin: 0,
    padding: 0,
    // marginBottom: "5px",
    color: "#181818",
  },

  messageLength: {
    fontSize: "0.8em",
    margin: 0,
    padding: 0,
    paddinRight: "5px",
    color: "#939393",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
    zIndex: 5,
  },

  observationText: {
    fontSize: "0.8em",
    margin: "0 0 15px 0",
    padding: 0,
    color: "#939393",
    // set text to italic
    fontStyle: "italic",
  },

  valueTitle: {
    fontSize: "0.9em",
    margin: 0,
    padding: 0,
    marginBottom: "1px",
    color: "#181818",
    fontWeight: 900,
  },

  valueText: {
    fontSize: "0.85em",
    margin: 0,
    padding: 0,
    marginBottom: "10px",
    maxHeight: "100px",
    overflow: "scroll",
  },
}));

function ScheduleModal({ open, onClose, scheduleId, reload, contact }) {
  const classes = useStyles();

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
    connection: null,
    templateId: null,
    mediaPath: null,
    connectionId: null,
    connection: null,
    template: null,
  };

  const initialContact = {
    id: "",
    name: "",
  };
  const [templateParamsLength, setTemplateParamsLength] = useState(0);
  const [templateParams, setTemplateParams] = useState([]);
  const [schedule, setSchedule] = useState(initialState);
  const [contacts, setContacts] = useState([initialContact]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [scheduleData, setScheduleData] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [variablesModalOpen, setVariablesModalOpen] = useState(false);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [showEmoji, setShowEmoji] = useState(false);

  useEffect(() => {
    if (!contact) return;

    setScheduleData((prevState) => ({
      ...prevState,
      contactId: contact.id,
      contact,
    }));
  }, [contact]);

  useEffect(() => {
    if (!open || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const whatsAppId = schedule.connection || scheduleData.connection;
          const {
            data: { contacts: contactList },
          } = await api.get("contacts", {
            params: { searchParam, channel: scheduleData?.connection?.channel },
          });
          const customList = contactList.map((c) => ({
            id: c.id,
            name: c.name,
          }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  useEffect(() => {
    if (!scheduleId) return;
    try {
      (async () => {
        const { data } = await api.get(`/schedules/${scheduleId}`);
        setSchedule(data);
        // setContactId(data.contactId);
        setScheduleData((prevState) => ({
          ...prevState,
          ...data,
          sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
        }));

        setTemplateParamsLength(JSON.parse(data.templateParams)?.length || 0);
        setTemplateParams(JSON.parse(data.templateParams) || []);
      })();
    } catch (err) {
      toastError(err);
    }
  }, [open, scheduleId]);

  const countTemplateParams = (text) => {
    var regex = /\{{\d+}}/g;
    var matches = text?.match(regex);

    if (matches) {
      return matches.length;
    } else {
      return 0;
    }
  };

  const handleClose = () => {
    onClose();
    setSchedule(initialState);
    setScheduleData(initialState);
  };

  useEffect(() => {
    setTemplateParamsLength(0);
    setTemplateParams([]);
    setScheduleData((prevState) => ({
      ...prevState,
      templateParams: [],
    }));
  }, [scheduleData.template]);

  const handleSaveSchedule = async () => {
    setIsSubmitting(true);

    try {
      if (scheduleId) {
        try {
          const { data } = await api.put(
            `/schedules/${scheduleId}`,
            scheduleData
          );
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      } else {
        try {
          await api.post("/schedules", scheduleData);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      // if (typeof reload === "function") {
      //   reload();
      // }
      setLoading(false);
      setSchedule(initialState);
      // history.push("/schedules");
      setIsSubmitting(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
      setIsSubmitting(false);
    }
    handleClose();
  };

  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number} - ${option.channel}`;
    }
    return `${option.name}`;
  };

  const handleMessage = (e) => {
    const { value } = e.target;
    // if value last letter is "$"
    if (value[value.length - 1] === "$") {
      setVariablesModalOpen(true);
      return;
    }

    // const formatedMessage = value.replace(
    //   new RegExp(`(\\${defaultVariables.join("|\\")})`, "g"),
    //   (match) => variables[match]
    // );

    setScheduleData((prevState) => ({
      ...prevState,
      body: value,
    }));
  };

  const handleAddEmoji = (e) => {
    const emoji = e.native;
    // setInputMessage(prevState => prevState + emoji);
    setScheduleData((prevState) => ({
      ...prevState,
      body: prevState.body + emoji,
    }));
  };

  const handleUpdateMedia = async (e) => {
    if (!e.target.files) {
      return;
    }

    const img = e.target.files[0];
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", img);
    const response = await api.post("/bulk-messaging/upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const url = `${process.env.REACT_APP_BACKEND_URL}/public/${img.name}`;

    setScheduleData((prevState) => ({
      ...prevState,
      mediaPath: url,
    }));
  };

  const showTemplateParams = () => {
    // const templateText = templates
    //   .find((tmpt) => tmpt.id === scheduleData.templateId)
    //   .components?.find((component) => component.type === "BODY").text;

    return (
      <>
        <Typography variant="subtitle1" style={{ alignSelf: "center" }}>
          Parâmetros do template: {templateParamsLength}
        </Typography>
      </>
    );
  };

  return (
    // <div className={classes.root}>
    <Dialog
      open={open}
      onClose={handleClose}
      // maxWidth="xs"
      className={classes.dialog}
      // fullWidth
      // scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        {schedule.status === "ERRO"
          ? "Erro de Envio"
          : `Mensagem ${capitalize(schedule.status)}`}
      </DialogTitle>

      <VariablesModal
        open={variablesModalOpen}
        handleClose={() => setVariablesModalOpen(false)}
        variables={defaultVariables}
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
      />

      <DialogContent className={classes.dialogContent} dividers>
        <Box className={classes.multFieldLine}>
          <Typography className={classes.inputLabel}>
            Conexão de origem
          </Typography>
          <TextField
            select
            variant="outlined"
            className={classes.simpleSelectField}
            value={
              whatsApps.find(
                (whatsApp) => whatsApp.id === scheduleData?.connection?.id
              ) || ""
            }
            onChange={(e) => {
              setTimeout(() => {
                setScheduleData((prevState) => ({
                  ...prevState,
                  connectionId: e.target.value.id,
                  connection: e.target.value,
                }));
              }, 100);
            }}
          >
            {whatsApps.map((whatsApp) => (
              <MenuItem key={whatsApp.id} value={whatsApp}>
                {whatsApp.channel === "email" && (
                  <>
                    {/* <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" /> */}
                    <EmailIcon
                      style={{
                        fontSize: "1.2em",
                        marginLeft: "0.1em",
                        marginRight: "0.4em",
                      }}
                    />
                    {whatsApp.name}
                  </>
                )}
                {whatsApp.channel === "whatsapp" && (
                  <>
                    <SmallAvatar src="https://img.icons8.com/color/48/000000/whatsapp.png" />
                    {whatsApp.name}
                  </>
                )}
                {whatsApp.channel === "telegram" && (
                  <>
                    <SmallAvatar src="https://img.icons8.com/color/48/000000/telegram-app--v1.png" />
                    {whatsApp.name}
                  </>
                )}
                {whatsApp.channel === "whatsappcloud" && (
                  <>
                    <SmallAvatar src="https://e7.pngegg.com/pngimages/362/594/png-clipart-whatsapp-inc-business-whatsapp-text-logo.png" />
                    {whatsApp.name}
                  </>
                )}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {scheduleId || contact ? (
          <TextField
            className={classes.simpleSelectField}
            label="Contato"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={scheduleData?.contact?.name || contact?.name}
            disabled
          />
        ) : (
          <Autocomplete
            className={classes.simpleSelectField}
            options={contacts}
            loading={loading}
            clearOnBlur
            autoHighlight
            freeSolo
            clearOnEscape
            value={scheduleData?.contact}
            getOptionLabel={renderOptionLabel}
            onChange={(e, newValue) => {
              setScheduleData((prevState) => ({
                ...prevState,
                contactId: newValue.id,
              }));
            }}
            renderInput={(params) => (
              <TextField
                className={classes.simpleSelectField}
                {...params}
                label={i18n.t("newTicketModal.fieldLabel")}
                variant="outlined"
                required
                value={scheduleData.contact}
                onChange={(e) => setSearchParam(e.target.value)}
                // onKeyPress={(e) => {
                //   if (loading || !selectedContact) return;
                //   else if (e.key === "Enter") {
                //     handleSaveTicket(selectedContact.id);
                //   }
                // }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}

        {scheduleData?.connection?.channel === "whatsappcloud" ? (
          <>
            <Box className={classes.templatesTableBox}>
              <TemplatesTable
                whatsappId={scheduleData?.connectionId}
                scheduleData={scheduleData}
                setScheduleData={setScheduleData}
                setTemplateParamsLength={setTemplateParamsLength}
                // setTemplateParams={setTemplateParams}
              />
            </Box>
            {scheduleData?.template?.components[0].format === "VIDEO" && (
              <p>video</p>
            )}
            {scheduleData?.template?.components[0].format === "LOCATION" && (
              <p>localização</p>
            )}
            {scheduleData?.template?.components[0].format === "IMAGE" && (
              <p>imagem</p>
            )}
            {scheduleData?.template?.components[0].format === "DOCUMENT" && (
              <p>documento</p>
            )}

            {["VIDEO", "DOCUMENT", "IMAGE"].includes(
              scheduleData?.template?.components[0].format
            ) && (
              <TextField
                value={scheduleData?.mediaPath}
                // disabled
                name="name"
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        marginBottom: "0.8em",
                      }}
                    >
                      {scheduleData?.mediaPath ? (
                        <>
                          <Tooltip title={scheduleData?.mediaPath}>
                            <ModalImageChatbot
                              imageUrl={scheduleData?.mediaPath}
                              style={{ width: "10px" }}
                            />
                          </Tooltip>

                          <IconButton component="span">
                            <Tooltip title="Remover mídia">
                              <CancelIcon
                                onClick={() =>
                                  setScheduleData((prevState) => ({
                                    ...prevState,
                                    mediaPath: null,
                                  }))
                                }
                              />
                            </Tooltip>
                          </IconButton>
                        </>
                      ) : (
                        <Tooltip title="Anexar imagem">
                          <Button component="label">
                            <AttachFileIcon />

                            <input
                              accept={
                                scheduleData?.template?.components[0].format ===
                                "IMAGE"
                                  ? "image/png, image/jpeg, image/jpg"
                                  : scheduleData?.template?.components[0]
                                      .format === "VIDEO"
                                  ? "video/mp4"
                                  : scheduleData?.template?.components[0]
                                      .format === "DOCUMENT"
                                  ? "application/pdf"
                                  : ""
                              }
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleUpdateMedia(e);
                              }}
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {showTemplateParams(scheduleData?.templateId)}
            <Box
              style={{
                width: "100%",
                // height: "3vh",
              }}
            >
              {
                // create a select for each template param
                [...Array(templateParamsLength)].map((_, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "-1.5em",
                        // justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ alignSelf: "center" }}
                      >{`{{${index + 1}}}-`}</Typography>
                      <Select
                        defaultValue={"text"}
                        value={
                          templateParams[index] === "contactName"
                            ? "contactName"
                            : "text"
                        }
                        style={{
                          margin: "1em 0",
                          borderRadius: "10px",
                        }}
                      >
                        <ListItem
                          value="text"
                          onClick={() => {
                            const newTemplateParams = [...templateParams];
                            newTemplateParams[index] = "";
                            setTemplateParams(newTemplateParams);
                          }}
                        >
                          Texto Livre
                        </ListItem>

                        <ListItem
                          value="contactName"
                          onClick={() => {
                            const newTemplateParams = [...templateParams];
                            newTemplateParams[index] = "contactName";
                            setTemplateParams(newTemplateParams);
                            setScheduleData((prevState) => ({
                              ...prevState,
                              templateParams: newTemplateParams,
                            }));
                          }}
                        >
                          Nome do contato
                        </ListItem>
                      </Select>
                      {templateParams[index] !== "contactName" && (
                        <Input
                          placeholder={`Parâmetro ${index + 1}`}
                          value={templateParams[index]}
                          onChange={(e) => {
                            const newTemplateParams = [...templateParams];
                            newTemplateParams[index] = e.target.value;
                            setTemplateParams(newTemplateParams);
                            setScheduleData((prevState) => ({
                              ...prevState,
                              templateParams: newTemplateParams,
                            }));
                          }}
                          fullWidth
                          autoFocus
                          style={{
                            margin: "1em 0",
                            borderRadius: "10px",
                          }}
                        />
                      )}
                    </Box>
                  );
                })
              }
            </Box>
          </>
        ) : (
          <div className={classes.multFieldLine}>
            <Box
              display="flex"
              sx={{ width: "100%" }}
              justifyContent="space-between"
            >
              <Typography className={classes.inputLabel}>Mensagem</Typography>
              <Typography className={classes.messageLength}>
                {`${scheduleData?.body?.length || 0}/${1024}`}
              </Typography>
            </Box>
            <TextField
              className={classes.simpleTextField}
              value={scheduleData?.body || ""}
              defaultValue={schedule?.body || ""}
              // InputProps={{
              //   endAdornment: (
              //     <IconButton
              //       aria-label="emojiPicker"
              //       component="span"
              //       onClick={(e) => setShowEmoji((prevState) => !prevState)}
              //     >
              //       <MoodIcon className={classes.sendMessageIcons} />
              //     </IconButton>
              //   ),
              // }}
              inputProps={{
                maxLength: 1024,
              }}
              characterLimit={1024}
              onChange={(e) => handleMessage(e)}
              multiline
              minRows={4}
              variant="outlined"
              size="small"
              required
            />
            {showEmoji ? (
              <div className={classes.emojiBox}>
                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                  <Picker
                    perLine={16}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                  />
                </ClickAwayListener>
              </div>
            ) : null}
            <Typography className={classes.observationText}>
              Observação: para inserir variáveis na mensagem digite "$" e defina
              um whatsapp não oficial para obter o nome original do contato.
            </Typography>
            <Typography className={classes.inputLabel}>Arquivo</Typography>
            <TextField
              className={classes.simpleTextField}
              value={scheduleData.mediaPath}
              defaultValue={schedule.mediaPath}
              variant="outlined"
              size="small"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {scheduleData?.mediaPath && (
                      <Box
                        style={{
                          borderRadius: "10px",
                          marginRight: "10px",
                          marginLeft: "-20px",
                          overflow: "hidden",
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        <ModalImageChatbot imageUrl={scheduleData?.mediaPath} />
                      </Box>
                    )}
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    {scheduleData?.mediaPath && (
                      <Button
                        className={classes.removeUrl}
                        onClick={() =>
                          setScheduleData((prevState) => ({
                            ...prevState,
                            mediaPath: "",
                          }))
                        }
                      >
                        remover
                      </Button>
                    )}
                    <Button
                      component="label"
                      variant="contained"
                      className={classes.searchButton}
                    >
                      Procurar
                      <input
                        type="file"
                        hidden
                        // disabled={loadingContacts}
                        onChange={(e) => {
                          handleUpdateMedia(e);
                        }}
                      />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}

        <Box className={classes.multFieldLine}>
          <TextField
            label={i18n.t("scheduleModal.form.sendAt")}
            type="datetime-local"
            name="sendAt"
            InputLabelProps={{
              shrink: true,
            }}
            value={
              scheduleData?.sendAt || moment().format("YYYY-MM-DDTHH:mm")
              // convert send at to local time
              // scheduleData?.sendAt
              //   ? moment(schedule?.sendAt).format("YYYY-MM-DDTHH:mm")
              //   : moment().format("YYYY-MM-DDTHH:mm")
            }
            onChange={(e) => {
              const { value } = e.target;
              setScheduleData((prevState) => ({
                ...prevState,
                sendAt: value,
              }));
            }}
            variant="outlined"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={isSubmitting}
          variant="outlined"
        >
          {i18n.t("scheduleModal.buttons.cancel")}
        </Button>
        {(schedule.sentAt === null || schedule.sentAt === "") && (
          <Button
            color="primary"
            disabled={
              isSubmitting ||
              !scheduleData?.connectionId ||
              !scheduleData?.contactId ||
              (templateParamsLength > 0 &&
                templateParams.length !== templateParamsLength) ||
              (scheduleData?.template?.components[0].format === "IMAGE" &&
                !scheduleData?.mediaPath)
            }
            variant="contained"
            className={classes.btnWrapper}
            onClick={handleSaveSchedule}
          >
            {scheduleId
              ? `${i18n.t("scheduleModal.buttons.okEdit")}`
              : `${i18n.t("scheduleModal.buttons.okAdd")}`}
            {isSubmitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
    // </div>
  );
}

export default ScheduleModal;
