import React from "react";
import Radio from "@material-ui/core/Radio";
import { Box, Typography } from "@material-ui/core";

export default function TemplateRadioItem({
  template,
  outlined,
  templateIsApproved,
  setSelectedTemplateRaw,
}) {
  return (
    <>
      <Typography variant="h6">{template.name.replace(/_/gi, " ")}</Typography>
      <Box
        onClick={() => setSelectedTemplateRaw(template)}
        display="flex"
        border="1px solid black"
        p={2}
        m={1}
        style={{
          backgroundColor: outlined ? "rgba(0, 0, 0, 0.2)" : "inherit",
          borderRadius: "10px",
        }}
      >
        {templateIsApproved ? (
          <Radio value={template.id} />
        ) : (
          <>
            <Typography variant="h6" style={{ color: "red" }}>
              Esse template ainda não foi aprovado
            </Typography>
            <Radio disabled value={template.id} />
          </>
        )}
        <Box display="flex" flexDirection="column">
          {template.components.map((component) => {
            return (
              <>
                <Typography>{component.text}</Typography>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
