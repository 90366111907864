import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./App.css";
import { Box, IconButton } from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  draftContainer: {
    display: "flex",
  },

  sendMessageIcons: {
    color: "grey",
    cursor: "pointer",
  },
}));

function DraftInput({ ticketId }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const classes = useStyles();

  const handleSendAnnotation = () => {
    (async () => {
      try {
        await api.post(`/messages/annotation/${ticketId}`, {
          body: convertedContent,
        });

        setEditorState(() => EditorState.createEmpty());
      } catch (err) {
        console.log(err);
      }
    })();
  };

  useEffect(() => {
    // let html = convertToHTML(editorState.getCurrentContent());
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === "BOLD") {
          return <strong />;
        }
        if (style === "ITALIC") {
          return <em />;
        }
        if (style === "STRIKETHROUGH") {
          return <s />;
        }
      },
      blockToHTML: (block) => {
        if (block.type === "PARAGRAPH") {
          return <p />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    setConvertedContent(html);
  }, [editorState]);

  return (
    <div
    // className="App"
    >
      <Box className={classes.draftContainer}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={{
            options: ["inline"],
          }}
          placeholder={"Digite uma anotação"}
        />
        <IconButton
          aria-label="send-annotation"
          component="span"
          onClick={handleSendAnnotation}
          // disabled={loading || templateBarStatus}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Box>
    </div>
  );
}

export default DraftInput;
