import React from 'react';
import Lottie from 'react-lottie';
import loginAnimation from '../../assets/lottieJson/loginAnimation.json';

export default function LoginAnimation({ wid, hei }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loginAnimation,
  };
  return <Lottie options={defaultOptions} />;
}
