import React, { createContext, useState, useEffect } from 'react';

import { ptBR } from '@material-ui/core/locale';
import primaryColor from '@material-ui/core/colors/teal';
import secondaryColor from '@material-ui/core/colors/blueGrey';

import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import whatsBackground from '../../assets/wa-background.png';
import whatsBackgroundDark from '../../assets/wa-background-dark.jpg';

const ThemeContext = createContext();

function ThemeProvider({ children }) {
  const [locale, setLocale] = useState();
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  const palletType = darkMode ? 'dark' : 'light';

  useEffect(() => {
    const i18nlocale = localStorage.getItem('i18nextLng');
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === 'ptBR') {
      setLocale(ptBR);
    }
  }, []);

  const theme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '*::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: darkMode
                ? 'hsla(0,0%,100%,.16)'
                : 'rgba(0,0,0,.1)',
            },
          },
        },
      },

      palette: {
        type: palletType,
        primary: darkMode ? secondaryColor : primaryColor,
        secondary: darkMode ? primaryColor : secondaryColor,
        error: {
          50: '#e0f2f1',
          100: '#b2dfdb',
          200: '#80cbc4',
          300: '#4db6ac',
          400: '#26a69a',
          500: '#009688',
          600: '#00897b',
          700: '#00796b',
          800: '#00695c',
          900: '#004d40',
          A100: '#a7ffeb',
          A200: '#64ffda',
          A400: '#1de9b6',
          A700: '#00bfa5',
        },
      },

      backgroundImage: darkMode
        ? `url(${whatsBackgroundDark})`
        : `url(${whatsBackground})`,

      typography: {
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      },

      messagesList: {
        messageLeft: {
          backgroundColor: darkMode ? '#202c33' : '#FFFFFF',
          color: darkMode ? '#FFFFFF' : '#303030',
        },
        messageRight: {
          backgroundColor: darkMode ? '#005c4b' : '#d9fdd3',
          color: darkMode ? '#FFFFFF' : '#303030',
        },
        backgroundImage: darkMode
          ? `url(${whatsBackgroundDark})`
          : `url(${whatsBackground})`,
        backgroundColor: darkMode ? '#222222' : '#E5DDD5',
        timestampBackground: darkMode ? '#1E2A30' : '#E1F3FB',
        noteMessage: darkMode ? '#4D6C7C' : '#FFFFD4',
      },
    },
    locale,
  );

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
