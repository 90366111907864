import { Button } from "@material-ui/core";
import { DeviceHub } from "@material-ui/icons";
import React from "react";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";

export default function HubButton() {
  const createFaceBookPages = async (data) => {
    try {
      const whatsappData = {
        name: data.name,
        greetingMessage: "",
        farewellMessage: "",
        isDefault: false,
        isDisplay: false,
        email: "",
        emailPassword: "",
        channel: "facebook",
        status: "FACEBOOK",
        queueIds: [],
        pageToken: data.access_token,
        pageId: data.id,
      };

      await api.post("/whatsapp", whatsappData);
    } catch (err) {
      toastError(err);
    }
  };

  const handleClick = () => {
    window.open(
      "http://hub.notificame.com.br/social-login/lista-paginas.php",
      {},
      function (w) {
        console.log("nw window", w);
      }
    );

    window.onmessage = function (e) {
      if (e.data === "replace your location") {
        console.log("Recebeu replace");
      }

      try {
        const sanitizedData = e.data.replace(",]", "]");

        const pagesData = JSON.parse(sanitizedData).data;

        pagesData.forEach((page) => {
          createFaceBookPages(page);
        });
      } catch (err) {}
    };
  };

  return (
    <Button
      startIcon={<DeviceHub />}
      color="primary"
      variant="contained"
      onClick={handleClick}
    >
      Hub
    </Button>
  );
}
